import React, { useCallback, useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'

import { Grid, styled, Typography } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

import NoConditions from '../../../../components/NoConditions'
import AddConditionButton from '../../../../components/AddConditionButton/AddConditionButton'
import modalService from '../../../../../../services/modalService'
import ConditionsModal from '../../../../modals/ConditionsModal'
import ConditionsList from '../../../../../../components/Conditions/ConditionsList'
import ConfirmationModal from '../../../../../ReportConfigurationNext/modals/ConfirmationModal'
import toastService from '../../../../../../services/toastService'
import withMultipleProviders from '../../../../../../hocs/withMultipleProviders'

import { translations } from '../../../../../../config'
import { useQuery } from '../../../../../../hooks'
import { asyncActions as integrationAsyncActions, selectors as integrationSelectors } from '../../../../../../store/modules/integrationManagement'
import { formatRulesEngine, formatQueryBuilder } from '../../../../../../helpers/conditionsFormatter'

import DictionaryContext, { useDictionaries } from './contexts/DictionaryContext'
import FieldContext, { useFields } from './contexts/FieldContext'
import { actionEnum } from '../../../../constants'

const InfoWrapper = styled(Grid)(({ theme }) => ({
  background: '#EEE',
  border: '1px solid #DDD',
  padding: theme.spacing(1),
  margin: theme.spacing(2)
}))

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  maxWidth: '100%'
}))

const IntegrationManagementEditReportFormIncomingReports = () => {
  const dispatch = useDispatch()
  const {
    formSchemaId,
    organisationId,
  } = useQuery()
  const method = formSchemaId ? 'FORM_SCHEMA' : 'FORM_VIEW'
  const fetchedConditions = useSelector(integrationSelectors.getFetchData({ id: formSchemaId, method }))
  const fetchStatus = useSelector(integrationSelectors.getFetchData({ id: formSchemaId, method, path: ['status'] }))
  const fetchErrors = useSelector(integrationSelectors.getFetchData({ id: formSchemaId, method, path: ['error', 'name'] }))
  const [conditions, setConditions] = useState(undefined)
  const { fields, fieldLookup } = useFields()
  const dictionaries = useDictionaries()

  const integrationConfigId = useMemo(() => {
    return _.get(fetchedConditions, 'id')
  }, [fetchedConditions])

  const handlePrefixOperation = (action) => {
    let prefixOperation
    const dictionaryId = _.get(action, 'details.value')

    if (dictionaryId) {
      const searchResult = _.find(dictionaries, { id: dictionaryId })
      const dictionaryName = _.get(
        searchResult,
        'displayName',
        translations('Integration Management - Missing Dictionary')
      )
      prefixOperation = dictionaryName
    }

    return prefixOperation
  }

  const formatAction = ({ action, direction }) => {
    const actionsPayload = _.cloneDeep(action)
    const actionType = _.get(action, 'action')
    const conditionsParser = (direction === 'outgoing') ? formatRulesEngine : formatQueryBuilder

    const actionConditions = _.get(action, 'details.conditions')
    if (actionConditions) {
      actionsPayload.details.conditions = conditionsParser(actionConditions)
    }

    if (actionType === actionEnum.USE_DICTIONARY) {
      actionsPayload.prefixOperation = handlePrefixOperation(action)
    }

    return actionsPayload
  }

  const configConditions = useMemo(() => {
    const conditions = _.get(fetchedConditions, 'conditions')
    const convertToQueryConditions = (conditions) => {
      const orderedConditions = _.orderBy(conditions, 'position')
      const queryBuilderConditions = _.map(orderedConditions, ({ condition }, index) => {
        const conditionVersionCondition = _.get(condition, 'conditionVersion.0.conditions')
        const formattedConditions = formatQueryBuilder(conditionVersionCondition)
        const { IMAction, name, description, id } = condition
        const actions = _.map(IMAction, (action) => {
          return formatAction({ action, direction: 'incoming' })
        })
        return {
          id,
          name,
          description,
          conditions: formattedConditions,
          actions,
          position: index
        }
      })
      return _.sortBy(queryBuilderConditions, 'position')
    }
    return convertToQueryConditions(conditions)
  }, [fetchedConditions])

  const generateNextConditions = useCallback((newCondition) => {
    const existingCondition = _.find(conditions, { id: newCondition.id })
    if (existingCondition) {
      return [..._.filter(conditions, ({ id }) => id !== newCondition.id), { ...newCondition, position: existingCondition.position }]
    }
    return [...conditions, newCondition]
  }, [conditions])

  const updateOrCreateAction = useCallback(({ payload }) => {
    const nextConditions = _.sortBy(generateNextConditions(payload), ['position'])
    const formatActions = (actions) => {
      return _.map(actions, (action) => {
        return formatAction({ action, direction: 'outgoing' })
      })
    }
    const conditionForSave = _.map(nextConditions, (nextCondition, index) => {
      const relationships = {}
      const conditionId = _.get(nextCondition, 'id')
      const conditionActions = _.get(nextCondition, 'actions')

      const formattedActions = formatActions(conditionActions)
      const fallbackActions = _.reduce(conditionActions, (memo, action) => {
        const fallbackActions = _.get(action, 'fallbackActions')        
        if (_.size(fallbackActions) > 0) {
          memo.push({
            conditionId,
            actionId: _.get(action, 'id'),
            actions: formatActions(fallbackActions)
          })
        }
        return memo
      }, [])

      if (_.size(fallbackActions) > 0) {
        relationships.fallbackActions = fallbackActions
      }

      return {
        ...nextCondition,
        actions: formattedActions,
        conditions: formatRulesEngine(nextCondition.conditions),
        relationships,
        position: index
      }
    })

    if (integrationConfigId) {
      setConditions(nextConditions)
      return integrationAsyncActions.updateIntegrationManagementConfig({
        integrationManagementConfigurationId: integrationConfigId,
        organisationId,
        formSchemaId,
        conditions: conditionForSave
      })
    }
    setConditions([payload])
    return integrationAsyncActions.createIntegrationManagementConfig({
      organisationId,
      formSchemaId,
      conditions: conditionForSave
    })
  }, [formSchemaId, integrationConfigId, organisationId, generateNextConditions])

  const hasNoConditions = useMemo(() => _.isEmpty(conditions), [conditions])

  const handleAddOrEdit = useCallback((props) => {
    const initialValues = _.get(props, 'initialValues')
    modalService.open({
      component: ConditionsModal,
      disableBackdropClick: true,
      largeModal: true,
      initialValues,
      editing: !!initialValues,
      fields,
      dictionaries,
      onSubmit: async (payload) => {
        dispatch(updateOrCreateAction({ payload }))
      }
    })
  }, [fields, dictionaries, dispatch, updateOrCreateAction])

  const handleDelete = (props) => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      message: translations('Condition - Delete modal message'),
      title: translations('Confirmation'),
      onConfirmation: async (payload) => {
        dispatch(integrationAsyncActions.deleteIntegrationManagementConfigCondition({ conditionId: props.id, formSchemaId }))
      }
    })
  }

  const handleDragEnd = (props) => {
    const { source, destination } = props
    if (_.isNull(destination)) {
      return
    }
    const { index: fromIndex } = source
    const { index: toIndex } = destination
    let nextConditions = _.cloneDeep(conditions)
    const targetCondition = nextConditions.splice(fromIndex, 1)[0]
    nextConditions.splice(toIndex, 0, targetCondition)
    const conditionForSave = _.map(nextConditions, (nextCondition, index) => {
      return {
        ...nextCondition,
        conditions: formatRulesEngine(nextCondition.conditions),
        position: index
      }
    })
    setConditions(nextConditions)
    dispatch(integrationAsyncActions.updateIntegrationManagementConfig({
      integrationManagementConfigurationId: integrationConfigId,
      organisationId,
      formSchemaId,
      conditions: conditionForSave
    }))
  }

  useEffect(() => {
    if (fetchStatus === 'REJECTED') {
      const translationsKey = integrationConfigId ? 'Integration Management - Update Failure' : 'Integration Management - Create Failure'
      toastService.action({
        type: 'error',
        message: translations(translationsKey, { error: fetchErrors })
      })
    }
  }, [fetchStatus, fetchErrors, integrationConfigId])

  useEffect(() => {
    dispatch(integrationAsyncActions.fetchIntegrationManagementConfigs({
      organisationId,
      formSchemaId
    }))
  }, [
    formSchemaId,
    organisationId,
    dispatch
  ])

  useEffect(() => {
    setConditions(configConditions)
  }, [configConditions])

  return (
    <Grid
      direction='column'
      container
      spacing={2}
    >
      <InfoWrapper item xs={12} >
        <InfoOutlined />
        <Typography variant='body1' component='span'>
          {translations('Integration Management - Conditions are triggered in sequence')}
        </Typography>
      </InfoWrapper>
      <Container>
        {hasNoConditions && <NoConditions />}
        {!hasNoConditions && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <ConditionsList
              conditions={conditions}
              onDelete={handleDelete}
              onEdit={handleAddOrEdit}
              useDragAndDrop
              id={formSchemaId}
              fieldLookup={fieldLookup}
            />
          </DragDropContext>
        )}
      </Container>
      <AddConditionButton onAdd={handleAddOrEdit} />
    </Grid>
  )
}

export default withMultipleProviders(
  FieldContext.FieldProvider,
  DictionaryContext.DictionaryProvider
)(IntegrationManagementEditReportFormIncomingReports)
