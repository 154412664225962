"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUserValues = exports.generateComputedValues = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _constants = require("../../../constants");

var _formHelpers = require("../../../formSchemas/sabreSAE/1.0.0/formHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var generateComputedValues = function generateComputedValues(_ref) {
  var _ref2;

  var schemaName = _ref.schemaName,
      organisationDetails = _ref.organisationDetails,
      _ref$formViewDetails = _ref.formViewDetails,
      formViewDetails = _ref$formViewDetails === void 0 ? {} : _ref$formViewDetails,
      _ref$productDetails = _ref.productDetails,
      productDetails = _ref$productDetails === void 0 ? {} : _ref$productDetails,
      _ref$userDetails = _ref.userDetails,
      userDetails = _ref$userDetails === void 0 ? {} : _ref$userDetails,
      isWorkspace = _ref.isWorkspace;

  var sendingOrganisation = _fp["default"].getOr(_fp["default"].get('senderorganization', organisationDetails), 'senderorganization')(formViewDetails);

  var contactFirstName = _fp["default"].getOr(_fp["default"].get('sendergivename', organisationDetails), 'sendergivename')(formViewDetails);

  var contactSecondName = _fp["default"].getOr(_fp["default"].get('senderfamilyname', organisationDetails), 'senderfamilyname')(formViewDetails);

  var contactEmail = _fp["default"].getOr(_fp["default"].get('senderemailaddress', organisationDetails), 'senderemailaddress')(formViewDetails);

  var contactTelephone = _fp["default"].getOr(_fp["default"].get('sendertel', organisationDetails), 'senderemailaddress')(formViewDetails);

  var contactAddress = _fp["default"].getOr(_fp["default"].get('senderstreetaddress', organisationDetails), 'senderstreetaddress')(formViewDetails);

  var contactCountry = _fp["default"].getOr(_fp["default"].get('country', organisationDetails), 'country')(formViewDetails);

  var contactCity = _fp["default"].getOr(_fp["default"].get('sendercity', organisationDetails), 'senderpostcode')(formViewDetails);

  var contactPostcode = _fp["default"].getOr(_fp["default"].get('senderpostcode', organisationDetails), 'senderpostcode')(formViewDetails);

  var contactStreet1 = _fp["default"].get('importedData.appian.MAN_ADDR_LINE_1', organisationDetails);

  var contactStreet2 = _fp["default"].get('importedData.appian.MAN_ADDR_LINE_2', organisationDetails);

  var contactStreet3 = _fp["default"].get('importedData.appian.MAN_ADDR_LINE_3', organisationDetails);

  var contactStreet4 = _fp["default"].get('importedData.appian.MAN_ADDR_LINE_4', organisationDetails);

  var ARName = _fp["default"].get('importedData.appian.REP_NAME', organisationDetails);

  var ARContactPersonFirstName = _fp["default"].get('importedData.appian.REP_FIRST_NAME', organisationDetails);

  var ARContactPersonSecondName = _fp["default"].get('importedData.appian.REP_SURNAME', organisationDetails);

  var AREmailAddress = _fp["default"].get('importedData.appian.REP_EMAIL_ADDR', organisationDetails);

  var ARCity = _fp["default"].get('importedData.appian.REP_CITY', organisationDetails);

  var ARPostCode = _fp["default"].get('importedData.appian.REP_POSTCODE', organisationDetails);

  var ARCountry = _fp["default"].get('importedData.appian.REP_COUNTRY', organisationDetails);

  var ARStreet1 = _fp["default"].get('importedData.appian.REP_ADDRESS_LINE_1', organisationDetails);

  var ARStreet2 = _fp["default"].get('importedData.appian.REP_ADDRESS_LINE_2', organisationDetails);

  var ARStreet3 = _fp["default"].get('importedData.appian.REP_ADDRESS_LINE_3', organisationDetails);

  var ARStreet4 = _fp["default"].get('importedData.appian.REP_ADDRESS_LINE_4', organisationDetails);

  var productName = _fp["default"].get('name', productDetails);

  var brandName = _fp["default"].get('brandName', productDetails);

  var modelNum = _fp["default"].get('modelNum', productDetails);

  var catalogNum = _fp["default"].get('catalogNum', productDetails);

  var udiDIUnitUse = _fp["default"].get('udiDIUnitUse', productDetails);

  var udiDI = _fp["default"].get('udiDI', productDetails);

  var udiPI = _fp["default"].get('udiPI', productDetails);

  var udiDIBasic = _fp["default"].get('device.udiDIBasic', productDetails);

  var nomenclatureCode = _fp["default"].get('device.nomenclatureCode', productDetails);

  var deviceDescription = _fp["default"].get('device.deviceDescription', productDetails);

  var mfrOrgStreet = _fp["default"].trim(_fp["default"].join(' ')([contactStreet1, contactStreet2, contactStreet3, contactStreet4]));

  var ARStreet = _fp["default"].trim(_fp["default"].join(' ')([ARStreet1, ARStreet2, ARStreet3, ARStreet4]));

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.PSR_REPORT:
      return {
        mfrName: sendingOrganisation,
        mfrContactName: contactFirstName,
        mfrEmailAddress: contactEmail,
        mfrPhone: contactTelephone,
        mfrCountry: contactCountry,
        mfrAddress: contactAddress,
        mfrCity: contactCity,
        mfrPostcode: contactPostcode,
        mfrOrgStreet: mfrOrgStreet,
        ARName: ARName,
        ARContactPersonFirstName: ARContactPersonFirstName,
        ARContactPersonSecondName: ARContactPersonSecondName,
        AREmailAddress: AREmailAddress,
        ARCountry: ARCountry,
        ARStreet: ARStreet,
        ARCity: ARCity,
        ARPostCode: ARPostCode,
        brandName: productName
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.FSCA_REPORT:
      return {
        mfrName: sendingOrganisation,
        mfrContactName: contactFirstName,
        mfrEmailAddress: contactEmail,
        mfrPhone: contactTelephone,
        mfrCountry: contactCountry,
        mfrAddress: contactAddress,
        mfrCity: contactCity,
        mfrPostcode: contactPostcode,
        mfrOrgStreet: mfrOrgStreet,
        ARName: ARName,
        ARContactPersonFirstName: ARContactPersonFirstName,
        ARContactPersonSecondName: ARContactPersonSecondName,
        AREmailAddress: AREmailAddress,
        ARCountry: ARCountry,
        ARStreet: ARStreet,
        ARCity: ARCity,
        ARPostCode: ARPostCode,
        brandName: brandName || productName,
        udiDIBasic: udiDIBasic,
        udiDIUnitUse: udiDIUnitUse,
        modelNum: modelNum,
        catalogNum: catalogNum,
        udiDI: udiDI,
        udiPI: udiPI,
        nomenclatureCode: nomenclatureCode,
        deviceDescription: deviceDescription
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.MIR_REPORT:
      return _ref2 = {
        mfrName: sendingOrganisation,
        mfrContactPersonFirstName: contactFirstName,
        mfrContactPersonSecondName: contactSecondName,
        mfrEmailAddress: contactEmail,
        mfrPhone: contactTelephone,
        mfrCountry: contactCountry,
        mfrOrgStreet: contactAddress,
        mfrCity: contactCity,
        mfrPostcode: contactPostcode
      }, _defineProperty(_ref2, "mfrOrgStreet", mfrOrgStreet), _defineProperty(_ref2, "ARName", ARName), _defineProperty(_ref2, "ARContactPersonFirstName", ARContactPersonFirstName), _defineProperty(_ref2, "ARContactPersonSecondName", ARContactPersonSecondName), _defineProperty(_ref2, "AREmailAddress", AREmailAddress), _defineProperty(_ref2, "ARCountry", ARCountry), _defineProperty(_ref2, "ARStreet", ARStreet), _defineProperty(_ref2, "ARCity", ARCity), _defineProperty(_ref2, "ARPostCode", ARPostCode), _defineProperty(_ref2, "brandName", brandName || productName), _defineProperty(_ref2, "udiDIBasic", udiDIBasic), _defineProperty(_ref2, "udiDIUnitUse", udiDIUnitUse), _defineProperty(_ref2, "modelNum", modelNum), _defineProperty(_ref2, "catalogNum", catalogNum), _defineProperty(_ref2, "udiDI", udiDI), _defineProperty(_ref2, "udiPI", udiPI), _defineProperty(_ref2, "nomenclatureCode", nomenclatureCode), _defineProperty(_ref2, "deviceDescription", deviceDescription), _ref2;

    case _constants.FORM_SCHEMAS_CONSTANTS.SAE_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.TREND_REPORT:
      return {
        reporterOrgName: sendingOrganisation
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAE_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAR_REPORT:
      return {
        reporterStatus: _formHelpers.REPORTER_STATUSES.SUBMIT_NOTIFICATION,
        regulatorStatus: _formHelpers.REGULATOR_STATUSES.NOT_RECEIVED,
        reportStatus: _formHelpers.REPORT_STATUSES.NOTIFICATION,
        orgName: _fp["default"].get('orgName', organisationDetails),
        orgCode: _fp["default"].get('orgCode', organisationDetails),
        orgAddress: _fp["default"].compose(_formHelpers.cleanAndJoin, _fp["default"].pick(['addressLine1', 'addressLine2', 'town', 'county']))(organisationDetails)
      };

    default:
      return {};
  }
};

exports.generateComputedValues = generateComputedValues;

var generateUserValues = function generateUserValues(_ref3) {
  var schemaName = _ref3.schemaName,
      userDetails = _ref3.userDetails,
      isWorkspace = _ref3.isWorkspace;
  var id = userDetails.id,
      email = userDetails.email,
      firstName = userDetails.firstName,
      lastName = userDetails.lastName,
      company = userDetails.company,
      houseNumberOrName = userDetails.houseNumberOrName,
      address = userDetails.address,
      addressLineTwo = userDetails.addressLineTwo,
      city = userDetails.city,
      country = userDetails.country,
      postalCode = userDetails.postalCode,
      telephone = userDetails.telephone;
  var workspaceFields = {
    reporterOrgName: company,
    reporterContactPersonFirstName: firstName,
    reporterContactPersonSecondName: lastName,
    reporterOrgEmailAddress: email,
    reporterOrgPhone: telephone,
    reporterOrgCountry: country,
    reporterOrgStreet: _fp["default"].trim(_fp["default"].join(' ')([address, addressLineTwo])),
    reporterOrgStreetNum: houseNumberOrName,
    reporterOrgCity: city,
    reporterOrgPostcode: postalCode
  };

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.SAE_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.PSR_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.TREND_REPORT:
      return {
        reporterContactPersonFirstName: firstName,
        reporterContactPersonSecondName: lastName,
        reporterOrgEmailAddress: email
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAE_REPORT:
      return {
        incidentType: 'Serious Adverse Event'
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAR_REPORT:
      return {
        incidentType: 'Serious Adverse Reaction'
      };

    default:
      return _objectSpread({}, isWorkspace && workspaceFields);
  }
};

exports.generateUserValues = generateUserValues;