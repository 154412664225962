import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormBody } from '../../../components/Form'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import RightButtonContent from '../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../components/ToggleIconButton'
import Heading from '../../../components/Heading'
import style from './style'
import { translations } from '../../../config'

class ContentFormSection extends Component {
  state = {
    isOpen: this.props.initialOpen
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  renderFormBody () {
    const { schema, initialValues } = this.props
    return <FormBody
      initialValues={initialValues}
      schema={schema}
      fullWidthFields
      editing
      hidden={!this.state.isOpen}
    />
  }

  render () {
    const { id, title, classes } = this.props
    const contentId = `${id}Content`
    const controlId = `${id}Controls`

    return (
      <ContentBox id={id} contentId={contentId} hidden={!this.state.isOpen}>
        <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
          <TextContent>
            <Heading id={id}>{translations(title)}</Heading>
          </TextContent>
          <RightButtonContent>
            <div className={classes.rightButtonContent}>
              <ToggleIconButton
                id={controlId}
                targetId={contentId}
                onClick={this.toggleOpen}
                active={this.state.isOpen}
              />
            </div>
          </RightButtonContent>
        </ContentBoxHeader>
        <ContentBoxBody>
          {this.renderFormBody()}
        </ContentBoxBody>
      </ContentBox>
    )
  }
}

ContentFormSection.defaultProps = {
  initialOpen: false,
  editing: true
}

ContentFormSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  schema: PropTypes.array.isRequired,
  layout: PropTypes.array,
  classes: PropTypes.shape({}).isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  initialOpen: PropTypes.bool
}

export default withStyles(style)(ContentFormSection)
