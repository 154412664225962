import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import _ from 'lodash'
import { usePrevious } from 'react-use'
import { FormSpy } from 'react-final-form'
import { useSdk } from '../../services/sdk/hooks'

import { utilities } from '@redant/mhra-form-schema-library'
import { translation } from '../../services/translations'
import { useFormStatusState } from '../../services/formStatusContext'

import H1 from '../H1'
import { StyledH3 } from '../H3'
import LinkButton from '../LinkButton'
import Button from '../Button'
import ErrorMessage from '../ErrorMessage'
import Spinner from '../Spinner'
import ReportFormSection from '../ReportFormSection'
import Wizard from './Wizard'
import WizardStep from './WizardStep'

const Heading = styled.header`
  padding: 15px;
  ${media.greaterThan('small')`
    padding-top: 30px;
    padding-bottom: 30px;
  `}
`

const SectionTitle = styled(StyledH3)`
  font-weight: 400;
`

const FollowUpMessageWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lightGray};
  padding: 5px;
`

const FollowUpMessage = styled.p`
  padding: 5px;
  margin: 5px;
  font-size: 13px;
  ${media.greaterThan('medium')`
    font-size: 16px;
    padding: 10px;
  `}
`

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -5px;
`

const Content = styled.div``

const ErrorSection = styled.div`
  padding: 20px 15px 0;
  text-align: right;
`

const FormActions = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${props => `${props.theme.form.spacing.small}px`};
  padding-bottom: ${props => `${props.theme.form.spacing.small}px`};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${media.greaterThan('small')`
    padding-top: ${props => `${props.theme.form.spacing.large}px`};
    padding-bottom: ${props => `${props.theme.form.spacing.large}px`};
  `}
`

const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
`

const LeftActions = styled.div`
  margin-right: auto;
`
const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  > button {
    margin-left: 20px;
  }
  ${media.lessThan('small')`
    > button {
      margin-left: 0px;
    }
`}
`


/**
 * Turn form sections into a step-by-step flow (or wizard)
 */
const FormWizard = (props) => {
  const {
    schemaName,
    handleSubmit,
    submitError,
    submitting,
    onPrevious,
    onStepCount,
    index,
    form: {
      batch,
      change,
      submit,
      getFieldState,
      getState
    },
    values,
    organisationDetails,
    professions,
    formViewDetails,
    onSaveDraftSuccess,
    onSaveDraftError,
    reportId,
    followUp,
    enableDrafts,
    formSchemaLibrary,
    onFormStateChange,
    setTouched
  } = props

  const { saveDraftReport } = useSdk()
  const previousValues = usePrevious(values) || {}
  const [formSections, setFormSections] = useState({})
  const [formState, setFormState] = useState({
    submitting: false,
    values: {},
    touched: {}
  })
  const [formViewConfig, setFormViewConfig] = useState()
  const audienceId = _.get(formViewConfig, 'audienceId', 1)
  const showNullFlavours = _.get(formViewConfig, 'showNullFlavours', false)

  const { clearRequiredRepeatableId, requiredRepeatables } = useFormStatusState()
  const isFollowUp = _.has(followUp, 'id')

  const getTitle = useCallback(({ formSections, index }) => {
    let title

    if (isFollowUp) {
      title = 'FollowUp Report - Subheader'
    } else {
      const sectionKey = _.get(_.keys(formSections), index)
      title = _.get(formSections, `${sectionKey}.title`)
    }

    return title
  }, [formSections, index, isFollowUp])

  const title = getTitle({ formSections, index })
  const stepCount = _.size(formSections)
  const isFirstStep = (index === 0)
  const isLastStep = (index === (stepCount - 1))

  const getViewJSON = useCallback(() => {
    let viewJSON = {}
    const latestFormViewVersion = _.get(formViewDetails, 'formViewVersions.0.viewJSON')
    if (latestFormViewVersion) {
      viewJSON.sections = latestFormViewVersion
    }
    return viewJSON
  }, [formViewDetails])

  const viewJSON = getViewJSON(formViewDetails)
  const schema = formSchemaLibrary.processSchema(schemaName, viewJSON, translation, { ...formViewConfig, showAllSections: true })
  const changeField = useCallback((_form, field, value) => change(field, value), [change])

  const parseSchema = useCallback(() => {
    return utilities.parseDynamicSchemaSections({ schema, formValues: values, changeField, denyList: [], batchChanges: batch })
      .then((schemaSections) => {
        const parser = utilities.parseDynamicSchema(values, previousValues, changeField, schemaName, false, {}, organisationDetails, formViewConfig, professions, { batchChanges: batch })
        const formSections = {}

        for (const sectionKey in schemaSections) {
          const formSection = schemaSections[sectionKey]
          let formSectionSchema = parser(formSection.schema)
          if (!formSection.hideFromForm && utilities.sectionShouldRender({ fields: formSectionSchema })) {
            formSections[sectionKey] = _.assign({}, formSection, { schema: formSectionSchema })
          }
        }

        return formSections
      })
  }, [schemaName, values, previousValues, organisationDetails, formViewConfig, professions])

  useEffect(() => {
    parseSchema()
      .then((formSections) => {
        setFormSections(formSections)
      })
  }, [values, formViewConfig])

  useEffect(() => {
    _.forEach(requiredRepeatables, (repeatableId) => {
      _.mapKeys(formSections, (value) => {
        const findMatch = _.find(value.schema, (field) => field.id === repeatableId)
        _.get(findMatch, 'props.type') === 'hidden' && clearRequiredRepeatableId(repeatableId)
      })
    })
  }, [formSections, requiredRepeatables])

  useEffect(() => {
    onStepCount(stepCount)
  }, [stepCount])

  useEffect(() => {
    const formViewConfig = _.get(formViewDetails, 'details', {})
    const audienceId = _.get(formViewConfig, 'audienceId', 1)
    formViewConfig.audienceId = audienceId
    setFormViewConfig(formViewConfig)
  }, [formViewDetails])

  if (_.isEmpty(formSections) || stepCount < 1) {
    return null
  }

  const renderActions = () => {
    if (submitting) {
      return (
        <Spinner />
      )
    }
    return (
      <>
        {enableDrafts && <LeftActions><Button light type='button' onClick={() => saveDraft()}>{translation('Save draft')}</Button></LeftActions>}
        <RightActions>
          {!isFirstStep && <LinkButton type={'button'} onClick={onPrevious}>{translation('Previous step')}</LinkButton>}
          {!isLastStep && <Button type='button' onClick={submit}>{translation('Next step')}</Button>}
          {isLastStep && <Button type='button' onClick={submit}>Submit</Button>}
        </RightActions>
      </>
    )
  }
  const saveDraft = () => {
    return saveDraftReport({ values })
      .then((res) => {
        _.defaultsDeep(res, { report: { id: reportId } })
        onSaveDraftSuccess(res)
      })
      .catch((error) => {
        console.log(error)
        onSaveDraftError(error, values)
      })
  }

  const handleFormStateChange = (state) => {
    setTouched(state.touched)
    setFormState(state)
    if (onFormStateChange) {
      onFormStateChange(state)
    }
  }

  return (
    <Content>
      <Heading>
        <HeadingWrap>
          <H1>{isFollowUp ? translation('FollowUp Report - Header') : translation(`Step ${index + 1} of ${stepCount}`)}</H1>
          {enableDrafts && <Button
            type='button'
            onClick={() => saveDraft()}
            light
          >
            {translation('Save draft')}
          </Button>}
        </HeadingWrap>
        <SectionTitle as={'h2'}>{translation(title)}</SectionTitle>
        {isFollowUp && (
          <FollowUpMessageWrapper>
            <FollowUpMessage>{translation(_.get(followUp, 'followUp.message'))}</FollowUpMessage>
          </FollowUpMessageWrapper>
        )}
        <HeaderActions>
          <div>{!isFirstStep && <LinkButton onClick={onPrevious}>{translation('Previous step')}</LinkButton>}</div>
          <div>{!isLastStep && <LinkButton onClick={submit}>{translation('Next step')}</LinkButton>}</div>
        </HeaderActions>
      </Heading>
      <form onSubmit={handleSubmit}>
        <Wizard index={index}>
          {_.map(formSections, (section) => {
            const { title, schema } = section
            return (
              <WizardStep key={title}>
                <ReportFormSection
                  schema={schema}
                  change={change}
                  getFieldState={getFieldState}
                  getState={getState}
                  audienceId={audienceId}
                  schemaName={schemaName}
                  showNullFlavours={showNullFlavours}
                />
              </WizardStep>
            )
          })}
        </Wizard>
        {submitError && <ErrorSection>
          <ErrorMessage error={submitError} />
        </ErrorSection>}
        <FormActions>
          {renderActions()}
        </FormActions>
      </form>
      <FormSpy
        subscription={{
          values: true,
          submitting: true,
          submitError: true,
          submitFailed: true,
          dirty: true,
          touched: true,
        }}
        onChange={handleFormStateChange}
      />
    </Content>
  )
}

FormWizard.propTypes = {
  /**
   * Wraps the submit function - passed from React Final Form
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Sets to true when the form is submitting - passed from React Final Form
   */
  submitting: PropTypes.bool,
  /**
   * Function to call when the wizard should go back a step
   */
  onPrevious: PropTypes.func.isRequired,
  /**
   * Callback function when the wizard has parsed the schema and knows the total step count
   */
  onStepCount: PropTypes.func,
  /**
   * The current step
   */
  index: PropTypes.number,
  /** [React Final Form Instance API](https://final-form.org/docs/final-form/types/FormApi)
   *
   * `change` - Changes a field (used by parseDynamicSchema)
   *
   * `submit` - Trigger form submit (also handles {translation('next step')})
  */
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  }).isRequired,
  /**
   * Current form values
   */
  values: PropTypes.object,
  /**
   * Vigilance Hub organisation details
   */
  organisationDetails: PropTypes.object,
  /**
   * Vigilance Hub organisation's professions
   */
  professions: PropTypes.array,
  /**
   * FormView configuration
   */
  formViewDetails: PropTypes.object,
  /**
   * The default form schema name to use.
   *
   * Primarily used for testing where a `formViewId` has not been setup
   */
  schemaName: PropTypes.string,
  /**
   * FollowUp details
   */
  FollowUp: PropTypes.object,
  /**
   * Enable ability to save report as a draft for logged in users
   */
  enableDrafts: PropTypes.bool
}

FormWizard.defaultProps = {
  values: {},
  organisationDetails: {},
  professions: [],
  formViewDetails: {},
  index: 0,
  submitting: false,
  enableDrafts: false
}

export default FormWizard
