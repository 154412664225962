import React from 'react'

import ReportFullList from '../../../ReportFullList'

const OrganisationReportingFullList = props => {
  const { organisationAnalytics, reportSchema } = props
  return <ReportFullList
    items={[organisationAnalytics]}
    reportSchema={reportSchema}
    itemProps={{chartCategory: reportSchema.chartCategory}}
  />
}

export default OrganisationReportingFullList
