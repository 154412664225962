import { connect } from 'react-redux'
import qs from 'qs'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { isSubmitting, isInvalid, submit, getFormValues } from 'redux-form'
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState
} from 'recompose'
import digitalStoreSdk from '../../../digitalStoreSdk'

import {
  actions as communicationTemplateActions,
  selectors as communicationTemplateSelectors,
  constants as communicationTemplateConstants
} from '../../../store/modules/communicationTemplates'

import {
  selectors as translationsSelectors
} from '../../../store/modules/translations'

import {
  selectors as authSelectors
} from '../../../store/modules/auth'

import toastService from '../../../services/toastService'
import { translations, getCurrentLanguage } from '../../../config'

import FullScreenLoader from '../../../components/FullScreenLoader'
import CommunicationsTemplateForm from './CommunicationsTemplateForm'

const enhancer = compose(
  connect((state, ownProps) => {
    const templateTypes = communicationTemplateSelectors.getDefaultPlatformTemplatesByType(state)
    const template = _.get(templateTypes, ownProps.type)
    const channel = _.get(ownProps, 'channel')
    let testDestination
    switch(channel){
      case 'sms':
        testDestination = authSelectors.getActiveTelephone(state)
        break;
      case 'email':
        testDestination = authSelectors.getActiveEmail(state)
        break;
      default:
        testDestination = null
        break;
    }
    return {
      isSubmitting: isSubmitting(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM)(state),
      isInvalid: isInvalid(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM)(state),
      currentFormData: getFormValues(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM)(state),
      systemEntities: communicationTemplateSelectors.getSystemEntities(state),
      organisationId: authSelectors.getUserSelectedOrganisationId(state),
      templateTypes: communicationTemplateSelectors.getTemplatesIDsByType(state),
      isLoading: communicationTemplateSelectors.isLoadingCommunicationTemplates(state),
      languages: translationsSelectors.getLanguages(state),
      currentLanguage: getCurrentLanguage(),
      testDestination,
      editing: _.has(ownProps, 'id'),
      template,
      channel,
    }
  }, (dispatch) => {
    return {
      fetchTemplates: ({channel}) => {        
        switch(channel) {
          case 'sms':
            return dispatch(communicationTemplateActions.fetchSmsTemplates())
          case 'push':
            return dispatch(communicationTemplateActions.fetchPushTemplates())
          default:
            return dispatch(communicationTemplateActions.fetchEmailTemplates())
        }
      },
      createOrganisationTemplate: ({ organisationId, body, channel }) => {        
        const query = _.get(window, 'location.search')
        const { isSystemEmailTemplate = false } = qs.parse(query, { ignoreQueryPrefix: true }) || {}       
        switch(channel) {
          case 'sms':
            body.smsTemplateId = body.templateId
            delete body.templateId
            return dispatch(communicationTemplateActions.createOrganisationSmsTemplate({ organisationId, body }))
          case 'push':
            body.pushTemplateId = body.templateId
            delete body.templateId
            return dispatch(communicationTemplateActions.createOrganisationPushTemplate({ organisationId, body }))
          default:
            body.emailTemplateId = body.templateId
            delete body.templateId
            if (isSystemEmailTemplate === 'true') {
              return dispatch(communicationTemplateActions.createSystemEmailTemplate({ body }))
            }
            return dispatch(communicationTemplateActions.createOrganisationEmailTemplate({ organisationId, body }))
        }
      },
      updateTemplate: ({ organisationId, id, body, channel, isSystemTemplate }) => {
        switch(channel) {
          case 'sms':
            return dispatch(communicationTemplateActions.updateOrganisationSmsTemplate({ organisationId, id, body }))
          case 'push':
            return dispatch(communicationTemplateActions.updateOrganisationPushTemplate({ organisationId, id, body }))
          default:
            if (isSystemTemplate) {
              return dispatch(communicationTemplateActions.updateSystemEmailTemplate({ id, body }))
            }
            return dispatch(communicationTemplateActions.updateOrganisationEmailTemplate({ organisationId, id, body }))
        }
      },
      onCreateSuccess: ({ name }) => {
        dispatch(push('/communications'))
        toastService.action({
          type: 'success',
          message: translations('Communications - Create Success', { name })
        })
      },
      onUpdateSuccess: ({ name }) => {
        toastService.action({
          type: 'success',
          message: translations('Communications - Update Success', { name })
        })
      }
    }
  }),
  withState('testLanguage', 'setTestLanguage', (props) => {
    const { currentLanguage, languages } = props
    const isoCode = _.head(_.split(_.toLower(currentLanguage), '-'))
    return _.includes(languages, isoCode) ? isoCode : 'en'
  }),
  withHandlers({
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM))
    },
    handleSubmit: (props) => async (formData) => {
      const {
        template,
        organisationId,
        createOrganisationTemplate,
        updateTemplate,
        onCreateSuccess,
        onUpdateSuccess,
        editing,
        id,
        channel,
        systemEntities
      } = props
      const name = _.get(formData, 'name')
      const isSystemTemplate = _.includes(_.keys(systemEntities), id)
      try {
        if (editing) {
          await updateTemplate({ organisationId, id, body: formData, channel, isSystemTemplate })
          onUpdateSuccess({ name })
        } else {
          await createOrganisationTemplate({
            organisationId,
            body: {
              templateId: _.get(template, 'id'),
              ...formData
            },
            channel
          })
          onCreateSuccess({ name })
        }
      } catch (e) {
        const translationsKey = id ? 'Communications - Update Failure' : 'Communications - Create Failure'
        toastService.action({
          type: 'error',
          message: translations(translationsKey, { name })
        })
      }
    },
    onSendTest: (props) => async () => {
      const { template, organisationId, currentFormData, testLanguage, channel } = props
      try {
        if (!_.get(currentFormData, 'name')) {
          throw Error('Failure - Missing template name')
        }
        let sdkEndpoint, body
        switch(channel) {
          case 'sms':
            sdkEndpoint = digitalStoreSdk.communicationTemplates.sendTestSmsTemplate
            body = {
              smsTemplateId: _.get(template, 'id'),
              languageCode: testLanguage,
              ...currentFormData
            }
            break;
          case 'email':
            sdkEndpoint = digitalStoreSdk.communicationTemplates.sendTestEmailTemplate
            body = {
              emailTemplateId: _.get(template, 'id'),
              languageCode: testLanguage,
              ...currentFormData
            }
            break;
          default:
            return
        }

        await sdkEndpoint({
          organisationId,
          body
        })
        toastService.action({
          type: 'success',
          message: translations(`Communications - Send Test Success ${_.capitalize(channel)}`)
        })
      } catch (error) {
        let translationsKey = `Communications - Send Test Failure ${_.capitalize(channel)}`

        if (error.code === 'RA-MHR-155-08') {
          translationsKey = error.code
        }

        if (error.code === 'RA-MHR-171-08') {
          translationsKey = error.code
        }

        if (error.code === 'RA-MHR-171-09') {
          translationsKey = error.message
        }

        if (_.startsWith(error.message, 'Failure')) {
          translationsKey = error.message
        }

        toastService.action({
          type: 'error',
          message: translations(translationsKey)
        })
      }
    },
    onCopy: () => (variable) => {
      toastService.action({
        type: 'success',
        message: translations(`Copied to clipboard`, { name: variable })
      })
    }
  }),
  withPropsOnChange(['languages'], (props) => {
    const { languages, channel } = props
    const schema = [
      {
        id: 'Name',
        field: 'Input',
        props: {
          label: translations('Communications - Name Field'),
          name: 'name',
          shrink: true,
          required: true
        }
      }
    ]

    const generateContentSchema = ({ language }) => {
      let contentSchema = [{
        id: `content.${language}.body`,
        field: 'Input',
        props: {
          label: translations('Communications - Body Field'),
          name: `content.${language}.body`,
          multiline: true,
          rows: 10,
          shrink: true,
          required: true
        }
      }]
      if(channel === 'email') {
        const body = _.get(contentSchema, '0')
        contentSchema = _.concat([
          {
            id: `content.${language}.subject`,
            field: 'Input',
            props: {
              label: translations('Communications - Subject Field'),
              name: `content.${language}.subject`,
              shrink: true,
              required: true
            }
          }
        ], body)
      }
      return contentSchema
    }

    const contentSections = _.reduce(languages, (memo, language) => {
      memo[language] = {
        id: language,
        title: `Language - ${language}`,  
        schema: generateContentSchema({language})
      }
      return memo
    }, {})

    return {
      schema,
      contentSections,
      languagesOptions: _.map(languages, (language) => ({ label: translations(`Language - ${language}`), value: language }))
    }
  }),
  withPropsOnChange(['template'], (props) => {
    const { template } = props
    return {
      variables: _.get(template, 'variables', [])
    }
  }),
  withPropsOnChange(['template'], (props) => {
    const { template, initialValues, channel } = props

    let pickFields = ['body']
    if(channel === 'email') {
      pickFields = ['subject', 'body']
    }

    if (!initialValues) {
      const contents = _.get(template, 'content')
      const defaultValues = _.reduce(contents, (memo, content, key) => {
        memo.content[key] = _.pick(content, pickFields)
        return memo
      }, { content: {} })
      return { initialValues: defaultValues }
    }
  }),
  FullScreenLoader
)

export default enhancer(CommunicationsTemplateForm)
