import React, { useState, useEffect } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Dropdown from '../../../../components/Fields/Dropdown'
import style from './style'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as analyticsSelectors } from '../../../../store/modules/platformAnalytics'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { replace } from 'connected-react-router'
import { push } from 'connected-react-router'
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs'
import OrganisationAssociationLookup from '../../../../components/Fields/OrganisationAssociationLookup'
import { FormControl } from '@material-ui/core'
import { translations, dateFormat, timeFormat } from '../../../../config'
import Grid from '@material-ui/core/Grid'
import DatePicker from '../../../../components/Fields/DatePicker'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const reportTypeOptions = [
  { label: 'All Reports', value: 'AllReports' },
  { label: 'Initial Report Only', value: 'InitialReportOnly' },
  { label: 'Latest Version of Report', value: 'LatestVersionOfReport' }
]

const dateRangeOptions = [
  {
    label: translations('Analytics - Date Filter - Last 24 Hours'),
    value: 'today'
  },
  {
    label: translations('Analytics - Date Filter - Last 7 Days'),
    value: 'thisWeek'
  },
  {
    label: translations('Analytics - Date Filter - Last 30 Days'),
    value: 'thisMonth'
  },
  {
    label: translations('Analytics - Date Filter - Past Year'),
    value: 'pastYear'
  },
  {
    label: translations('Analytics - Date Filter - Custom'),
    value: 'custom'
  }
]

const AnalyticsFilters = props => {
  const {
    classes,
  } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const query = _.get(location, 'search')

  const selectedOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)

  const loggedInUserOrganisationsOptions = useSelector(authSelectors.getUserOrganisationsOptions)

  const organisationId = useSelector(analyticsSelectors.getSelectedOrganisationId) || selectedOrganisationId
  const { timePeriod = 'today', dateFrom, dateTo, organisationIds = [organisationId], sourceId = 'AllSources' } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [showDateRange, setShowDateRange] = useState(timePeriod === 'custom')
  const orgIds = typeof organisationIds === 'string' ? [organisationIds] : organisationIds

  const selectTimePeriod = (e) => {
    const timePeriod = e.target.value
    setShowDateRange(timePeriod === 'custom')
    setTimePeriod(timePeriod, dateFrom, dateTo, reportType, organisationIds)
  }

  const sourceOptions = useSelector(analyticsSelectors.getSourcesForSelectedOrganisationAsOptionsIncludedAll)

  const { widgetType } = useParams()
  const { reportType } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [reportTypeDropDown, setReportTypeDropDown] = useState(reportType || 'AllReports')

  const selectDateFrom = (e) => {
    const dateFrom = e
    setTimePeriod(timePeriod, dateFrom, dateTo, reportType, organisationIds)
  }

  const selectDateTo = (e) => {
    const dateTo = e
    setTimePeriod(timePeriod, dateFrom, dateTo, reportType, organisationIds)
  }

  const onChangeOrganisation = event => {
    const ids = event.target.value
    setOrganisationIds(ids)
  }

  const onChangeSource = event => {
    const sourceId = event.target.value
    setSourceId(sourceId)
  }

  const setSourceId = (sourceId) => {
    if (!_.isEmpty(sourceId)) {
      dispatch(replace({ search: qs.stringify({ timePeriod, dateFrom, dateTo, reportType, organisationIds, sourceId }, { arrayFormat: 'repeat' }) }))
    }
  }

  const setOrganisationIds = (orgIds) => {
    const organisationIds = typeof orgIds === 'string' ? [orgIds] : orgIds
    dispatch(replace({ search: qs.stringify({ timePeriod, dateFrom, dateTo, reportType, organisationIds, sourceId }, { arrayFormat: 'repeat' }) }))
  }

  const onChangeReportType = event => {
    const reportTypeValue = event.target.value
    setReportTypeDropDown(reportTypeValue)
    dispatch(replace({ search: qs.stringify({ timePeriod, dateFrom, dateTo, reportType: reportTypeValue, organisationIds, sourceId }, { arrayFormat: 'repeat' }) }))
  }

  const setTimePeriod = (timePeriod, dateFrom, dateTo, reportType, organisationIds) => {
    dispatch(replace({ search: qs.stringify({ timePeriod, dateFrom, dateTo, reportType, organisationIds, sourceId }, { arrayFormat: 'repeat' }) }))
  }

  const organisationComponent =
    _.size(loggedInUserOrganisationsOptions) > 1 ?
      (
        <FormControl className={classes.dropdown}>
          <Dropdown
            label={'Organisation'}
            value={orgIds}
            options={loggedInUserOrganisationsOptions}
            onChange={onChangeOrganisation}
            passedProps={{ multiple: true }}
            multiple={true}
            noErrorTextLabel
            class={classes.organisation}
          />
        </FormControl>
      ) : null

  const dropdownProps = []

  if (widgetType === 'report') {
    dropdownProps.push({
      options: reportTypeOptions,
      value: reportTypeDropDown,
      onChange: onChangeReportType,
      name: 'select-reportType',
      label: 'Report Type'
    })
  }

  if (widgetType === 'report' && !_.isEmpty(sourceOptions)) {
    dropdownProps.push({
      options: sourceOptions,
      value: sourceId,
      onChange: onChangeSource,
      name: 'select-source',
      label: 'Report Source'
    })
  }

  return (
    <div class={classes.centerContent}>
      <Column>
        <div className={classes.filters}>
          {organisationComponent}
          {
            dropdownProps.map((someProps, i) =>
              <FormControl className={classes.dropdown}>
                <Dropdown
                  key={someProps.name}
                  {...someProps}
                  givenClasses={classes}
                  noNullOption
                />
              </FormControl>
            )
          }
        </div>
      </Column>
      <Column>
        <div className={classes.filters}>
          <FormControl className={classes.dropdown}>
            <Dropdown
              key='select-timePeriod'
              value={timePeriod}
              onChange={selectTimePeriod}
              label={'Date Range'}
              options={dateRangeOptions}
              noNullOption
              noErrorTextLabel
              shrink
            />
          </FormControl>
          {showDateRange &&
            <FormControl className={classes.dropdown}>
              <DatePicker
                label={translations('Date From')}
                value={dateFrom}
                onChange={selectDateFrom}
                noErrorTextLabel
                class={classes.datepicker}
                shrink
              />
            </FormControl>
          }
          {showDateRange &&
            <FormControl className={classes.dropdown}>
              <DatePicker
                label={translations('Date To')}
                value={dateTo}
                onChange={selectDateTo}
                noErrorTextLabel
                class={classes.datepicker}
                shrink
              />
            </FormControl>
          }
        </div>
      </Column>
    </div>
  )
}

export default withStyles(style)(AnalyticsFilters)
