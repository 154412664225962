import _ from 'lodash'
import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import { selectors as authSelectors } from '../auth'
import { selectors as userDetailsSelectors } from '../userDetails'
import { selectors as rolesSelectors } from '../roles'
import { selectors as organisationSelectors } from '../organisations'
import { selectors as platformSelectors } from '../platforms'
import { selectors as usersSelectors } from '../users'
import { selectors as sourceSelectors } from '../sources'
import * as reportDetailsSelectors from '../reportDetails/selectors'
import { stringManipulation, getFullName } from '../../../helpers'
import { menu, dashboard, translations } from '../../../config'
import { permissionsLookup } from '../../../ability'
import { Ability } from '@casl/ability'

export const getCurrentUserRole = createSelector([
  authSelectors.getCurrentUser
], (currentUser) => {
  return _.get(currentUser, 'role.value')
})

export const getSelectedUserRole = createSelector([
  userDetailsSelectors.getSelectedUser,
], (selectedUser) => {
  return _.get(selectedUser, 'role.value')
})

export const getAdminEditPermission = createSelector([
  getCurrentUserRole,
  getSelectedUserRole,
], (currentUserValue, selectedUserValue) => {
  // super admins can edit everyone
  // if current user is an admin AND selected user is not a super admin
  if ((currentUserValue === 0) || (currentUserValue === 1 && selectedUserValue !== 0)) {
    return true
  }
})

export const getCanUserEdit = createSelector([
  getCurrentUserRole,
  getSelectedUserRole,
  authSelectors.getHasUserManagement,
  platformSelectors.getUserManagementConfig,
  getAdminEditPermission
], (currentUserValue, selectedUserValue, hasUserManagement, userManagementConfig, getAdminEditPermissionValue) => {
  if (getAdminEditPermissionValue) {
    return true
  }
  if (currentUserValue && selectedUserValue) {
    // Need to allow org leads to deactivate other org leads for SABRE
    const allowSameRoleDeactivation = _.get(userManagementConfig, 'allowSameRoleDeactivation', false)

    if (allowSameRoleDeactivation) {
      return (currentUserValue <= selectedUserValue)
    }
    return (currentUserValue < selectedUserValue)
  } else {
    return hasUserManagement
  }
})

export const getCanUserEditRole = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser,
  authSelectors.getHasUserManagement,
  platformSelectors.getUserManagementConfig
], (currentUser, selectedUser, hasUserManagement, userManagementConfig) => {
  const currentUserValue = _.get(currentUser, 'role.value')
  const selectedUserValue = _.get(selectedUser, 'role.value')
  const selectedUserId = _.get(selectedUser, 'id')
  const currentUserId = _.get(currentUser, 'id')
  // super admins can edit everyone
  // super admins and admins can edit themselves
  if (
    (currentUserValue === 0) ||
    ((currentUserValue === 1 || currentUserValue === 2) && currentUserId === selectedUserId)
  ) {
    return true
  }
  if (currentUserId === selectedUserId) {
    return false
  }
  if (currentUser && selectedUser) {
    // Need to allow org leads to deactivate other org leads for SABRE
    const allowSameRoleDeactivation = _.get(userManagementConfig, 'allowSameRoleDeactivation')
    if (allowSameRoleDeactivation) {
      return (currentUserValue <= selectedUserValue)
    }
    return (currentUserValue < selectedUserValue)
  } else {
    return hasUserManagement
  }
})

export const getAvailableRolesForUser = createSelector([
  rolesSelectors.getRolesForPlatform,
  authSelectors.getActiveUserValue
], (roles, userValue) => {
  return roles
    ? roles
      .filter(role => !role.isPublic)
      .filter(
        role => userValue === 0
          ? true
          : role.value >= userValue
      )
    : []
})

export const getAvailableRolesForUserOptions = createSelector([
  getAvailableRolesForUser
], (roles) => {
  return _.chain(roles)
    .map(role => ({ value: role.id, label: stringManipulation.formatRoleName(role.name) }))
    .value()
})

export const availableRoleNamesForUser = createSelector([
  getAvailableRolesForUser
], roles => roles.map(role => stringManipulation.formatRoleName(role.name)))

export const getAvailableRoleNameOptionsForUser = createSelector([
  getAvailableRolesForUser
], roles => roles.map(role => ({ value: role.id, label: translations(`Role - ${role.name}`), name: role.name })))

export const isCurrentUserTempOrSales = createSelector([
  authSelectors.getCurrentUser
], currentUser => {
  return currentUser && (currentUser.role.value === 4 || currentUser.role.value === 5)
})

export const canViewBetaApps = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('view', 'reportConfigurationNext')
})

export const canViewDeveloper = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('view', 'developer')
})

export const isIntegrationManagementAllowed = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('view', 'integrationManagement')
})

export const canViewPermissions = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('view', 'permissionsEditor')
})

export const canEditPermissions = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('editPermissions', 'permissionsEditor')
})


export const canViewOwnOrganisation = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('viewOwnOrganisation', 'organisationManagement')
})

export const isCurrentUserSelectedUser = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser
], (currentUser, selectedUser) => {
  return currentUser && selectedUser && currentUser.id === selectedUser.id
})

export const isCurrentUserAbleToEditPersonalDetails = createSelector([
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEdit
], (selectedUserIsDeactivated, isCurrentUserSelectedUserValue, editable) => {
  return !selectedUserIsDeactivated && (isCurrentUserSelectedUserValue || editable)
})

export const isCurrentUserAbleToEditPassword = createSelector([
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEdit
], (selectedUserIsDeactivated, isCurrentUserSelectedUserValue, editable) => {
  return !selectedUserIsDeactivated && (isCurrentUserSelectedUserValue || editable)
})

export const isCurrentUserAbleToEditRoleAndOrganisations = createSelector([
  userDetailsSelectors.getSelectedUserValue,
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEditRole
], (selectedUserRoleValue, selectedUserIsDeactivated, isCurrentUserSelectedUserValue, editable) => {
  // cannot edit super admin roles
  if (selectedUserRoleValue === 0) {
    return false
  }
  return !selectedUserIsDeactivated && editable
})

export const isCurrentUserAbleToViewRoleAndOrganisations = createSelector([
  getCanUserEditRole,
  authSelectors.getCurrentUser,
], (canUserEditRole, currentUser) => {
  const multpleOrganisationsRole = _.get(currentUser, 'role.multipleOrganisations')
  return canUserEditRole || multpleOrganisationsRole
})

export const isCurrentUserAbleToEditProfession = createSelector([
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEdit,
  authSelectors.getIsUserAdmin,
  isCurrentUserAbleToEditRoleAndOrganisations
], (selectedUserIsDeactivated, currentUserIsSelectedUser, canUserEdit, isUserAdmin, canEditRoleAndOrganisations) => {
  if (isUserAdmin && !canEditRoleAndOrganisations) {
    // even though they kinda should have the power to edit their profession...
    // they are associated to multiple orgs (because they are an admin)
    // and they can't stop their role being admin because they can't edit roles
    // and users with multiple orgs can't have a profession
    // because professions are associated with orgs
    // so they can never actually edit their profession
    return false
  }
  return !selectedUserIsDeactivated && (currentUserIsSelectedUser || canUserEdit)
})

export const isCurrentUserAbleToDeactivateUser = createSelector([
  isCurrentUserSelectedUser,
  getCanUserEdit
], (isCurrentUserSelectedUserValue, editable) => {
  return !isCurrentUserSelectedUserValue && editable
})

export const getAllRolesOptionsWithPermissions = createSelector([
  rolesSelectors.getAllRoles,
  authSelectors.getHasHigherUserAccess,
  authSelectors.getCurrentUser
], (roles = [], higherUserAccess, currentUser) => {
  if (higherUserAccess) {
    return rolesSelectors.convertRoleToOptions(roles)
  }

  const roleValue = _.get(currentUser, 'role.value')
  const filteredRoles = roles.filter(role => role.value >= roleValue)
  return rolesSelectors.convertRoleToOptions(filteredRoles)
})

export const getDetailsForSelectedOrganisation = createSelector([
  authSelectors.getUserSelectedOrganisationId,
  organisationSelectors.getAllOrganisations
], (selectedOrganisationId, organisations = []) => {
  const organisation = organisations.find(({ id }) => id === selectedOrganisationId)
  return _.get(organisation, 'details', {})
})

export const getOrganisationProfessions = createSelector([
  authSelectors.getUserSelectedOrganisationId,
  organisationSelectors.getAllOrganisations
], (selectedOrganisationId, organisations = []) => {
  const organisation = organisations.find(({ id }) => id === selectedOrganisationId)
  return _.get(organisation, 'professions', [])
})

export const getIsNewsAllowedForSelectedOrganisation = createSelector([
  getDetailsForSelectedOrganisation
], (orgDetails) => {
  return _.get(orgDetails, 'isNewsAllowed', false)
})

export const getIsUsingMedDRAForSelectedOrganisation = createSelector([
  getDetailsForSelectedOrganisation
], (orgDetails) => {
  return _.get(orgDetails, 'isUsingMedDRATerms', false)
})

export const getIsMandatoryMedDRAForSelectedOrganisation = createSelector([
  getDetailsForSelectedOrganisation
], (orgDetails) => {
  const isMeddraMandatory = _.get(orgDetails, 'isMandatoryMedDRA', false)
  return isMeddraMandatory
})

export const getIsUsingHospitalsUKListForSelectedOrganisation = createSelector([
  getDetailsForSelectedOrganisation
], (orgDetails) => {
  return _.get(orgDetails, 'isUsingHospitalsUKList', false)
})

// # Below selectors are for menu items filters by user and organisation context

const filterMenuItem = (
  hasUserManagement,
  isAdmin,
  canViewReports,
  canViewFiles,
  canEditReportConfig,
  _canViewAnalytics,
  canConfigureCases,
  canViewBetaApps,
  canViewResourceUsingPolicy,
  canViewOrganisation,
  canViewDeveloper,
  isIntegrationManagementAllowed
) => ({ id }) => {
  const allowed = !(
    (id === 'USER_MANAGEMENT' && !hasUserManagement) ||
    (id === 'ORGANISATION_MANAGEMENT' && !canViewOrganisation) ||
    (id === 'PLATFORMS' && !isAdmin) ||
    (id === 'REPORT_MANAGEMENT' && !canViewReports) ||
    (id === 'PHARMACEUTICAL_REFERRALS' && !canViewFiles) ||
    (id === 'REPORT_CONFIGURATION' && !canEditReportConfig) ||
    (id === 'CASE_MANAGEMENT' && !canConfigureCases) ||
    (id === 'CHANGE_MANAGEMENT' && false) ||
    (id === 'DEVELOPER' && !canViewDeveloper) ||
    (id === 'INTEGRATION_MANAGEMENT' && !isIntegrationManagementAllowed) ||
    (id === 'EXPORT_MANAGEMENT' && false)
  ) && canViewResourceUsingPolicy[id]
  return allowed
}

/**
* @description Check if all policies applied to the user allow access to Vigilance Hub Applications.
* @param {object} parameters
* @param {keyof permissionsLookup} parameters.app
*/
export const canViewResourceUsingPolicy = createSelector([authSelectors.getPermissions, authSelectors.getIsCustomPermissionsPolicy], (permissions, isCustomPolicy) => {
  const ability = new Ability(permissions)
  /**
   * A permissionsLookUp with applied changes,
   */
  const policyAppliedPermissions = _.mapValues(permissionsLookup, (resource) => {
    // Handle permissions when the policy has been customised
    // For example the user has add user or org-user level permissions applied in user management.
    if (isCustomPolicy) {
      switch (resource) {
        case 'userManagement':
          // If user can view and edit permissions, then they can view user management
          return (ability.can('view', 'permissionsEditor') && ability.can('editPermissions', 'permissionsEditor'))
        default:
          return ability.can('view', resource)
      }
    }
    // Handle stock permissions from the system like roles.
    return ability.can('view', resource)
  })
  return policyAppliedPermissions
})

export const getMenuItems = createSelector([
  authSelectors.getHasUserManagement,
  authSelectors.getIsUserAdmin,
  authSelectors.getCanViewOwnReports,
  authSelectors.getCanViewFiles,
  authSelectors.getCanEditReportConfig,
  authSelectors.getCanViewAnalytics,
  authSelectors.getHasConfigureCasesPermission,
  canViewBetaApps,
  canViewResourceUsingPolicy,
  authSelectors.getCanViewOrganisation,
  canViewDeveloper,
  canViewPermissions
], (...auth) => {
  return {
    menu: menu.filter(filterMenuItem(...auth)),
    dashboard: dashboard.filter(filterMenuItem(...auth))
  }
})

// Add any app id here to prevent/grant access to it being
// viewed. A message will be displayed when viewing the page.
// This DOES NOT control whether the tile or menu item is shown.
export const getAllowedAppsForSelectedOrganisation = createSelector([
  getIsNewsAllowedForSelectedOrganisation,
  platformSelectors.getHasWatchListPermissions,
  platformSelectors.getHasPharmaceuticalReferralsPermissions,
  platformSelectors.getHasReportsPermissions,
  authSelectors.getHasUserManagement,
  authSelectors.getHasConfigureOrgPermission,
  authSelectors.getHasPlatformDetailsPermissions,
  authSelectors.getCanViewOwnReports,
  //authSelectors.getCanViewFiles,
  authSelectors.getCanEditReportConfig,
  authSelectors.getHasCommunicationPermissions,
  platformSelectors.getHasProductsPermissions,
  platformSelectors.getHasAnalyticsEnabled,
  authSelectors.getCanViewAnalytics,
  authSelectors.getHasConfigureCasesPermission,
  platformSelectors.getHasCaseManagementEnabled,
  canViewBetaApps,
  canViewResourceUsingPolicy,
  platformSelectors.getHasRCBetaEnabled,
  platformSelectors.getHasDeveloperMenuEnabled,
  platformSelectors.getHasCommunicationsEnabled,
  platformSelectors.getHasChangeManagementEnabled,
  canViewOwnOrganisation,
  canViewDeveloper,
  platformSelectors.getHasIntegrationManagementEnabled,
  platformSelectors.getHasTerminologyEnabled,
  platformSelectors.getHasWebsiteManagementEnabled,
  platformSelectors.getHasPermissionsEditorEnabled,
  canViewPermissions,
  platformSelectors.getHasExportManagementEnabled,
], (
  isNewsAllowed,
  isWatchlistAllowed,
  isPharmaceuticalReferralsAllowed,
  isReportsAllowed,
  isUserManagementAllowed,
  isOrganisationManagementAllowed,
  isPlatformManagementAllowed,
  isReportManagementAllowed,
  isReportConfigurationAllowed,
  isCommunicationsAllowed,
  isProductsAllowed,
  isAnalyticsEnabledForPlatform,
  isAnalyticsAllowed,
  isCaseManagementAllowed,
  isCaseManagementEnabledForPlatform,
  canViewBetaApps,
  canAccessResources,
  isRCBetaAllowed,
  isDeveloperMenuAllowed,
  isCommunicationsTileAllowed,
  isChangeManagementAllowed,
  canViewOwnOrganisation,
  canViewDeveloper,
  isIntegrationManagementAllowed,
  isTerminologyAllowed,
  isWebsiteManagementAllowed,
  isPermissionsEditorEnabled,
  canViewPermissions,
  isExportManagementEnabled
) => {
  return {
    REPORTS: canAccessResources.REPORTS && isReportsAllowed,
    NEWS: canAccessResources.NEWS && isNewsAllowed,
    WATCH_LIST: canAccessResources.WATCH_LIST && isWatchlistAllowed,
    PHARMACEUTICAL_REFERRALS: canAccessResources.PHARMACEUTICAL_REFERRALS && isPharmaceuticalReferralsAllowed,
    USER_MANAGEMENT: canAccessResources.USER_MANAGEMENT && isUserManagementAllowed,
    ORGANISATION_MANAGEMENT: canAccessResources.ORGANISATION_MANAGEMENT && isOrganisationManagementAllowed,
    OWN_ORGANISATION: canViewOwnOrganisation,
    PLATFORMS: canAccessResources.PLATFORMS && isPlatformManagementAllowed,
    REPORT_MANAGEMENT: canAccessResources.REPORT_MANAGEMENT && isReportManagementAllowed,
    CASE_MANAGEMENT: canAccessResources.CASE_MANAGEMENT && isCaseManagementEnabledForPlatform && isCaseManagementAllowed,
    REPORT_CONFIGURATION: canAccessResources.REPORT_CONFIGURATION && isReportConfigurationAllowed,
    REPORT_CONFIGURATION_NEXT: canAccessResources.REPORT_CONFIGURATION_NEXT && canViewBetaApps && isRCBetaAllowed,
    COMMUNICATIONS: isCommunicationsTileAllowed && canAccessResources.COMMUNICATIONS && isCommunicationsAllowed,
    PRODUCTS: canAccessResources.PRODUCTS && isProductsAllowed,
    ANALYTICS: canAccessResources.ANALYTICS && isAnalyticsEnabledForPlatform && isAnalyticsAllowed,
    DEVELOPER: canAccessResources.DEVELOPER && isDeveloperMenuAllowed && canViewDeveloper,
    CHANGE_MANAGEMENT: canAccessResources.CHANGE_MANAGEMENT && isChangeManagementAllowed,
    INTEGRATION_MANAGEMENT: canAccessResources.INTEGRATION_MANAGEMENT && isIntegrationManagementAllowed,
    TERMINOLOGY: canAccessResources.TERMINOLOGY && isTerminologyAllowed,
    WEBSITE_MANAGEMENT: canAccessResources.WEBSITE_MANAGEMENT && isWebsiteManagementAllowed,
    PERMISSIONS_EDITOR: canAccessResources.PERMISSIONS_EDITOR && isPermissionsEditorEnabled && canViewPermissions,
    EXPORT_MANAGEMENT: canAccessResources.EXPORT_MANAGEMENT && isExportManagementEnabled
  }
})

export const getAvailableMenuItemsForSelectedOrganisation = createSelector([
  getAllowedAppsForSelectedOrganisation,
  getMenuItems
], (allowedApps, menuItems) => {
  return menuItems.menu.filter(item => allowedApps[item.id] !== false)
})

export const getAvailableDashboardItemsForSelectedOrganisation = createSelector([
  getAllowedAppsForSelectedOrganisation,
  getMenuItems
], (allowedApps, menuItems) => {
  return menuItems.dashboard.filter(item => allowedApps[item.id] !== false)
})

const optionFromUser = (user) => ({
  label: getFullName(user),
  value: user.id
})
const optionFromMe = (user) => {
  const option = optionFromUser(user)
  option.label = translations('Notification - Me', { name: option.label })
  return option
}

export const getUserOptionsIncludingCurrentUser = createSelector([
  usersSelectors.getResults,
  authSelectors.getActiveUserId,
  authSelectors.getCurrentUser
], (allUsers, activeUserId, activeUser) => {
  let userOptionGroups = []

  userOptionGroups.push([optionFromMe(activeUser)])

  const activeUserIndex = allUsers.findIndex(user => user.id === activeUserId)
  const hasActiveUser = activeUserIndex > -1

  let otherUsers = [...allUsers]
  if (hasActiveUser) {
    otherUsers.splice(activeUserIndex, 1)
  }
  userOptionGroups.push(
    _.chain(otherUsers)
      .sortBy('username')
      .map(optionFromUser)
      .value()
  )
  return userOptionGroups
})

export const isCurrentPublicUsersProfile = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser
], (currentUser, selectedUser) => {
  const currentUserValue = _.get(currentUser, 'role.value')
  const selectedUserId = _.get(selectedUser, 'id')
  const currentUserId = _.get(currentUser, 'id')
  return currentUserId === selectedUserId && currentUserValue === 6
})

export const isSelectedUserPublic = createSelector([
  userDetailsSelectors.getSelectedUser
], (selectedUser) => {
  const selectedUserValue = _.get(selectedUser, 'role.value')
  return selectedUserValue === 6
})

export const isSelectedUserRoleEqualToCurrent = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser
], (currentUser, selectedUser) => {
  const currentUserValue = _.get(currentUser, 'role.value')
  const selectedUserValue = _.get(selectedUser, 'role.value')
  return currentUserValue === selectedUserValue
})

export const canUserModifyPermissions = createSelector([
  isSelectedUserPublic,
  getAdminEditPermission,
  getCurrentUserRole,
  getSelectedUserRole,
  authSelectors.getHasUserManagement,
], (isSelectedUserPublicValue, getAdminEditPermissionValue, currentUserValue, selectedUserValue, hasUserManagement) => {
  if (isSelectedUserPublicValue) {
    return false
  }

  if (getAdminEditPermissionValue) {
    return true
  }

  if (hasUserManagement && currentUserValue && selectedUserValue) {
    return currentUserValue <= selectedUserValue
  } else {
    return false
  }
})

/**
 * Calculate whether the current user can see report updates
 *
 * Public users can only see updates to reports if the current platform has the permission denyPublicUpdates = false
 *
 */
export const userCanSeeReportUpdates = createSelector([
  platformSelectors.getHasDenyPublicUpdatesPermissions,
  authSelectors.isPublicOrUnknownRole
], (denyPublicUpdates, isPublic) => {
  return !isPublic || !denyPublicUpdates
})

export const getHasUpdateReportsPermissions = createSelector([
  platformSelectors.getCurrentPlatformPermissions,
  authSelectors.getIsUserAdmin,
  userCanSeeReportUpdates
], (currentPlatformPermissions, adminUser, userCanUpdate) => {
  if (adminUser) {
    return true
  }
  return _.get(currentPlatformPermissions, 'updateReport', false) && userCanUpdate
})

export const canExportAssessessmentPdf = createSelector(
  [
    reportDetailsSelectors.getCanExportPdf,
    reportDetailsSelectors.getHasAssessmentSchemaItems,
    authSelectors.isPublicOrUnknownRole
  ], (
    canExportPdf,
    reportHasAssessmentSchemaItems,
    userIsPublicOrUnknown
  ) => {
  return canExportPdf && reportHasAssessmentSchemaItems && !userIsPublicOrUnknown
})

export const getCurrentOrganisationSources = createSelector([
  sourceSelectors.getAllSources,
  authSelectors.getUserSelectedOrganisationId
], (sources, organisationId) => {
  return _.get(sources, organisationId)
})

export const canUserSeeWorkspaceOption = createSelector([
  authSelectors.getHasSinglePlatformRole,
  platformSelectors.hasWorkspacesEnabled
], (hasSinglePlatformRole, platformHasWorkspaces) => {
  return hasSinglePlatformRole && platformHasWorkspaces
})

export const canUseAdminOptionsForReport = authSelectors.isSuperAdmin

export const canResendTransmissions = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('resendTransmissions', 'caseManagement')
})

export const canReassignReports = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('reassignReports', 'reportManagement')
})

export const canUploadResource = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('uploadResource', 'resources')
})

export const canDeleteResource = createSelector([
  authSelectors.getPermissions
], permissions => {
  const ability = new Ability(permissions)
  return ability.can('deleteResource', 'resources')
})

export const getCanEditAccountType = createSelector([
  getCurrentUserRole,
  getSelectedUserRole,
  isSelectedUserPublic
], (currentUserValue, selectedUserValue, isSelectedUserPublicValue) => {
  // super admins, admin, platform managaer can edit everyone
  if ((currentUserValue < 3 || currentUserValue > selectedUserValue) && !isSelectedUserPublicValue) {
    return true
  }
  return false
})


export const canAccessAnnouncementManagement = createSelector([
  authSelectors.getPermissions,
  platformSelectors.isAnnouncementManagementEnabled,
  authSelectors.getRoleValue
], (permissions, enabledOnPlatform, userRole) => {
  const ability = new Ability(permissions)
  const hasPolicyToView = ability.can('viewAnnouncementManagement', 'announcements')
  if (userRole === 0) {
    return hasPolicyToView
  }
  return hasPolicyToView && enabledOnPlatform
})
