import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import VisuallyHidden from '@reach/visually-hidden'
import Label from '../Label'
import Field from '../Field'
import { StyledSelect } from '../SelectBox/SelectBox'
import Hint from '../Hint'
import ErrorMessage from '../ErrorMessage'
import NullFlavourField from '../NullFlavourField'

import {
  parseDate,
  getMonths,
  getDays,
  stringifyDate
} from './functions'
import { useInputType } from '../../hooks'
import { getYears } from '../../helpers'

const SelectBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`

const SelectBoxColumn = styled.div`
  & + & {
    margin-left: 10px;
  }
  ${media.greaterThan('small')`
    & + & {
      margin-left: 15px;
    }
  `}
`

const DateSelect = styled(StyledSelect)`
  display: flex;
  flex-direction: row;
  width: ${props => props.big ? '120px' : '80px'};
  &:disabled {
    cursor: not-allowed;
  }
`

const PickerInput = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  &:disabled {
    cursor: not-allowed;
  }
`

const IconWrapper = styled.div`
  display: flex;
  position: relative;
`

const CalendarIcon = styled.div`
  background-image: ${props => `url(${props.theme.icons.calendarRange})`};
  background-position: center center;
  background-size: 30px;
  background-repeat: no-repeat;
  width: inherit;
  height: inherit;
`

const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconLabel = styled.label`
  height: 30px;
  width: 30px;
  margin-left: 10px;
  ${media.greaterThan('small')`
    margin-left: 15px;
`}
`

/**
 * Date
 */
const DateInput = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur,
    },
    meta: {
      error,
      touched,
      active
    },
    id,
    label,
    hint,
    required,
    disabled,
    startYear,
    startDate,
    endDate,
    acceptedDateFormats,
    endYearOffset,
    nullFlavours
  } = props

  const isBrowserCompatible = useInputType('date')
  const fieldInvalid = error && touched
  const errorMessage = !active && fieldInvalid && error
  const allowFullDate = !acceptedDateFormats?.length || acceptedDateFormats.includes('YYYYMMDD')
  const valueParts = parseDate(value)
  const startDateParts = parseDate(startDate)
  const endDateParts = parseDate(endDate)

  const onDateChange = type => event => (onChange && onChange(stringifyDate(event, value, type)))
  const onDatePicked = event => onChange && onChange(_.replace(event.target.value, /-/gi, ''))

  const handleFocus = (params) => {
    !active && onFocus(params)
  }

  // Prevents onBlur from being called when the new target is an inner/child div
  const handleBlur = event => {
    if (!event.currentTarget.parentNode.parentNode.contains(event.relatedTarget)) {
      onBlur()
    }
  }

  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)

  const renderDateComponent = () => {
    return (
      <Field as={'fieldset'} error={errorMessage} tabIndex={error ? '-1' : ''} name={name}>
        <VisuallyHidden><legend>{label}</legend></VisuallyHidden>

        <Label as='h3' required={required}>{label}</Label>
        {errorMessage && <ErrorMessage error={errorMessage} />}
        {hint && <Hint message={hint} />}
        <SelectBoxRow>
          <SelectBoxColumn>
            <Label name={`${id}_year`} nested required>Year</Label>
            <DateSelect
              id={`${id}_year`}
              aria-required={required}
              aria-invalid={fieldInvalid}
              label='year'
              disabled={disabled}
              as={'select'}
              value={valueParts.year}
              big
              error={errorMessage}
              onChange={onDateChange('year')}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              {['YYYY', ...getYears(startYear || startDateParts.year, endYearOffset, endDateParts.year)].map((opt) => {
                return <option key={`${opt}`} value={opt !== 'YYYY' ? opt : ''}>{opt}</option>
              })}
            </DateSelect>
          </SelectBoxColumn>
          {(allowFullDate || acceptedDateFormats.includes('YYYYMM')) &&
            <SelectBoxColumn>
              <Label name={`${id}_month`} nested required>Month</Label>
              <DateSelect
                id={`${id}_month`}
                aria-required={required}
                aria-invalid={fieldInvalid}
                label='month'
                disabled={disabled}
                as={'select'}
                value={valueParts.month}
                error={errorMessage}
                onChange={onDateChange('month')}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                {['MM', ...getMonths(valueParts.year, startDateParts, endDateParts)].map((opt) => {
                  return <option key={`${opt}`} value={opt !== 'MM' ? opt : ''}>{opt}</option>
                })}
              </DateSelect>
            </SelectBoxColumn>
          }
          {allowFullDate &&
            <SelectBoxColumn>
              <Label name={`${id}_day`} nested required>Day</Label>
              <RowCenter>
                <DateSelect
                  id={`${id}_day`}
                  aria-required={required}
                  aria-invalid={fieldInvalid}
                  label='day'
                  disabled={disabled}
                  as={'select'}
                  value={valueParts.day}
                  error={errorMessage}
                  onChange={onDateChange('day')}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  {['DD', ...getDays(valueParts.month, valueParts.year, startDateParts, endDateParts)].map((opt) => {
                    return <option key={`${opt}`} value={opt !== 'DD' ? opt : ''}>{opt}</option>
                  })}
                </DateSelect>
                {isBrowserCompatible && !disabled &&
                  <IconWrapper>
                    <IconLabel htmlFor="dateselect-calendar">
                      <CalendarIcon />
                    </IconLabel>
                    <PickerInput
                      id="dateselect-calendar"
                      type="date"
                      onChange={onDatePicked}
                      disabled={disabled}
                      min={startDate && `${startDateParts.year}-${startDateParts.month}-${startDateParts.day}`}
                      max={endDate && `${endDateParts.year}-${endDateParts.month}-${endDateParts.day}`}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      tabIndex="-1"
                    />
                  </IconWrapper>
                }
              </RowCenter>
            </SelectBoxColumn>
          }
        </SelectBoxRow>
      </Field>
    )
  }

  if (hasNullFlavours && !disabled) {
    return (
      <NullFlavourField
        {...props}
        render={renderDateComponent}
      />
    )
  }

  return (renderDateComponent())
}

DateInput.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the field is disabled */
  disabled: PropTypes.bool,
  /** Year to start the year dropdown from */
  startYear: PropTypes.number,
  /** Number of years to offset the maximum year dropdown selection by */
  endYearOffset: PropTypes.number,
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

DateInput.defaultProps = {
  required: false,
  input: {},
  meta: {}
}

export default DateInput
