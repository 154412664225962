import React from 'react'

const IntegrationManagementReceiversPanel = () => {
  return (
    <>
    </>
  )
}

export default IntegrationManagementReceiversPanel
