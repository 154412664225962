import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'recompose'
import digitalStoreSdk from '../../../../digitalStoreSdk'
import { useHistory } from 'react-router-dom'

import { styled } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ToolTip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { translations } from '../../../../config'
import Input from '../../../../components/Fields/Input'
import Dropdown from '../../../../components/Fields/Dropdown'
import DatePicker from '../../../../components/Fields/DatePicker'
import Checkbox from '../../../../components/Fields/Checkbox'
import { withFiltersContainer } from '../../../../components/FiltersContainer'
import { useApplicationOptions } from '../../../../store/modules/combinedHooks/reportManagementCombinedHooks'
import Chip from '../../../../components/Chip'
import Label from '../../../../components/Fields/Dropdown/Label'

import { withStyles, useTheme } from '@material-ui/core/styles'
import { useCurrentOrganisationAndPlatformSourcesOptions } from '../../../../store/modules/combinedHooks/rcCombinedHooks'
import styles from './style'

const SubmissionReportsSearchFilters = (props) => {
  const {
    filters = {},
    updateFilters: updateFiltersAction,
    classes,
    validationStatusOptions,
    receiverStatusOptions,
    receiverOptions,
    showReceiverStatusFilters
  } = props
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))
  const [stateFilters, setFilters] = useState(filters)
  const [userDisplayName, setUserDisplayName] = useState(null)
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)

  const applicationOptions = useApplicationOptions()
  const sourceOptions = useCurrentOrganisationAndPlatformSourcesOptions({ rcSupport: false })
  const history = useHistory()

  useEffect(() => {
    if (filters?.userId && !userDisplayName) {
      digitalStoreSdk.users.fetchUserDetails({ id: filters.userId })
        .then(user => {
          setUserDisplayName(user.displayName)
        })
    }
  }, [])

  useEffect(() => {
    if (!_.isEqual(stateFilters, filters)) {
      setFilters(filters)
    }
  }, [filters])

  const updateFilters = useCallback(
    _.debounce((filter) => {
      updateFiltersAction(filter)
    }, 500)
    , [filters]
  )

  const setFilter = (filter) => {
    setFilters({ ...stateFilters, ...filter })
    updateFilters(filter)
    if (filter.userId === null) {
      setUserDisplayName(null)
    }
  }

  const handleUserDeleteClick = () => {
    setFilter({userId: null})
    history.replace(history.location.pathname)
  }

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.filterBlock} justify={'center'}>
          {userDisplayName && <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Label label={translations('User')} />
              
              <ToolTip
                aria-label={userDisplayName}
                title={userDisplayName}
              >
                <Chip
                  variant={'outlined'}
                  label={userDisplayName}
                  onDelete={handleUserDeleteClick}
                  className={classes.chipStyle}
                />
              </ToolTip>
            </FormControl>
          </Grid>}
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Validation Status')}
                value={stateFilters.validationStatus || null}
                options={validationStatusOptions}
                onChange={(e) => setFilter({ validationStatus: e.target.value })}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Receiver Status')}
                value={stateFilters.receiverStatus || null}
                options={receiverStatusOptions}
                onChange={(e) => setFilter({ receiverStatus: e.target.value })}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Receiver')}
                value={stateFilters.receiverId || null}
                options={receiverOptions}
                onChange={(e) => setFilter({ receiverId: e.target.value })}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Application')}
                options={applicationOptions}
                value={stateFilters.application || null}
                onChange={(e) => setFilter({ application: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Source(s)')}
                options={sourceOptions}
                value={stateFilters.sources || null}
                onChange={(e) => setFilter({ sources: e.target.value })}
                multiple
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date From')}
                value={stateFilters.dateFrom}
                onChange={(e) => setFilter({ dateFrom: e })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date To')}
                value={stateFilters.dateTo}
                onChange={(e) => setFilter({ dateTo: e })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Display')}
                options={[
                  translations('Exclude Failed History')
                  // translations('Display Latest Versions Only')
                ]}
                value={stateFilters.display || null}
                onChange={(e) => setFilter({ display: e.target.value })}
              />
            </FormControl>
          </Grid>
          {
            isMediumUp &&
            <div className={classes.filterToggle}>
              <ToolTip
                aria-label={translations('Show Additional Filters')}
                title={translations('Show Additional Filters')}
              >
                <IconButton onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}>
                  {showAdditionalFilters
                    ? <ExpandLessIcon />
                    : <ExpandMoreIcon />
                  }
                </IconButton>
              </ToolTip>
            </div>
          }
        </Grid>
        <Collapse in={showAdditionalFilters || !isMediumUp} style={{ width: '100%' }}>
          <Grid container direction='row' spacing={2} className={classes.filterBlock} justify='center'>
            <Grid container xs={12} md={2} className={classes.checkBoxContainer} alignItems='center'>
              <FormControl className={classes.formControl}>
                <Checkbox
                  label={translations('Exclude Manually Processed')}
                  value={!!stateFilters.omitManuallyProcessed}
                  onChange={(e) => setFilter({ omitManuallyProcessed: !!e.target.checked })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </div>
      {
        showReceiverStatusFilters(stateFilters) && (
          <div className={classes.container}>
            <Grid container spacing={2} justify={'center'}>
              <Grid item xs={12} md={6} className={classes.leftContent}>
                <FormControl className={classes.formControl}>
                  <Input
                    label={translations('Filter by receiver error')}
                    value={stateFilters.receiverErrorQuery || ''}
                    onChange={(e) => setFilter({ receiverErrorQuery: e })}
                    clearable
                    noErrorTextLabel
                    passedProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    shrink
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )
      }
    </>
  )
}

SubmissionReportsSearchFilters.defaultProps = {
  validationStatusOptions: [],
  receiverStatusOptions: [],
  receiverOptions: []
}

SubmissionReportsSearchFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  validationStatusOptions: PropTypes.array.isRequired,
  receiverStatusOptions: PropTypes.array.isRequired,
  receiverOptions: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    validationStatus: PropTypes.oneOf([
      'VALID',
      'INVALID',
      'NOT_VALIDATED'
    ]),
    receiverStatus: PropTypes.oneOf([
      'NOT_SENT',
      'SUCCESS',
      'PENDING',
      'FAILED',
      'TIMED_OUT'
    ]),
    receiverId: PropTypes.string
  })
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(SubmissionReportsSearchFilters)
