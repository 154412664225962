import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { Link } from 'react-router-dom'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import Button from '../../../components/Button'
import GroupList from '../../../components/GroupList'
import List from '../../../components/List'
import { translations } from '../../../config'

import withReportData from '../withReportData'
import style from './style'

class ReportFullList extends Component {
  renderHashLinks () {
    const { groupedListData, classes } = this.props
    const nonEmptyGroupListData = groupedListData
      .filter(({ items }) => !!items.length)
    return (
      <div className={classes.buttonsContainer}>
        {
          nonEmptyGroupListData
          .map(({ name: groupName }) => (
            <div className={classes.buttonContainer} key={groupName}>
              <Link to={`#GroupList-${groupName}`} replace>
                <Button
                  buttonType='white'
                >
                  {groupName}
                </Button>
              </Link>
            </div>
          ))
        }
      </div>
    )
  }
  renderList () {
    const { groupedListData, listData, classes, listItem, itemProps } = this.props

    const ItemComponent = _.get(listItem, 'FullListItemComponent')
    const onClickItem = _.get(listItem, 'onClickItem')

    if (groupedListData) {
      return (
        <div className={classes.groupedListContainer}>
          {this.renderHashLinks()}
          <GroupList
            data={groupedListData}
            ItemComponent={ItemComponent}
            noMargin
            onClickItem={onClickItem}
            itemProps={itemProps}
          />
        </div>
      )
    } else if (listData) {
      return (
        <div className={classes.listContainer}>
          <List
            items={listData}
            ItemComponent={ItemComponent}
            noMargin
            onClickItem={onClickItem}
          />
        </div>
      )
    } else {
      return <div className={classes.noDataPlaceholder}>{translations('No data placeholder')}</div>
    }
  }

  render () {
    const { reportSchema } = this.props

    return (
      <div>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <H2 value={_.toUpper(reportSchema.title)} />
          )}
        />
        {this.renderList()}
      </div>
    )
  }
}

export default compose(
  withReportData,
  withStyles(style)
)(ReportFullList)
