import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import _ from 'lodash'

import FieldMeta from '../FieldMeta'
import Field from '../Field'

export const StyledFileInputLabel = styled.label``

export const StyledFileInputButton = styled.div`
  -webkit-appearance: none;
  border-radius: 0;
  background: ${props => props.theme.colors.secondary};
  opacity: ${props => props.disabled ? 0.5 : 1};
  color: #fff;
  display: inline-block;
  vertical-align: center;
  padding: 10px 15px;
  font-size: ${props => `${props.theme.type.button.small}px`};
  line-height: 1;
  border: none;
  font-weight: 700;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  margin: 0;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.button.large}px`};
    padding: 12px 25px;
  `}
  &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):focus {
    outline: none;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
  &:active {
    transform: translate(1px, 1px);
  }
`

export const StyledFileInputFileName = styled.span`
  appearance: none;
  width: 100%;
  height: ${rem('35px')};
  padding: 8px;
  border-radius: 0;
  line-height: 1.4;
  margin: 0;
  color: ${props => props.theme.colors.inputText};
  font-size: ${props => `${props.theme.type.input.small}px`};
  font-weight: 400;
  transition: box-shadow .3s ease-in-out;
  ${media.greaterThan('small')`
    height: ${rem('41px')};
    padding: 8px 12px;
    font-size: ${props => `${props.theme.type.input.large}px`};
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):focus {
    outline: none;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
  &:active {
    transform: translate(1px, 1px);
  }
`

export const StyledFileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  &:focus + ${StyledFileInputLabel} > ${StyledFileInputButton} {
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
`

/**
 * A basic file input
 */
const FileInput = (props) => {
  const {
    input: {
      name,
      onChange,
      onFocus,
      onBlur
    },
    meta: {
      error,
      touched,
      initial
    },
    id,
    label,
    hint,
    required,
    buttonText,
    placeholder,
    accept,
    disabled
  } = props
  const initialName = _.get(initial, 'name')
  const [filepath, setFilepath] = useState(null)
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  // Stops fileInput from losing focus when opening file selection box, which prevents premature error
  const handleBlur = (event) => event.target !== event.currentTarget && onBlur()

  return (
    <Field error={errorMessage}>
      <FieldMeta name={id} label={label} error={errorMessage} hint={hint} required={required} disabled={disabled} />
      <StyledFileInput
        id={id}
        name={name}
        type='file'
        mutliple={false}
        accept={accept}
        error={errorMessage}
        aria-required={required}
        aria-invalid={fieldInvalid}
        onChange={(event) => {
          if (onChange && _.get(event, 'target.files[0]')) {
            const file = _.get(event, 'target.files[0]')
            setFilepath(file.name)
            onChange(file)
          }
        }}
        onFocus={onFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <StyledFileInputLabel htmlFor={id}>
        <StyledFileInputButton disabled={disabled}>
          {buttonText}
        </StyledFileInputButton>
        <StyledFileInputFileName>
          {filepath || initialName || placeholder}
        </StyledFileInputFileName>
      </StyledFileInputLabel>
    </Field>
  )
}

FileInput.propTypes = {
  /** ID used for file input */
  id: PropTypes.string.isRequired,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  hint: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the input accepts multiple files */
  mutliple: PropTypes.bool,
  /** File selector button prompt text */
  buttonText: PropTypes.string,
  /** File selector no selected file name placeholder text */
  placeholder: PropTypes.string,
  /** Input props based from React Final Form
   * 
   * `name` - Used to associate label and input
   * 
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   * 
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

FileInput.defaultProps = {
  required: false,
  buttonText: 'Select file',
  placeholder: 'Please select a file',
  input: {},
  meta: {}
}

export default FileInput
