import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { reset } from 'redux-form'
import AddLanguageForm, { formName } from './AddLanguageForm'
import { actions as translationsActions, selectors as newsFeedsSelectors } from '../../../../../store/modules/translations'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'

const enhancer = compose(
  connect(),
  withHandlers({
    handleSubmit: ({ dispatch }) => async ({ language }) => {
      await dispatch(translationsActions.createTranslations(language))
      dispatch(reset(formName))
    }
  })
)

export default enhancer(AddLanguageForm)
