const style = theme => ({
  dropdown: {
    width: '15em',
    margin: '0 0.5em',
    textAlign: 'left',
    height: '80px'
  },
  field: {
    width: '100%'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  organisationsLookupFilter: {
    width: '250px'
  },
  dateFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '40px'
  },
  datePicker: {
    width: '100%',
  },
  formControl: {
    width: '300px',
    paddingRight: '10px'
  },
})

export default style
