import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class PlatformsActions {
  fetchAllPlatforms = () => ({
    type: constants.FETCH_ALL_PLATFORMS,
    promise: () => digitalStoreSdk.platforms.fetchAllPlatforms()
  })

  fetchPlatformForDomain = ({ domain }) => ({
    type: constants.FETCH_PLATFORM_FOR_DOMAIN,
    promise: () => digitalStoreSdk.platforms.fetchPlatformForDomain({ domain })
  })

  fetchPlatformAnnouncements = ({ platformId, ...query }) => ({
    type: constants.FETCH_PLATFORM_ANNOUNCEMENTS,
    promise: () => digitalStoreSdk.announcementManagement.fetchAnnouncementsForPlatform({ platformId, ...query })
  })
}

export default new PlatformsActions()
