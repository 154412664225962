import React from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import Form from '../../../../components/Form'
import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import BottomBar from '../../../../components/BottomBar'
import schema from '../../../../components/Form/commonSchemas/userPersonalDetails'
import SetPassword from '../UserRegistrationForm/SetPassword'
import Terms from '../UserRegistrationForm/Terms'
import { translations } from '../../../../config'
import { formStyle } from './style'
import OrganisationSelector from './OrganisationSelector/OrganisationSelector'

const formId = 'user-registration'
const SabreUserRegistrationForm = Form(formId)

const SabreUserRegistrationFormWrapper = ({
  classes,
  onSubmit,
  isPublic,
  isOrganisationInvite,
  organisationInvite
}) => {
  return (
    <div>
      <SabreUserRegistrationForm
        onSubmit={onSubmit}
        submitLabel={translations('Create user')}
        initialValues={{
          email: _.get(organisationInvite, 'email'),
          telephoneCountryCode: 'GB'
        }}
      >
        <FormBody>
          <div>
            <PersonalDetails
              formId={formId}
              schema={getSabreSchema({ isOrganisationInvite })}
            />
            {!isOrganisationInvite &&
              <OrganisationSelector isLookup={false} classes={classes} />
            }
            <SetPassword />
            {isPublic ? <Terms label={'User Registration - Terms and Conditions Agreement'} /> : null}
          </div>
        </FormBody>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={translations('Create user')}
        />
      </SabreUserRegistrationForm>
    </div>
  )
}

export const getSabreSchema = ({ isOrganisationInvite }) => {
  return schema({
    disableEmail: isOrganisationInvite,
    allFieldsMandatory: true,
    allowedFields: [
      'title',
      'firstName',
      'lastName',
      'email',
      'telephone'
    ],
    customFields: [
      {
        position: 4,
        value: {
          id: 'occupation',
          field: 'Input',
          props: {
            label: translations('Position/Occupation'),
            name: 'occupation',
            required: true,
            shrink: true
          }
        }
      }
    ]
  })
}

export default withStyles(formStyle)(SabreUserRegistrationFormWrapper)
