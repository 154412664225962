import React from 'react'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { translations } from '../../../../config'
import { FormBody, FormError } from '../../../../components/Form'

const formId = 'communication-preferences'
const CommunicationPreferencesForm = Editable(FormContentBox(formId))

const CommunicationPreferences = ({
  classes,
  editable,
  initialValues,
  onSubmit,
  canManageUsers,
  isViewingOrEditingAnotherUser
}) => {

  return (
    <CommunicationPreferencesForm
      editable={editable}
      enableReinitialize
      initialValues={initialValues}
      givenClass={classes.formContainer}
      onSubmit={onSubmit}
      boxName={translations('Communication Preferences')}
      formId={formId}
    >

      <FormError givenClass={classes.submitError} />
      <FormBody
        schema={getSchema({
          initialValues,
          canManageUsers,
          isViewingOrEditingAnotherUser
        })}
        globalClasses={{
          col: classes.columnStyle,
          row: classes.rowStyle
        }}
      />
    </CommunicationPreferencesForm>
  )
}

const getSchema = ({
  initialValues,
  canManageUsers,
  isViewingOrEditingAnotherUser
}) => {
  const preferencesSchema = [
    {
      id: 'emailCommunicationsPermitted',
      field: 'Checkbox',
      props: {
        ...canManageUsers && isViewingOrEditingAnotherUser && !initialValues.emailCommunicationsPermitted && { disabled: true },
        label: translations('Email'),
        name: 'emailCommunicationsPermitted',
        required: false
      }
    }
  ]
  if (initialValues.telephone) {
    preferencesSchema.push({
      id: 'smsCommunicationsPermitted',
      field: 'Checkbox',
      props: {
        ...canManageUsers && isViewingOrEditingAnotherUser && !initialValues.smsCommunicationsPermitted && { disabled: true },
        label: translations('SMS'),
        name: 'smsCommunicationsPermitted',
        required: false,
      }
    })
  }
  return preferencesSchema
}

export default CommunicationPreferences