import React from 'react'

import P from '../../../../../components/P'
import BottomBar from '../../../../../components/BottomBar'
import HealthCheckFilters from '../../../AnalyticsScreen/HealthCheckFilters/HealthCheckFilters'

import ReportFullListCollapsable from '../../../ReportFullListCollapsable'
import { translations } from '../../../../../config'


const OrganisationReportingFullList = props => {
  const { organisationHealthCheck, reportSchema, lastUpdated } = props
  return <>
    <HealthCheckFilters preventDropdown />
    <ReportFullListCollapsable
      items={organisationHealthCheck}
      reportSchema={reportSchema}
      itemProps={{ chartCategory: reportSchema.chartCategory }}
    />
    <BottomBar relative>
      <div style={{ textAlign: 'center' }}>
        <P value={`${translations('Last Updated At:')} ${lastUpdated}`} />
      </div>
    </BottomBar>
  </>
}

export default OrganisationReportingFullList
