import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING, FAIL } from '../../middleware/redux-promise'
import { getUserSelectedOrganisationId, getIsUserAdmin } from '../auth/selectors'

import { FETCH_USER_DETAILS, FETCH_CURRENT_USER_DETAILS } from './constants'

export const getSelectedUser = state => (state.userDetails || {}).user

export const getInitialUserDetailIsLoading = state => state.userDetails.status === PENDING && (state.userDetails.action === FETCH_USER_DETAILS || state.userDetails.action === FETCH_CURRENT_USER_DETAILS)
export const getFailedFetchCurrentUserDetailsStatus = state => state.userDetails.status === FAIL && (state.userDetails.action === FETCH_USER_DETAILS || state.userDetails.action === FETCH_CURRENT_USER_DETAILS)
export const getSelectedUserOrganisations = state => _.get(state.userDetails, 'user.organisations', [])
export const getUserWithModifiedRoleAndOrganisations = createSelector([
  getSelectedUser,
  getUserSelectedOrganisationId,
  getIsUserAdmin
], (user, selectedOrganisationId, isLoggedInUserAdmin) => {
  if (user) {
    const newUser = _.omit(user, ['role', 'organisations', 'professions', 'policies'])
    newUser.roleId = user.role.id

    // material UI needs an array for multi and a string for single
    if (_.includes([4,5,6], user.role.value)) { // If Public User handle profession.
      newUser.organisationIds = _.chain(user.organisations)
        .find({ id: selectedOrganisationId })
        .get('id', '')
        .value()
      newUser.professionId = _.chain(user.professions)
        .find({ organisationId: newUser.organisationIds })
        .get('id', '')
        .value()
    } else {
      newUser.organisationIds = _.map(user.organisations, 'id')
    }
    const policies = {}
    for (const policy of user.policies) {
      let resource = _.get(policy, ['resource']) + '-' + _.get(policy, ['action'])
      const defaultValue = _.get(policy, ['origin']) === 'role' ? 'DEFAULT' : null
      const filterReportsValue = _.get(policy, ['action']) === 'filterReports' ? _.get(policy, ['conditions', 'category']) : null
      const effectValue = _.get(policy, ['effect'])
      policies[resource] = defaultValue || filterReportsValue || effectValue
    }
    newUser.policies = policies
    newUser.occupation = newUser.details.occupation || ''
    return newUser
  }
})

export const isSelectedUserAdmin = createSelector([
  getSelectedUser
], (user) => {
  return _.get(user, 'role.isAdmin', false)
})

export const getSelectedUserValue = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user.role.value
  }
})

export const getSelectedUserIsLocked = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user && user.accountLocked
  }
})

export const getSelectedUserIsDeactivated = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user && user.accountDeactivated
  }
})

export const getMarkForDeletion = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user && user.isMarkedForDeletion
  }
})

export const getSelectedUserPendingChangeRequests = createSelector([
  getSelectedUser,
], (user) => {
  if (user) {
    const pendingChangeRequests = _.filter(user.changeRequests, { approvalStatus: 'PENDING' })
    return pendingChangeRequests
  }
})

export const getSelectedUserProfessions = createSelector([
  getSelectedUser,
], (user) => {
  return user.professions
})

export const getSelectedUserOrganisationPreferencesCount = state => (state.userDetails.preferencesCount || 0)
export const getSelectedUserOrganisationPreferences = state => (state.userDetails.organisationPreferences || [])

export const getSelectedUserId = state => _.get(state, 'userDetails.user.id')

export const getSelectedUserUserGroups = state => _.get(state, 'userDetails.user.userGroup')
export const getSelectedUserAccountType = state => _.get(state, 'userDetails.user.accountType')