import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Input, InputLabel, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const DatePicker = ({
  label,
  classes,
  onChange,
  onBlur,
  meta: { touched, error } = {},
  id,
  ...rest
}) => {
  return (
    <Fragment>
      <InputLabel htmlFor={id} shrink>{label}</InputLabel>
      <Input
        id={id}
        type='date'
        onChange={onChange}
        onBlur={onBlur}
        className={classes.textField}
        {..._.omit(rest, ['noErrorTextLabel'])}
      />
      {touched && error ? <FormHelperText error role='alert'>{error}</FormHelperText> : null}
    </Fragment>
  )
}

DatePicker.propTypes = {
  classes: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  meta: PropTypes.shape({})
}

export default withStyles(style)(DatePicker)
