import AsyncLookUpInput from './AsyncLookUpInput'
import * as implementations from './implementations'
import { withImplementation } from './withImplementation'

export default AsyncLookUpInput


export const MeddraAsyncLookUp = withImplementation(
    implementations.createMeddraImplementation
)(AsyncLookUpInput)

export const DictionaryEntriesAsyncLookUp = withImplementation(
    implementations.createDictionaryEntriesImplementation
)(AsyncLookUpInput)

export const LocationLookup = withImplementation(
    implementations.createLocationsImplementation
)(AsyncLookUpInput)