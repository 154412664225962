import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import fp from 'lodash/fp'
import { v4 as uuidv4 } from 'uuid'

import { styled, Button, IconButton, Grid, TextField, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/AddCircleOutline'

import Dropdown from '../../../../components/Fields/Dropdown'
import FieldSelector from '../../../../components/Conditions/FieldSelector'
import ConditionsBuilder from '../../../../components/Conditions/ConditionsBuilder'

import ValueField from './ValueField'
import FullWidthFormControl from './FullWidthFormControl'

import { actionEnum } from '../../constants'
import { translations } from '../../../../config'

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[100],
  borderRadius: '4px',
  background: 'rgba(189, 189, 189, 0.2)',
  width: '100%'
}))

const ActionContainer = styled(Grid)(({ theme }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgb(224, 224, 224)',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1)
}))

const ActionRow = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2)
}))

const OptionsRow = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const InfoWrapper = styled(Grid)(({ theme }) => ({
  background: '#EEE',
  border: '1px solid #DDD',
  padding: theme.spacing(1),
  margin: theme.spacing(2, 0)
}))

const FallbackRow = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0, 1, 0),
  marginRight: theme.spacing(1)
}))

const ActionsBuilder = ({
  query = [],
  fields = [],
  dictionaries = [],
  onQueryChange,
  actions = actionEnum,
  theme = {},
  defaultField,
  isNested = false
}) => {
  const orderedQuery = _.orderBy(query, ['position'])
  const [internalActions, setActions] = useState(orderedQuery)

  const repeatables = _.filter(fields, { field: 'Repeatable' })
  const groupedFields = _.groupBy(fields, 'parentId')

  const isInRepeatable = (field) => {
    const fieldMeta = _.find(fields, { id: field })
    const fieldParent = _.get(fieldMeta, 'parentId')
    const isFieldRepeatable = _.find(repeatables, { id: fieldParent })
    const isRepeatable = !_.isUndefined(isFieldRepeatable)
    return isRepeatable
  }

  /**
   * Used for 'Target Field' component. Don't allow selecting fields within repeatables.
   * 
   * @param {*} fields 
   * @returns {array} Fields excluding ones within repeatables
   */
  const targetFields = (fields) => _.filter(fields, (field) => !isInRepeatable(field.id) && field.field !== 'Repeatable')

  const assignedFields = useMemo(() => {
    return _.map(internalActions, (action) => {
      const repeatableName = _.get(action, 'details.repeatable')
      const fieldId = _.get(action, 'details.field')
      const field = _.find(fields, { id: fieldId })
      const parentId = _.get(field, 'parentId')
      if (repeatableName) {
        return groupedFields[repeatableName] || []
      }
      if (parentId) {
        return groupedFields[parentId] || []
      }
      return fields
    })
  }, [internalActions, groupedFields, fields])

  const getFieldParent = (field) => {
    const fieldMeta = _.find(fields, { id: field })
    const fieldParent = _.get(fieldMeta, 'parentId')
    const isFieldRepeatable = _.find(repeatables, { id: fieldParent })
    return isFieldRepeatable ? fieldParent : undefined
  }

  const shouldRenderConditions = useMemo(() => {
    const isInRepeatable = (field) => {
      const fieldMeta = _.find(fields, { id: field })
      const fieldParent = _.get(fieldMeta, 'parentId')
      const isFieldRepeatable = _.find(repeatables, { id: fieldParent })
      return !_.isUndefined(isFieldRepeatable)
    }

    let renderConditionsResult = []
    _.forEach(internalActions, (action, index) => {
      const actionActionValue = _.get(action, 'action')
      const actionFieldValue = _.get(action, 'details.field')
      switch (true) {
        case actionActionValue === actionEnum.REMOVE_REPEATABLE:
        case actionActionValue === actionEnum.SET_FIELD && isInRepeatable(actionFieldValue) && !isNested:
        case actionActionValue === actionEnum.CLEAR_FIELD && isInRepeatable(actionFieldValue):
          renderConditionsResult = fp.set(index, true)(renderConditionsResult)
          break
        default:
      }
    })
    return renderConditionsResult
  }, [internalActions, fields, repeatables, isNested])

  const handleChange = (actions) => {
    setActions(actions)
    onQueryChange(actions)
  }

  const handleAdd = () => {
    let nextActions = [...internalActions]
    nextActions.push({ action: 'SET_FIELD', id: uuidv4(), details: { field: defaultField } })
    handleChange(nextActions)
  }
  const handleDelete = (index) => () => {
    let nextActions = [...internalActions]
    _.pullAt(nextActions, index)
    handleChange(nextActions)
  }

  const changeFieldPath = ({ index, field }) => {
    let path

    switch (field) {
      case 'action':
      case 'fallbackActions':
        path = `${index}.${field}`
        break
      default:
        path = `${index}.details.${field}`
    }

    return path
  }

  const onChangeField = (index, field) => (event) => {
    const nextValue = _.get(event, 'target.value', event)
    const path = changeFieldPath({ index, field })

    let temporaryInternalActions = fp.cloneDeep(internalActions)
    // if we've changed the actions, we need to clear the details
    if (field === 'action') {
      const actionParams =  { id: uuidv4(), details: {} }
      if (defaultField) {
        actionParams.details.field = defaultField
      }

      temporaryInternalActions = fp.set(`${index}`, actionParams)(temporaryInternalActions)
    }
    if (field === 'targetField') {
      temporaryInternalActions = fp.set(field, nextValue, temporaryInternalActions)
    }
    // if the current action has conditions, remove them if we've changed the field, but only if the repeatable has changed
    if (
      (field === 'field' || field === 'repeatable') &&
      (
        getFieldParent(fp.get(`${index}.details.field`, temporaryInternalActions)) !== getFieldParent(nextValue) ||
        fp.get(`${index}.details.repeatable`, temporaryInternalActions) === nextValue
      )
    ) {
      temporaryInternalActions = fp.set(`${index}.details.conditions`, undefined)(temporaryInternalActions)
    }

    const nextActions = fp.set(path, nextValue)(temporaryInternalActions)
    handleChange(nextActions)
  }

  const actionOptions = _.mapValues(actions, action => {
    return {
      label: translations(`Integration Management - ${action}`),
      value: action
    }
  })

  const renderAdd = () => {
    const buttonProps = {
      variant: 'outlined',
      size: 'medium',
      startIcon: null,
      ..._.get(theme, 'addAction.buttonProps', {})
    }
    const buttonLabel = _.get(theme, 'addAction.label', 'Add Action')

    return (
      <Button {...buttonProps} onClick={handleAdd}>{translations(buttonLabel)}</Button>
    )
  }

  return (
    <Container>
      {renderAdd()}
      {
        _.map(internalActions, (action, index) => {
          const { action: actionValue, id: actionId, details = {}, fallbackActions = [] } = action
          const {
            field: fieldValue,
            value: valueValue,
            targetField,
            repeatable: repeatableValue,
            conditions: query
          } = details

          return (
            <ActionContainer container key={actionId}>
              <Grid
                item
                xs={4}
              >
                <FullWidthFormControl
                  size='small'
                  title={translations('Action')}
                >
                  <Dropdown
                    noNullOption
                    value={actionValue}
                    options={actionOptions}
                    onChange={onChangeField(index, 'action')}
                    label={translations('Action')}
                  />
                </FullWidthFormControl>
              </Grid>
              {
                (actionValue === actionEnum.USE_DICTIONARY) && (
                  <Grid item xs={4}>
                    <FullWidthFormControl >
                      <FieldSelector
                        disableGrouping
                        label={translations('Integration Management - Use Dictionary')}
                        placeholder={translations('Integration Management - Search Dictionaries')}
                        options={dictionaries}
                        value={valueValue}
                        handleOnChange={onChangeField(index, 'value')}
                      />
                    </FullWidthFormControl>
                  </Grid>
                )
              }
              {(_.includes([actionEnum.SET_FIELD, actionEnum.CLEAR_FIELD, actionEnum.USE_DICTIONARY, actionEnum.APPEND_TO_FIELD], actionValue)) && (
                <Grid item xs={actionValue === actionEnum.CLEAR_FIELD ? 8 : 4}>
                  <FullWidthFormControl>
                    <FieldSelector
                      options={fields}
                      value={fieldValue || defaultField}
                      disabled={!_.isUndefined(defaultField)}
                      handleOnChange={onChangeField(index, 'field')}
                    />
                  </FullWidthFormControl>
                </Grid>
              )}
              {(_.includes([actionEnum.APPEND_TO_FIELD], actionValue)) && (
                <Grid item xs={4}>
                  <FullWidthFormControl>
                    <FieldSelector
                      options={targetFields(fields)}
                      value={targetField}
                      handleOnChange={onChangeField(index, 'targetField')}
                      label={translations('Target Field')}
                    />
                  </FullWidthFormControl>
                </Grid>
              )}
              {(actionValue === actionEnum.REMOVE_REPEATABLE) && (
                <Grid item xs={8}>
                  <FullWidthFormControl>
                    <FieldSelector
                      options={repeatables}
                      value={repeatableValue}
                      handleOnChange={onChangeField(index, 'repeatable')}
                      label={translations('Repeatable')}
                    />
                  </FullWidthFormControl>
                </Grid>
              )}
              {_.includes([actionEnum.SET_FIELD, actionEnum.APPEND_TO_FIELD], actionValue) && (
                <Grid item xs={actionValue === actionEnum.APPEND_TO_FIELD ? 12 : 4}>
                  <ValueField
                    actionValue={actionValue}
                    value={valueValue}
                    onChange={onChangeField(index, 'value')}
                  />
                </Grid>
              )}
              {actionValue === actionEnum.USE_DICTIONARY && (
                <FallbackRow item xs={12}>
                  <ActionsBuilder
                    query={fallbackActions}
                    fields={fields}
                    dictionaries={dictionaries}
                    onQueryChange={onChangeField(index, 'fallbackActions')}
                    defaultField={fieldValue}
                    isNested
                    actions={{
                      SET_FIELD: actionEnum.SET_FIELD,
                      APPEND_TO_FIELD: actionEnum.APPEND_TO_FIELD
                    }}
                    theme={{
                      addAction: {
                        buttonProps: {
                          size: 'small',
                          startIcon: <AddIcon />,
                          variant: 'solid'
                        },
                        label: 'Add fallback action'
                      }
                    }}
                  />
                </FallbackRow>
              )}
              {(_.includes([actionEnum.AWAIT_REVIEW, actionEnum.SEND_EMAIL], actionValue)) && (
                <Grid item xs={8}></Grid>
              )}
              {shouldRenderConditions[index] && (
                <ActionRow container>
                  <InfoWrapper item xs={12}>
                    <InfoOutlinedIcon />
                    <Typography variant='body1' component='span'>
                      {translations('Integration Management - Conditions to match for repeatable instance to be effected')}
                    </Typography>
                  </InfoWrapper>
                  <Grid item xs={12}>
                    <ConditionsBuilder
                      fields={assignedFields[index]}
                      onQueryChange={onChangeField(index, 'conditions')}
                      query={query}
                      forceEquals
                    />
                  </Grid>
                </ActionRow>
              )}
              <OptionsRow
                container
                justify='flex-end'
              >
                <Grid item>
                  <IconButton variant='outlined' onClick={handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </OptionsRow>
            </ActionContainer>)
        })
      }
    </Container >
  )
}

export default ActionsBuilder
