import { colors } from '../../../config/theme'

export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20,
    maxWidth: 1000,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: 30
    }
  },
  metaSection: {
    padding: '20px 15px 15px',
    background: colors.lightGrey
  },
  meta: {
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0
    },
    '& h2': {
      marginBottom: 5
    }
  },
  variables: {
    margin: '0 0 15px 0',
    display: 'flex',
    flexWrap: 'wrap',
    '& dt, & dd': {
      ...theme.p.p,
      width: '100%',
      color: colors.text
    },
    '& dt': {
      fontWeight: 700
    },
    '& dd': {
      fontSize: 12,
      opacity: 0.9
    }
  },
  variableItem: {
    borderBottom: `1px solid ${colors.mediumGrey}`,
    paddingTop: 7,
    paddingBottom: 8,
    paddingRight: 50,
    position: 'relative',
    display: 'block',
    width: '100%'
  },
  copyButton: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    width: 40,
    height: 40,
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: '.3s background',
    outline: 'none',
    '&:hover, &:active, &:focus': {
      background: colors.mediumGrey
    },
    '& img': {
      width: 16,
      height: 16
    }
  },
  testButton: {
    margin: 0
  },
  testActions: {
    paddingTop: 10,
    paddingBottom: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  languageDropdown: {
    fontSize: 14,
    marginRight: 10
  }
})
