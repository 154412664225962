import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import _ from 'lodash'

const StyledLabel = styled.label`
  font-weight: 400;
  font-size: ${props => `${props.theme.type.label.small}px`};
  line-height: 1.25;
  color: ${props => props.nested ? props.theme.colors.secondaryText : props.theme.colors.primaryText};
  display: block;
  margin-bottom: 5px;
  margin-top: 0;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.label.large}px`};
    line-height: 1.4;
  `}
`;

const Optional = styled.span`
  font-size: 10px;
  display: inline-block;
  margin-left: 5px;
  top: -1px;
  position: relative;
  ${media.greaterThan('small')`
    font-size: 14px;    
  `}
`

/**
 * Basic form label with (Optional) appended if not required
 */
const Label = (props) => {
  const { name, children, required, as, nested, disabled } = props
  const optionalFlag = !required && !disabled ? ' (Optional)' : ''

  const htmlFor = (_.isUndefined(as)) ? name : undefined

  return (
    <StyledLabel htmlFor={htmlFor} as={as} nested={nested}>
      {children}
      <Optional>{optionalFlag}</Optional>
    </StyledLabel>
  )
}

Label.propTypes = {
  /** Used to associate label and input */
  name: PropTypes.string,
  /** User friendly name for the field */
  children: PropTypes.string.isRequired,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the label is nested within a more complex field and should have less prominence */
  nested: PropTypes.bool
}

Label.defaultProps = {
  required: false
}

export default Label
