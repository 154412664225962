import React from 'react'
import fp from 'lodash/fp'

import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import InputLabel from '../../InputLabel'
import { translations } from '../../../config'
import { colors } from '../../../config/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  option: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& p': {
      margin: 0
    }
  },
  noOptions: {
    fontSize: 14
  },
  floatingLabelFocusStyle: {
    color: colors.accent
  },
  underlineFocusStyle: {
    borderColor: colors.accent
  },
  errorStyle: {
    color: colors.error
  },
  errorText: {
    color: colors.error
  },
  borderFocussed: {
    borderColor: colors.lightGrey
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  }
}))

const AutocompleteComponent = (props) => {
  const {
    label,
    shrink,
    freeSolo,
    options,
    value,
    inputValue,
    groupBy,
    getOptionLabel,
    help,
    autoFocus,
    getRef,
    placeholder,
    disabled,
    noErrorTextLabel,
    onChange,
    handleChange,
    handleInputChange,
    onBlur,
    readOnly,
    disableUnderline,
    required,
    renderInput,
    getOptionSelected,
    renderOption,
    multiple,
    disableCloseOnSelect,
    renderTags,
    fullWidth,
    loading,
    loadingText,
    filterOptions,
    meta: { error, touched } = {},
    noOptionsText
  } = props
  const classes = useStyles()
  return (
    <>
      <InputLabel
        htmlFor={label}
        key='label'
        shrink={shrink}
      >
        {label}
      </InputLabel>
      <Autocomplete
        filterOptions={filterOptions}
        loading={loading}
        loadingText={loadingText}
        classes={classes}
        groupBy={groupBy}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
        disableUnderline={disableUnderline}
        freeSolo={freeSolo}
        readOnly={readOnly}
        onBlur={onBlur}
        // redux-form modifies the onChange handler and sets the second prop to 0
        onChange={handleChange ? handleChange : onChange}
        options={options}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        value={value}
        inputValue={!fp.isNil(inputValue) ? inputValue : value}
        multiple={multiple}
        fullWidth={fullWidth}
        disableCloseOnSelect={disableCloseOnSelect}
        renderTags={renderTags}
        renderInput={
          renderInput
            ? renderInput
            : (params) => <TextField
              required={required}
              inputRef={getRef}
              onChange={handleInputChange ? handleInputChange : onChange}
              {...params}
            />}
        noOptionsText={noOptionsText}
      />
      {help && <FormHelperText key='help-text'>{translations(help)}</FormHelperText>}
      {noErrorTextLabel ? null : <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>}
    </>
  )
}

export default AutocompleteComponent
