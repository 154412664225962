import { colors } from '../../config/theme'

export default theme => ({
  listContainer: {
    width: '100%',
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  itemContainer: {
    backgroundColor: 'white',
    '&:first-child > div': {
      borderTop: 0
    }
  },
  clickable: {
    cursor: `${props => props.isClickable ? 'pointer' : 'auto'}`,
    '&:hover': {
      backgroundColor: colors.tableHoverGrey
    }
  },
  itemInner: {
    margin: '0 23px',
    borderTop: `1px solid ${colors.border}`
  },
  narrow: {
    margin: '0 6px'
  },
  noMargin: {
    margin: 0
  },
  noBorder: {
    border: 'none'
  },
  boxInner: {
    border: `1px solid ${colors.contentBoxGrey}`,
    borderBottom: 0
  },
  gutter: {
    margin: '10px 0'
  }
})
