import React from 'react'
import _ from 'lodash'
import { pure } from 'recompose'

import ChartTile from '../../../components/Chart/ChartTile'
import ReportChart from '../ReportChart'
import { slugify } from '../../../helpers'
import schema from '../../../config/configFiles/organisationHealthCheckSchema.json'
import qs from 'qs'

const OrganisationHealthCheckCharts = ({
    organisationHealthCheck,
    ids,
    timePeriod
}) => {
    const allowedFullList = ['LIST', 'COUNT']
    const canHaveSeeAllLink = ({ reportSchema }) => {
        const { type, fullList } = reportSchema
        return (_.includes(allowedFullList, type)) && fullList
    }

    return (
        schema.map((reportSchema, i) => {
            const link = `/monitoring-and-analytics/healthCheck/organisation/${i}-${slugify(reportSchema.title)}${qs.stringify({ timePeriod, organisationIds: ids }, { addQueryPrefix: true })}`
            const to = canHaveSeeAllLink({ reportSchema }) && !_.includes(allowedFullList, reportSchema.type) && link
            const seeAllLink = canHaveSeeAllLink({ reportSchema }) && link
            return (
                <ChartTile to={to || undefined} key={link}>
                    <ReportChart
                        items={[organisationHealthCheck]}
                        reportSchema={reportSchema}
                        seeAllLink={seeAllLink}
                    />
                </ChartTile>
            )
        })
    )
}

export default pure(OrganisationHealthCheckCharts)
