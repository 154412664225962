import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import moment from 'moment'
import { selectors } from './'
/**
 * @typedef {object} Filters
 * @property {string} organisationId
 * @property {object} timePeriod
 * @property {string?} timePeriod.startDate
 * @property {string?} timePeriod.endDate
 */
/**
 * Handle Analytics from the Vigilance Hub API.
 */
class PlatformAnalytics {
  /**
   * Get analytical data for an organisation with the filters from redux applied.
   */
  getOrganisationAnalyticsUsingFilters = () => (dispatch, getState) => {
    const state = getState()
    /**
     * @type {Filters}
     */
    const filters = selectors.getFilters(state)
    return dispatch({
      type: constants.GET_ORGANISATION_ANALYTICS,
      promise: () => digitalStoreSdk.analytics.getOrganisationAnalytics(filters)
    })
  }
  /**
   * @param {Filters['organisationId']} organisationId
   */
  setOrganisationIdFilter = (organisationId) => {
    return this.changeFilters({ organisationId })
  }
  /**
   * @param {Filters['reportType']} organisationId
   */
  setReportTypeFilter = (reportType) => {
    return this.changeFilters({ reportType })
  }
  /**
   * @param {Filters['organisationIds']} organisationId
   */
  setOrganisationIdsFilter = (organisationIds) => {
    return this.changeFilters({ organisationIds })
  }
  /*
   * @param {Filters['sourceId']} organisationId
   */
  setSourceIdFilter = (sourceId = 'AllSources') => {
    const filter = sourceId === 'AllSources' ? null : sourceId
    return this.changeFilters({ sourceId: filter })
  }
  /**
   * Converts a retailOS retailAnalytics period string in to a start date for the Vigilance Hub API.
   * @param {'today' | 'thisWeek' | 'thisMonth' | 'pastYear'} period
   */
  setTimePeriodFilter = (period, dateFrom, dateTo) => {
    let startDate = null
    let endDate = null
    const time = moment().utc()
    switch (period) {
      case 'today':
        startDate = time.startOf('day').format()
        endDate = time.endOf('day').format()
        break
      case 'thisWeek':
        startDate = time.startOf('week').format()
        endDate = time.endOf('week').format()
        break
      case 'thisMonth':
        startDate = time.startOf('month').format()
        endDate = time.endOf('month').format()
        break
      case 'pastYear':
        startDate = moment().utc().subtract(1, 'years').add(1, 'days').startOf('day').format()
        endDate = moment().utc().subtract(1, 'days').endOf('day').format()
        break
      case 'custom':
        if (dateFrom && dateTo) {
          startDate = moment(dateFrom, 'MM/DD/YYYY').startOf('day').format()
          endDate = moment(dateTo, 'MM/DD/YYYY').endOf('day').format()
        } else {
          startDate = time.startOf('day').format()
          endDate = time.endOf('day').format()
        }
        break
      default:
        startDate = time.startOf('day').format()
        endDate = time.endOf('day').format()
    }
    return this.changeFilters({ timePeriod: { startDate, endDate } })
  }
  /**
   * @private
   * @param {Filters} filters
   * Modify the filters within the Store.
   */
  changeFilters = (filters) => {
    return {
      type: constants.CHANGE_REPORTS_FILTERS,
      filters
    }
  }
  /**
   * Make a request to the API for a CSV export
   * @param {Filters} filters
   */
  exportAnalytics = (filters) => (dispatch, _getState) => {
    return dispatch({
      type: constants.EXPORT_ANALYTICS,
      promise: () => digitalStoreSdk.analytics
        .getOrganisationAnalyticsExport(filters)
        .then(({ url, id }) => {
          if (url) {
            window.location = url
          }
          if (id) {
            return id
          }
          return Promise.resolve()
        }),
      organisationId: filters.organisationId
    })
  }
}

export default new PlatformAnalytics()
