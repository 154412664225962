"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var formHelpers = _interopRequireWildcard(require("./formHelpers"));

var _computedHidden = require("../../../formHelpers/computedHidden");

var _computedValue = require("../../../formHelpers/computedValue");

var _yesNo = _interopRequireDefault(require("../config/yesNo"));

var _sex = _interopRequireDefault(require("../config/sex"));

var _reportStatus = _interopRequireDefault(require("../config/reportStatus"));

var _originalReporter = _interopRequireDefault(require("../config/originalReporter"));

var _incidentType = _interopRequireDefault(require("../config/incidentType"));

var _validators = require("../../../formHelpers/validators");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'reportStatus',
    field: 'Dropdown',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'reportStatus',
      label: 'Report Status',
      options: _reportStatus["default"]
    }
  }, {
    id: 'reportExcluded',
    field: 'Dropdown',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'reportExcluded',
      label: 'Report Excluded',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'regulatorStatus',
    field: 'Input',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'regulatorStatus',
      label: 'Regulator Status',
      required: true,
      validators: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'reporterStatus',
    field: 'Input',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'reporterStatus',
      label: 'Reporter Status',
      required: true,
      validators: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'orgCode',
    field: 'Input',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'orgCode',
      label: 'Reporting Organisation Code',
      required: false,
      disabled: true
    }
  }, {
    id: 'orgName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'orgName',
      label: 'Reporting Organisation',
      required: true,
      disabled: true
    }
  }, {
    id: 'orgAddress',
    field: 'Input',
    props: {
      shrink: true,
      name: 'orgAddress',
      label: 'Reporting Organisation Address',
      required: true,
      disabled: true
    }
  }, {
    id: 'submittedNtf',
    field: 'Date',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'submittedNtf',
      label: "Date Notification was submitted"
    }
  }, {
    id: 'reporterIdNtf',
    field: 'Input',
    computedValue: formHelpers.isDraftUpdateReporter(_computedValue.getCurrentUserId),
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'reporterIdNtf',
      label: 'Reporter\'s ID',
      required: false,
      disabled: true
    }
  }, {
    id: 'reporterNameNtf',
    field: 'Input',
    computedValue: formHelpers.isDraftUpdateReporter(formHelpers.getCurrentUserTitleAndFullName),
    props: {
      shrink: true,
      name: 'reporterNameNtf',
      label: 'Reporter\'s Name',
      required: false,
      disabled: true
    }
  }, {
    id: 'reporterEmailNtf',
    field: 'Input',
    computedValue: formHelpers.isDraftUpdateReporter(_computedValue.getCurrentUserEmail),
    props: {
      shrink: true,
      name: 'reporterEmailNtf',
      label: 'Reporter\'s Email',
      required: false,
      disabled: true,
      validate: [validators.isValidEmail]
    }
  }, {
    id: 'reporterPhoneNtf',
    field: 'Input',
    computedValue: formHelpers.isDraftUpdateReporter(_computedValue.getCurrentUserTelephone),
    props: {
      shrink: true,
      name: 'reporterPhoneNtf',
      label: 'Telephone Number',
      required: false,
      disabled: true
    }
  }, {
    id: 'reporterPositionNtf',
    field: 'Input',
    computedValue: formHelpers.isDraftUpdateReporter(formHelpers.getCurrentUserOccupation),
    props: {
      shrink: true,
      name: 'reporterPositionNtf',
      label: 'Position/Occupation',
      required: false,
      disabled: true
    }
  }, {
    id: 'hvrIdHeading',
    field: 'Heading',
    computedHidden: formHelpers.isNotDraft,
    props: {
      shrink: true,
      name: 'hvrIdHeading',
      label: 'MHRA Ref No',
      required: false,
      disabled: true,
      isHeading: true,
      value: 'MHRA Ref No: To be assigned'
    }
  }, {
    id: 'hvrId',
    field: 'Input',
    computedHidden: formHelpers.isDraft,
    props: {
      shrink: true,
      name: 'hvrId',
      label: 'MHRA Ref No',
      required: false,
      disabled: true
    }
  }, {
    id: 'localReferenceNumber',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'localReferenceNumber',
      label: 'Local Reference Number(s)',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'hospitalConsultant',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'hospitalConsultant',
      label: 'Hospital Consultant',
      required: false,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'incidentLocation',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAE_INCIDENT_LOCATION'
      },
      shrink: true,
      name: 'incidentLocation',
      label: 'Incident Location',
      required: true
    }
  }, {
    id: 'linkedEstablishment',
    field: 'BasicLocationLookUpInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'linkedEstablishment',
      label: 'Hospital / Establishment where incident occurred',
      required: false
    }
  }, {
    id: 'incidentType',
    field: 'Input',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'incidentType',
      label: 'Incident Type',
      required: false
    }
  }, {
    id: 'reportedLocally',
    field: 'Dropdown',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'reportedLocally',
      label: 'Reported Locally',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'beReported',
    field: 'Dropdown',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'beReported',
      label: 'Has this been reported to a Blood Establishment?',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'beName',
    computedHidden: formHelpers.showIfReportedToBloodEstablishment,
    computedValue: formHelpers.clearIfNotReportedToBloodEstablishment,
    computedDisabled: formHelpers.isNotNotification,
    field: 'Input',
    props: {
      shrink: true,
      name: 'beName',
      label: 'If so, which Blood Establishment',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'beConsultant',
    computedHidden: formHelpers.showIfReportedToBloodEstablishment,
    computedValue: formHelpers.clearIfNotReportedToBloodEstablishment,
    computedDisabled: formHelpers.isNotNotification,
    field: 'Input',
    props: {
      shrink: true,
      name: 'beConsultant',
      label: 'Details of the consultant within the blood establishment if applicable',
      required: false,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'eventDateNtf',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'eventDateNtf',
      label: 'Date of event',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'deviation',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAE_DEVIATION'
      },
      shrink: true,
      name: 'deviation',
      label: 'Event Involving',
      required: true
    }
  }, {
    id: 'deviationOther',
    computedHidden: formHelpers.showIfDeviationOther,
    computedValue: formHelpers.clearIfNotDeviationOther,
    computedDisabled: formHelpers.isNotNotification,
    field: 'Input',
    props: {
      shrink: true,
      name: 'deviationOther',
      label: 'Event Involving (if Other)',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'specification',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAE_SPECIFICATION'
      },
      shrink: true,
      name: 'specification',
      label: 'Specification',
      required: true
    }
  }, {
    id: 'specificationOther',
    field: 'Input',
    computedHidden: formHelpers.showIfSpecificationOther,
    computedValue: formHelpers.clearIfNotSpecificationOther,
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'specificationOther',
      label: 'Specification (if Other)',
      required: true,
      validate: [validators.maxLength(500, 'Field')]
    }
  }, {
    id: 'component',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAE_COMPONENT'
      },
      shrink: true,
      name: 'component',
      label: 'Implicated Component',
      required: true,
      multiple: true
    }
  }, {
    id: 'componentTransfused',
    field: 'Dropdown',
    computedValue: formHelpers.noIfNoImplicatedComponent,
    computedDisabled: formHelpers.disableComponentTransfused,
    props: {
      shrink: true,
      name: 'componentTransfused',
      label: 'Blood component in question transfused',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'furtherDetails',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'furtherDetails',
      label: 'Further Details',
      required: true,
      rows: 5,
      multiline: true
    }
  }, {
    id: 'furtherDetailsAttachments',
    field: 'Repeatable',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      label: 'Attach file',
      name: 'furtherDetailsAttachments',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: false,
      disableEdit: true,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf'],
          required: true,
          shrink: true
        }
      }]
    }
  }, {
    id: 'dob',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'dob',
      label: 'Date of birth',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])]
    }
  }, {
    id: 'age',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'age',
      label: 'Age',
      validate: [validators.numeric, validators.isBelow(120), validators.isAbove(0)]
    }
  }, {
    id: 'sex',
    field: 'Dropdown',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'sex',
      label: 'Sex',
      required: false,
      options: _sex["default"]
    }
  }, {
    id: 'notificationAttachments',
    computedDisabled: formHelpers.isNotNotification,
    field: 'Repeatable',
    props: {
      label: 'Attach file',
      name: 'notificationAttachments',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: false,
      disableEdit: true,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'notification',
          required: false,
          shrink: true
        }
      }]
    }
  }, {
    id: 'enableConfirmation',
    computedHidden: formHelpers.isDraft,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'enableConfirmation',
      label: 'Would you like to submit a confirmation at this time?',
      helperText: 'You can submit a footnote without a confirmation by using the footnote section below.',
      defaultValue: 'No',
      options: _yesNo["default"]
    }
  }, {
    id: 'submittedCnf',
    computedHidden: _computedHidden.hide,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Date',
    props: {
      shrink: true,
      name: 'submittedCnf',
      label: 'Date Confirmation was submitted'
    }
  }, {
    id: 'eventConfirmedDate',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Date',
    props: {
      shrink: true,
      name: 'eventConfirmedDate',
      label: 'Date of confirmation',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY/MM/DD',
      required: true
    }
  }, {
    id: 'originalReporter',
    field: 'Dropdown',
    computedValue: formHelpers.computeOriginalReporterValue,
    computedDisabled: _computedHidden.disabled,
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    props: {
      shrink: true,
      name: 'originalReporter',
      label: 'Confirmation report submitted by',
      options: _originalReporter["default"]
    }
  }, {
    id: 'reporterIdCnf',
    computedHidden: _computedHidden.hide,
    computedValue: formHelpers.isConfirmationSubmittedUpdateReporter(_computedValue.getCurrentUserId),
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterIdCnf',
      label: 'Confirmation Reporter\'s ID',
      disabled: true
    }
  }, {
    id: 'reporterNameCnf',
    computedHidden: formHelpers.showIfReporterOther,
    computedValue: formHelpers.isConfirmationSubmittedUpdateReporter(formHelpers.getCurrentUserTitleAndFullName),
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterNameCnf',
      label: 'Confirmation Reporter\'s Name',
      disabled: true
    }
  }, {
    id: 'reporterEmailCnf',
    computedHidden: formHelpers.showIfReporterOther,
    computedValue: formHelpers.isConfirmationSubmittedUpdateReporter(_computedValue.getCurrentUserEmail),
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterEmailCnf',
      label: 'Confirmation Reporter\'s Email Address',
      disabled: true
    }
  }, {
    id: 'reporterPhoneCnf',
    computedHidden: formHelpers.showIfReporterOther,
    computedValue: formHelpers.isConfirmationSubmittedUpdateReporter(_computedValue.getCurrentUserTelephone),
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterPhoneCnf',
      label: 'Confirmation Reporter\'s Telephone Number',
      disabled: true
    }
  }, {
    id: 'reporterPositionCnf',
    computedHidden: formHelpers.showIfReporterOther,
    computedValue: formHelpers.isConfirmationSubmittedUpdateReporter(formHelpers.getCurrentUserOccupation),
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterPositionCnf',
      label: 'Confirmation Reporter\'s Position/Occupation',
      disabled: true
    }
  }, {
    id: 'eventDateCnf',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Date',
    props: {
      shrink: true,
      name: 'eventDateCnf',
      label: 'Confirm Date of Serious Adverse Event',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'eventConfirmed',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'eventConfirmed',
      label: 'Confirmation of Serious Adverse Event',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'rootCauseAnalysis',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'rootCauseAnalysis',
      label: "Root cause analysis (outcome of investigation)",
      rows: 5,
      multiline: true,
      required: true
    }
  }, {
    id: 'correctiveMeasures',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Input',
    props: {
      shrink: true,
      name: 'correctiveMeasures',
      label: 'Corrective measures taken (details)',
      required: true,
      rows: 5,
      multiline: true
    }
  }, {
    id: 'confirmationAttachments',
    computedHidden: formHelpers.showIfReporterReadyToSubmitConfirmation,
    computedDisabled: formHelpers.isConfirmationSubmitted,
    field: 'Repeatable',
    props: {
      label: 'Attach file',
      name: 'confirmationAttachments',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: false,
      disableEdit: true,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'confirmation',
          required: false,
          shrink: true
        }
      }]
    }
  }, {
    id: 'footnotes',
    field: 'Repeatable',
    computedHidden: formHelpers.isDomainStatusSabreDraft,
    props: {
      label: 'Footnotes',
      name: 'footnotes',
      repeatableLabel: ['description', 'attachmentfile'],
      shrink: true,
      disableEdit: true,
      disableDelete: true,
      actionLabels: {
        add: 'Add Footnote',
        update: 'Update Footnote'
      },
      validate: [validators.handle(formHelpers.checkIfAdditionalFootnoteIsRequired)],
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        computedRequired: formHelpers.hasFileBeenAttached,
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: false,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'footnote',
          required: false,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        computedRequired: formHelpers.hasFileNameBeenSet,
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf'],
          required: false,
          shrink: true
        }
      }, {
        id: 'comments',
        field: 'Input',
        props: {
          label: 'Comments',
          name: 'comments',
          required: true,
          shrink: true,
          multiline: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }, {
        id: 'description',
        field: 'Input',
        props: {
          label: 'Description',
          name: 'description',
          required: true,
          shrink: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }, {
        id: 'userFirstName',
        computedInitialValue: _computedValue.getCurrentUserFirstName,
        field: 'Input',
        props: {
          shrink: true,
          name: 'userFirstName',
          label: "Author's first name",
          required: false,
          disabled: true
        }
      }, {
        id: 'userLastName',
        computedInitialValue: _computedValue.getCurrentUserLastName,
        field: 'Input',
        props: {
          shrink: true,
          name: 'userLastName',
          label: "Author's last name",
          required: false,
          disabled: true
        }
      }, {
        id: 'userEmailAddress',
        computedInitialValue: _computedValue.getCurrentUserEmail,
        field: 'Input',
        props: {
          shrink: true,
          name: 'userEmailAddress',
          label: "Author's email address",
          required: false,
          disabled: true
        }
      }, {
        id: 'createdAt',
        field: 'Date',
        computedInitialValue: _computedValue.getTimestamp,
        props: {
          shrink: true,
          name: 'createdAt',
          label: 'Date',
          required: true,
          disabled: true
        }
      }]
    }
  }];
};

exports.getFormSchema = getFormSchema;