import fp from 'lodash/fp'

export const getValueFromOrganisationOption = (option) => {
   return option
}


export const getOptionLabel = fp.getOr('', 'option')

export const getOptionSelected = (option, value) => {
    return option.value === value
}

export const handleAsyncStatusMessagesToUser = ({ isLoading, isEmpty, isInitial, minimumSearchLength }) => {
    switch (true) {
        // If you want to show loading.
        case isLoading:
            return {
                loading: true,
                loadingText: undefined
            }
        case isInitial:
            return {
                loading: true,
                loadingText: `For suggestions, please provide at least ${minimumSearchLength} characters`
            }
        case isEmpty:
            return {
                loading: true,
                loadingText: 'No suggestions found'
            }
        default:
            return {
                loading: false,
                loadingText: undefined
            }
    }
}

export const handleInputChange = ({ onInputValueChange }) => ({ target: { value = '' } }) => {
    onInputValueChange(value)
}


export const handleOptionChange = ({ onChange, onInputValueChange }) => (_event, value) => {
    if (!value) {
        onChange(null)
        return
    }
    onChange(value)
    onInputValueChange(value.label)
}

export const handleOnBlur = ({ onInputValueChange, selectedOption }) => () => {
    onInputValueChange(selectedOption.label)
}
