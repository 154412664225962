"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = exports.dateFormats = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _config = require("../../../config");

var mirHelpers = _interopRequireWildcard(require("./formHelpers"));

var _options = require("../../../formHelpers/options");

var _devices = require("../../../formHelpers/devices");

var _computedHidden = require("../../../formHelpers/computedHidden");

var _computedValue = require("../../../formHelpers/computedValue");

var _validators = require("../../../formHelpers/validators");

var _general = require("../../../formHelpers/devices/general");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var dateFormats = {
  yyyymmdd: '102',
  yyyymm: '610',
  yyyy: '602'
};
exports.dateFormats = dateFormats;

var getMHRAFormatForDate = function getMHRAFormatForDate(value) {
  return (0, mirHelpers.getFormatForDate)(value, dateFormats);
};

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'terms',
    field: 'TermsCheckbox',
    computedHidden: function computedHidden(values) {
      return values.isAuthorised;
    },
    props: {
      shrink: true,
      name: 'terms',
      label: 'Terms and conditions',
      required: true
    }
  }, {
    id: 'canSaveDraft',
    field: 'Input',
    computedValue: _devices.general.canSaveDraft,
    props: {
      type: 'hidden',
      name: 'canSaveDraft',
      label: 'Can save draft',
      required: false
    }
  }, {
    id: 'audienceid',
    field: 'Input',
    computedValue: function computedValue(_formValues, _parentFormValues, _organisationDetails, formViewDetails) {
      return _lodash["default"].get(formViewDetails, 'audienceId');
    },
    props: {
      type: 'hidden',
      name: 'audienceid'
    }
  }, {
    id: 'professionofreporter',
    field: 'Dropdown',
    props: {
      shrink: true,
      type: 'hidden',
      name: 'professionofreporter',
      label: 'Which best describes you?',
      optionsTranslationKeyPrefix: 'Profession - '
    }
  }, {
    id: 'isLateralFlowReport',
    field: 'Input',
    computedValue: function computedValue(_formValues, _parentFormValues, _organisationDetails, formViewDetails) {
      return _lodash["default"].get(formViewDetails, 'isLateralFlowReport');
    },
    props: {
      type: 'hidden',
      name: 'isLateralFlowReport'
    }
  }, {
    id: 'isSyringeReport',
    field: 'Input',
    computedValue: function computedValue(_formValues, _parentFormValues, _organisationDetails, formViewDetails) {
      return _lodash["default"].get(formViewDetails, 'isSyringeReport');
    },
    props: {
      type: 'hidden',
      name: 'isSyringeReport'
    }
  }, {
    id: 'homeTestingKitLogic',
    field: 'Input',
    computedValue: _fp["default"].T,
    props: {
      shrink: true,
      name: 'homeTestingKitLogic',
      label: 'Conditional logic based on if the report selected was Home testing and sample collection',
      required: false,
      type: 'hidden'
    }
  }, {
    id: 'reportType',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'reportType',
      label: 'Type of Report',
      options: [{
        value: 'Initial',
        label: 'Initial'
      }, {
        value: 'Follow up',
        label: 'Follow up'
      }, {
        value: 'Combined initial and final',
        label: 'Combined initial and final'
      }, {
        value: 'Final (Reportable incident)',
        label: 'Final (Reportable incident)'
      }, {
        value: 'Final (Non-reportable incident)',
        label: 'Final (Non-reportable incident)'
      }]
    }
  }, {
    id: 'ncaName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'ncaName',
      label: "Name of receiving national competent authority (NCA)",
      defaultValue: 'MHRA',
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ncaEudamedNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncaEudamedNum',
      label: "EUDAMED Number of NCA",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncaReportNo',
    field: 'Input',
    computedHidden: mirHelpers.showForIndustryOnly,
    computedDisabled: mirHelpers.isReportTypeNotAFollowUpOrFinal,
    props: {
      name: 'ncaReportNo',
      label: "Reference number assigned by NCA",
      required: false
    }
  }, {
    id: 'refNumEudamed',
    field: 'Input',
    props: {
      shrink: true,
      name: 'refNumEudamed',
      label: "Reference number assigned by EUDAMED for this incident",
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reportDate',
    field: 'Date',
    computedValue: _devices.audience.match({
      INDUSTRY: function INDUSTRY() {
        return (0, _computedValue.getTodayDate)();
      }
    }),
    computedHidden: _devices.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      disabled: true,
      name: 'reportDate',
      label: 'Date of submission',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'adversedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.adverseEventDate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'adversedateformat',
      label: 'Adverse Date Format'
    }
  }, {
    id: 'adverseEventDate',
    field: 'Date',
    computedHidden: _devices.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_devices.general.isNotLateralFlowReport),
      MOP: (0, _computedHidden.visibleIf)(_devices.general.isNotLateralFlowReport),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'adverseEventDate',
      label: 'Date of incident',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversefromdateformat', dateFormats)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'adversefromdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.adverseEventDateFrom);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'adversefromdateformat',
      label: 'Adverse From Date Format'
    }
  }, {
    id: 'adverseEventDateFrom',
    field: 'Date',
    computedRequired: _devices.audience.match({
      HCP: _devices.general.isLateralFlowReport,
      MOP: _devices.general.isLateralFlowReport,
      DEFAULT: _fp["default"].T
    }),
    computedValue: _devices.general.computedAdverseEventDate,
    props: {
      shrink: true,
      name: 'adverseEventDateFrom',
      label: 'Date of incident from',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversefromdateformat', dateFormats), validators.isDateBeforeOrEqual('adverseEventDateTo', 'Date of incident to')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'adversetodateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.adverseEventDateTo);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'adversetodateformat',
      label: 'Adverse To Date Format'
    }
  }, {
    id: 'adverseEventDateTo',
    field: 'Date',
    computedRequired: _devices.audience.match({
      HCP: _devices.general.isLateralFlowReport,
      MOP: _devices.general.isLateralFlowReport,
      DEFAULT: _fp["default"].T
    }),
    computedValue: _devices.general.computedAdverseEventDate,
    props: {
      shrink: true,
      name: 'adverseEventDateTo',
      label: 'Date of incident to',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversetodateformat', dateFormats), validators.isDateAfterOrEqual('adverseEventDateFrom', 'Date of incident from')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'mfrAwarenessDate',
    field: 'Date',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      disabled: false,
      shrink: true,
      name: 'mfrAwarenessDate',
      label: 'Manufacturer awareness date',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat), validators.isDateBeforeOrEqual('reportNextDate', 'expected date of next report')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'reportNextDate',
    field: 'Date',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Initial' || formValues.reportType === 'Follow up',
        2: false,
        3: false
      });
    },
    props: {
      disabled: false,
      shrink: true,
      name: 'reportNextDate',
      label: 'In case of initial and follow-up reports, please indicate the expected date of next report',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD',
      endYearOffset: 5
    }
  }, {
    id: 'eventClassification',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType && formValues.reportType !== 'Final (Non-reportable incident)',
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'eventClassification',
      label: 'Classification of incident',
      options: [{
        value: 'Serious public health threat',
        label: 'Serious public health threat'
      }, {
        value: 'Death',
        label: 'Death'
      }, {
        value: 'Unanticipated serious deterioration in state of health',
        label: 'Unanticipated serious deterioration in state of health'
      }, {
        value: 'All other reportable incidents',
        label: 'All other reportable incidents'
      }]
    }
  }, {
    id: 'statusReporter',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    otherFieldsDataMap: {
      reporterContactPersonFirstName: {
        formValuePaths: ['mfrContactPersonFirstName', 'ARContactPersonFirstName'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterContactPersonSecondName: {
        formValuePaths: ['mfrContactPersonSecondName', 'ARContactPersonSecondName'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgName: {
        formValuePaths: ['mfrName', 'ARName'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgEmailAddress: {
        formValuePaths: ['mfrEmailAddress', 'AREmailAddress'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgPhone: {
        formValuePaths: ['mfrPhone', 'ARPhone'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgCountry: {
        formValuePaths: ['mfrCountry', 'ARCountry'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgStreet: {
        formValuePaths: ['mfrOrgStreet', 'ARStreet'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgStreetNum: {
        formValuePaths: ['mfrOrgStreetNum', 'ARStreetNum'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgAddress: {
        formValuePaths: ['mfrAddress', 'ARAddress'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgPOBox: {
        formValuePaths: ['mfrPOBox', 'ARPOBox'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgCity: {
        formValuePaths: ['mfrCity', 'ARCity'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      },
      reporterOrgPostcode: {
        formValuePaths: ['mfrPostcode', 'ARPostCode'],
        condition: mirHelpers.statusReporterConditionalFieldUpdate
      }
    },
    props: {
      shrink: true,
      name: 'statusReporter',
      label: 'Submitter of report',
      options: [{
        value: 'Manufacturer',
        label: 'Manufacturer'
      }, {
        value: 'Authorised representative',
        label: 'Authorised representative'
      }, {
        value: 'Others',
        label: 'Other, please specify'
      }]
    }
  }, {
    id: 'reporterOtherText',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfStatusReporterIsOthers,
    computedRequired: mirHelpers.isStatusReporterOthers,
    computedValue: mirHelpers.clearIfStatusReporterIsNotOthers,
    props: {
      shrink: true,
      name: 'reporterOtherText',
      label: "Other reporter",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'mfrRef',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrRef',
      label: "Manufacturer's reference number for this incident",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncaRefMultiDev',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'ncaRefMultiDev',
      label: 'NCA Reference numbers for Multiple Devices',
      repeatableLabel: ['ncaRefMultiDevLI'],
      repeatableLabelPlaceholder: 'NCA Ref',
      schema: [{
        id: 'ncaRefMultiDevLI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'ncaRefMultiDevLI',
          label: "NCA's local reference number",
          required: false,
          validate: [validators.maxLength(50, 'Field')]
        }
      }]
    }
  }, {
    id: 'eudamedRefMultiDev',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'eudamedRefMultiDev',
      label: 'Eudamed Reference numbers for Multiple Devices',
      repeatableLabel: ['eudamedRefMultiDevLI'],
      repeatableLabelPlaceholder: 'Eudamed Ref',
      schema: [{
        id: 'eudamedRefMultiDevLI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'eudamedRefMultiDevLI',
          label: "Eudamed local reference number",
          required: false,
          validate: [validators.maxLength(50, 'Field')]
        }
      }]
    }
  }, {
    id: 'mfrRefMultiDev',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'mfrRefMultiDev',
      label: 'MFR Reference numbers for Multiple Devices',
      repeatableLabel: ['mfrRefMultiDevLI'],
      repeatableLabelPlaceholder: 'MFR Ref',
      schema: [{
        id: 'mfrRefMultiDevLI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'mfrRefMultiDevLI',
          label: "Manufacturer local reference number",
          required: false,
          validate: [validators.maxLength(50, 'Field')]
        }
      }]
    }
  }, {
    id: 'ncaRefFSCA',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'ncaRefFSCA',
      label: 'FSCA NCA reference numbers',
      repeatableLabel: ['ncaRefFSCALI'],
      repeatableLabelPlaceholder: 'FSCA NCA',
      schema: [{
        id: 'ncaRefFSCALI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'ncaRefFSCALI',
          label: "NCA's local FSCA reference number",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'eudamedRefFSCA',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'eudamedRefFSCA',
      label: 'FSCA Eudamed reference numbers',
      repeatableLabel: ['eudamedRefFSCALI'],
      repeatableLabelPlaceholder: 'FSCA Eudamed',
      schema: [{
        id: 'eudamedRefFSCALI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'eudamedRefFSCALI',
          label: "Eudameds local FSCA reference number",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'mfrRefFSCA',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'mfrRefFSCA',
      label: 'FSCA MFR reference numbers',
      repeatableLabel: ['mfrRefFSCALI'],
      repeatableLabelPlaceholder: 'FSCA MFR',
      schema: [{
        id: 'mfrRefFSCALI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'mfrRefFSCALI',
          label: "Manufacturer local FSCA reference number",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'psrId',
    field: 'Input',
    props: {
      shrink: true,
      name: 'psrId',
      label: "Periodic Summary Report (PSR) ID",
      required: false,
      disabled: true,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'pmcfpmpfId',
    field: 'Input',
    props: {
      shrink: true,
      name: 'pmcfpmpfId',
      label: "If the incident occurred within a PMCF/ PMPF investigation, please provide the Eudamed ID of that PMCF/PMPF investigation",
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'mfrName',
    field: 'Input',
    computedField: _devices.audience.match({
      HCP: (0, _computedValue.always)('OrganisationLookupInput'),
      MOP: (0, _computedValue.always)('OrganisationLookupInput'),
      DEFAULT: (0, _computedValue.always)('Input')
    }),
    otherFieldsDataMap: {
      mfrNameId: 'id'
    },
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrName',
      label: "Manufacturer Organisation name",
      validate: [validators.maxLength(100, 'Field')],
      freeSolo: true
    }
  }, {
    id: 'mfrNameId',
    field: 'Input',
    props: {
      type: 'hidden',
      name: 'mfrNameId',
      label: "ID for Manufacturer Organisation Name Look-up"
    }
  }, {
    id: 'mfrSRN',
    field: 'Input',
    props: {
      required: false,
      shrink: true,
      name: 'mfrSRN',
      label: "Single Registration Number (SRN)",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrContactPersonFirstName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrContactPersonFirstName',
      label: "Contact's first name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrContactPersonSecondName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrContactPersonSecondName',
      label: "Contact's last name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrEmailAddress',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrEmailAddress',
      label: "Email",
      validate: [validators.isValidEmail, validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'mfrPhone',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrPhone',
      label: "Phone",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrCountry',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      autocomplete: true,
      shrink: true,
      name: 'mfrCountry',
      label: 'Country',
      options: _config.countryCodesDropdownOptions
    }
  }, {
    id: 'mfrOrgStreet',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrOrgStreet',
      label: "Street",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrOrgStreetNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'mfrOrgStreetNum',
      label: "Street number",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrAddress',
    field: 'Input',
    props: {
      shrink: true,
      name: 'mfrAddress',
      label: "Address complement",
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'mfrOrgPOBox',
    field: 'Input',
    props: {
      shrink: true,
      name: 'mfrOrgPOBox',
      label: "PO Box",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrCity',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrCity',
      label: "City name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'mfrPostcode',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'mfrPostcode',
      label: "Postcode",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARName',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARName',
      label: "Authorised representative Organisation name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARSRN',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARSRN',
      label: "Single Registration Number (SRN)",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARContactPersonFirstName',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARContactPersonFirstName',
      label: "Contact's first name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARContactPersonSecondName',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARContactPersonSecondName',
      label: "Contact's last name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'AREmailAddress',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'AREmailAddress',
      label: "Email",
      validate: [validators.isValidEmail, validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'ARPhone',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARPhone',
      label: "Phone",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARCountry',
    field: 'Dropdown',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARCountry',
      label: 'Country',
      options: _config.countryCodesDropdownOptions
    }
  }, {
    id: 'ARStreet',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARStreet',
      label: 'Street',
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARStreetNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ARStreetNum',
      label: "Street number",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARAddress',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ARAddress',
      label: "Address complement",
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'ARPOBox',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ARPOBox',
      label: "PO Box",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARCity',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARCity',
      label: "City name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'ARPostCode',
    field: 'Input',
    computedRequired: mirHelpers.isStatusReporterAuthRep,
    props: {
      shrink: true,
      name: 'ARPostCode',
      label: "Postcode",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterOrgName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'reporterOrgName',
      label: "Registered commercial name of company",
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'reporterContactPersonFirstName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reportergivename_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterContactPersonFirstName',
      label: "Contact's first name",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterContactPersonSecondName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reporterfamilyname_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterContactPersonSecondName',
      label: "Contact's last name",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterOrgEmailAddress',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reporteremailaddress_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgEmailAddress',
      label: "Email",
      validate: [validators.isValidEmail, validators.maxLength(255, 'Field')]
    }
  }, {
    id: 'reporterOrgPhone',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reportertel_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgPhone',
      label: "Phone",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterOrgCountry',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'primarysourcecountry', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgCountry',
      label: "Country",
      options: _config.countryCodesDropdownOptions
    }
  }, {
    id: 'reporterOrgStreet',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reporterstreet_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgStreet',
      label: 'Street',
      validate: [validators.maxLength(100, 'Field'), validators.minimumOf1(_general.submitterAddressFieldsValidation.fields, _general.submitterAddressFieldsValidation.error)]
    }
  }, {
    id: 'reporterOrgStreetNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterOrgStreetNum',
      label: "Street number",
      validate: [validators.maxLength(100, 'Field'), validators.minimumOf1(_general.submitterAddressFieldsValidation.fields, _general.submitterAddressFieldsValidation.error)]
    }
  }, {
    id: 'reporterOrgAddress',
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterOrgAddress',
      label: "Address complement",
      required: false,
      validate: [validators.maxLength(100, 'Field'), validators.minimumOf1(_general.submitterAddressFieldsValidation.fields, _general.submitterAddressFieldsValidation.error)]
    }
  }, {
    id: 'reporterOrgPOBox',
    field: 'Input',
    props: {
      shrink: true,
      name: 'reporterOrgPOBox',
      label: "PO Box",
      required: false,
      validate: [validators.maxLength(50, 'Field'), validators.minimumOf1(_general.submitterAddressFieldsValidation.fields, _general.submitterAddressFieldsValidation.error)]
    }
  }, {
    id: 'reporterOrgCity',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reportercity_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgCity',
      label: "City name",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterOrgPostcode',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedValue: function computedValue(values) {
      return _lodash["default"].get(values, 'reporterpostcode_primarysource', null);
    },
    props: {
      shrink: true,
      name: 'reporterOrgPostcode',
      label: "Postcode",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'udiDI',
    field: 'Input',
    props: {
      shrink: true,
      name: 'udiDI',
      label: "UDI device identifier",
      validate: [validators.maxLength(50, 'Field')],
      nullFlavours: [{
        value: 'UNK',
        label: 'Unknown'
      }]
    }
  }, {
    id: 'udiPI',
    field: 'Input',
    props: {
      shrink: true,
      name: 'udiPI',
      label: "UDI production identifier",
      validate: [validators.maxLength(100, 'Field')],
      nullFlavours: [{
        value: 'UNK',
        label: 'Unknown'
      }]
    }
  }, {
    id: 'udiDIBasic',
    field: 'Input',
    props: {
      shrink: true,
      name: 'udiDIBasic',
      label: "Basic UDI-DI",
      validate: [validators.maxLength(100, 'Field')],
      nullFlavours: [{
        value: 'UNK',
        label: 'Unknown'
      }]
    }
  }, {
    id: 'udiDIUnitUse',
    field: 'Input',
    props: {
      shrink: true,
      name: 'udiDIUnitUse',
      label: "Unit of use UDI-DI",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'nomenclatureSystem',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'nomenclatureSystem',
      label: "Medical Device terminology used",
      required: false,
      options: [{
        value: 'EMDN',
        label: 'EMDN'
      }, {
        value: 'GMDN',
        label: 'GMDN'
      }, {
        value: 'UMDNS(ECRI)',
        label: 'UMDNS(ECRI)'
      }, {
        value: 'GIVD/EDMS',
        label: 'GIVD/EDMS'
      }, {
        value: 'Other',
        label: 'Other, please specify'
      }]
    }
  }, {
    id: 'nomenclatureSystemOther',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfNomenclatureSystemIsOther,
    computedRequired: mirHelpers.isNomenclatureSystemOther,
    computedValue: mirHelpers.clearIfNomenclatureSystemIsNotOther,
    props: {
      shrink: true,
      name: 'nomenclatureSystemOther',
      label: "Medical device other",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'nomenclatureCode',
    field: 'Input',
    props: {
      shrink: true,
      name: 'nomenclatureCode',
      label: "Medical device nomenclature code",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'brandName',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'brandName',
      label: "Medical device name (Brand/Trade/Proprietary or Common name)",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'deviceDescription',
    field: 'DeviceListLookupInput',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'deviceDescription',
      label: "Nomenclature text/Description of the device and its intended use",
      validate: [validators.maxLength(10000, 'Field')],
      autocomplete: true,
      multiple: true,
      options: _lodash["default"].map(_config.devicesList, function (device) {
        return {
          value: device.name
        };
      })
    }
  }, {
    id: 'modelNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'modelNum',
      label: "Model",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'catalogNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'catalogNum',
      label: "Catalogue/Reference number ",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'serialNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'serialNum',
      label: "Serial number(s)",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'batchNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'batchNum',
      label: "Lot/batch number(s)",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'deviceSoftwareVer',
    field: 'Input',
    props: {
      shrink: true,
      name: 'deviceSoftwareVer',
      label: "Software version ",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'deviceFirmwareVer',
    field: 'Input',
    props: {
      shrink: true,
      name: 'deviceFirmwareVer',
      label: "Firmware version",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'devicemfrdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.deviceMfrDate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'devicemfrdateformat',
      label: 'device Mfr Date Format'
    }
  }, {
    id: 'deviceMfrDate',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'deviceMfrDate',
      label: 'Device manufacturing date',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('devicemfrdateformat', dateFormats)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'deviceExpiryDate',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'deviceExpiryDate',
      label: 'Device expiry date',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.isDateAfterOrEqual('deviceMfrDate', 'Device manufacturing date')],
      helperText: 'YYYY/MM/DD',
      endYearOffset: 10
    }
  }, {
    id: 'implanteddatefromformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.ImplantedDateFrom);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'implanteddatefromformat',
      label: 'Implanted From Date Format'
    }
  }, {
    id: 'ImplantedDateFrom',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'ImplantedDateFrom',
      label: 'Date when device was implanted from',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('implanteddatefromformat', dateFormats), validators.isDateBeforeOrEqual('ImplantedDateTo', 'Date of implant to'), validators.isDateBeforeOrEqual('ExplantedDateFrom', 'Date of explant from'), validators.isDateBeforeOrEqual('ExplantedDateTo', 'Date of explant to'), validators.isDateAfterOrEqual('deviceMfrDate', 'Device manufacturing date')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'implanteddatetoformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.ImplantedDateTo);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'implanteddatetoformat',
      label: 'Implanted To Date Format'
    }
  }, {
    id: 'ImplantedDateTo',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'ImplantedDateTo',
      label: 'Date when device was implanted to',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('implanteddatetoformat', dateFormats), validators.isDateAfterOrEqual('ImplantedDateFrom', 'Date of implant from'), validators.isDateBeforeOrEqual('ExplantedDateFrom', 'Date of explant from'), validators.isDateBeforeOrEqual('ExplantedDateTo', 'Date of explant to'), validators.isDateAfterOrEqual('deviceMfrDate', 'Device manufacturing date')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'ImplantedYears',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ImplantedYears',
      label: "Implanted Years",
      validate: [validators.numeric]
    }
  }, {
    id: 'ImplantedMonths',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ImplantedMonths',
      label: "Implanted Months",
      validate: [validators.numeric]
    }
  }, {
    id: 'ImplantedDays',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ImplantedDays',
      label: "Implanted Days",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'BRSurgeonImplant',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'BRSurgeonImplant',
      label: "Implant Surgeon",
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'BRSurgeonRemove',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'BRSurgeonRemove',
      label: "Explant Surgeon",
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'Breast_IPI',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'Breast_IPI',
      label: "If applicable, what procedure were you undertaking?",
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'explanteddatefromformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.ExplantedDateFrom);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'explanteddatefromformat',
      label: 'Explanted From Date Format'
    }
  }, {
    id: 'ExplantedDateFrom',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'ExplantedDateFrom',
      label: 'Date when device was explanted from',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('explanteddatefromformat', dateFormats), validators.isDateBeforeOrEqual('ExplantedDateTo', 'Date of explant to'), validators.isDateAfterOrEqual('ImplantedDateFrom', 'Date of implant from'), validators.isDateAfterOrEqual('ImplantedDateTo', 'Date of implant to'), validators.isDateAfterOrEqual('deviceMfrDate', 'Device manufacturing date')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'explanteddatetoformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.ExplantedDateTo);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'explanteddatetoformat',
      label: 'Explanted To Date Format'
    }
  }, {
    id: 'ExplantedDateTo',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'ExplantedDateTo',
      label: 'Date when device was explanted to',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('explanteddatetoformat', dateFormats), validators.isDateAfterOrEqual('ExplantedDateFrom', 'Date of explant from'), validators.isDateAfterOrEqual('ImplantedDateFrom', 'Date of implant from'), validators.isDateAfterOrEqual('ImplantedDateTo', 'Date of implant to'), validators.isDateAfterOrEqual('deviceMfrDate', 'Device manufacturing date')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'NumYears',
    field: 'Input',
    props: {
      shrink: true,
      name: 'NumYears',
      label: "Number of years",
      validate: [validators.numeric]
    }
  }, {
    id: 'NumMonths',
    field: 'Input',
    props: {
      shrink: true,
      name: 'NumMonths',
      label: "Number of months",
      validate: [validators.numeric]
    }
  }, {
    id: 'NumDays',
    field: 'Input',
    props: {
      shrink: true,
      name: 'NumDays',
      label: "Number of days",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'implantFacilityName',
    field: 'HospitalsUKLookupInput',
    props: {
      shrink: true,
      name: 'implantFacilityName',
      label: "Implant facility",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'explantFacilityName',
    field: 'HospitalsUKLookupInput',
    props: {
      shrink: true,
      name: 'explantFacilityName',
      label: "Explant facility",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'nbIdNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'nbIdNum',
      label: "NB ID number",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'nbCertNum',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'nbCertNum',
      label: 'NB Certificate numbers',
      repeatableLabel: ['nbCertNumLI'],
      schema: [{
        id: 'nbCertNumLI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'nbCertNumLI',
          label: "NB Certificate numbers",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'nbIdNum2',
    field: 'Input',
    props: {
      shrink: true,
      name: 'nbIdNum2',
      label: "NB ID number",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'nbCertNum2',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'nbCertNum2',
      label: 'NB Certificate numbers',
      repeatableLabel: ['nbCertNum2LI'],
      schema: [{
        id: 'nbCertNum2LI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'nbCertNum2LI',
          label: "NB Certificate numbers",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'deviceMarketDateType',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'deviceMarketDateType',
      label: "Please indicate the date of one of the following",
      required: false,
      options: [{
        value: 'First declaration of conformity',
        label: 'First declaration of conformity'
      }, {
        value: 'The device first CE marked',
        label: 'The device first CE marked'
      }, {
        value: 'First placed on the market',
        label: 'First placed on the market'
      }, {
        value: 'First put into service',
        label: 'First put into service'
      }, {
        value: 'If software, date first made available',
        label: 'If software, date first made available'
      }]
    }
  }, {
    id: 'year',
    field: 'Input',
    props: {
      disabled: false,
      shrink: true,
      name: 'year',
      label: 'Year',
      required: false,
      validate: [validators.numeric, validators.isAbove(1899), validators.isBelow(2051)]
    }
  }, {
    id: 'month',
    field: 'Input',
    props: {
      disabled: false,
      shrink: true,
      name: 'month',
      label: 'Month',
      required: false,
      validate: [validators.numeric, validators.isAbove(0), validators.isBelow(13)]
    }
  }, {
    id: 'deviceClassChoice',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'deviceClassChoice',
      label: 'Device Class',
      options: [{
        value: 'This device has been placed on the market before the implementation of the MDD/AIMDD/IVDD',
        label: 'This device has been placed on the market before the implementation of the MDD/AIMDD/IVDD'
      }, {
        value: 'MDD',
        label: 'MDD/AIMDD'
      }, {
        value: 'IVDD',
        label: 'IVDD'
      }, {
        value: 'MDR',
        label: 'MDR'
      }, {
        value: 'IVDR',
        label: 'IVDR'
      }]
    }
  }, {
    id: 'deviceClassMDD',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsMDD,
    computedValue: mirHelpers.clearIfDeviceClassIsNotMDD,
    props: {
      shrink: true,
      name: 'deviceClassMDD',
      label: 'MDD/AIMDD',
      options: [{
        value: 'active implant',
        label: 'active implant'
      }, {
        value: 'class III',
        label: 'class III'
      }, {
        value: 'class IIb',
        label: 'class IIb'
      }, {
        value: 'class IIa',
        label: 'class IIa'
      }, {
        value: 'class I',
        label: 'class I'
      }, {
        value: 'class 1s',
        label: 'class 1s'
      }, {
        value: 'class 1m',
        label: 'class 1m'
      }, {
        value: 'class 1sm',
        label: 'class 1sm'
      }, {
        value: 'custom-made',
        label: 'custom-made'
      }],
      required: false
    }
  }, {
    id: 'deviceClassIVDD',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsIVDD,
    computedValue: mirHelpers.clearIfDeviceClassIsNotIVDD,
    props: {
      shrink: true,
      name: 'deviceClassIVDD',
      label: 'IVDD',
      options: [{
        value: 'IVD Annex II List A',
        label: 'IVD Annex II List A'
      }, {
        value: 'IVD Annex II List B',
        label: 'IVD Annex II List B'
      }, {
        value: 'IVD devices for self-testing',
        label: 'IVD devices for self-testing'
      }, {
        value: 'IVD general',
        label: 'IVD general'
      }],
      required: false
    }
  }, {
    id: 'deviceClassMDR',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsMDR,
    computedValue: mirHelpers.clearIfDeviceClassIsNotMDR,
    props: {
      shrink: true,
      name: 'deviceClassMDR',
      label: 'MDR',
      options: [{
        value: 'class III',
        label: 'class III'
      }, {
        value: 'class IIb',
        label: 'class IIb'
      }, {
        value: 'class IIa',
        label: 'class IIa'
      }, {
        value: 'class I',
        label: 'class I'
      }],
      required: false
    }
  }, {
    id: 'deviceClassMDRType',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsMDR,
    computedValue: mirHelpers.clearIfDeviceClassIsNotMDR,
    props: {
      shrink: true,
      name: 'deviceClassMDRType',
      label: 'Type (Multiple choice)',
      options: [{
        value: 'implantable',
        label: 'implantable'
      }, {
        value: 'activedevice',
        label: 'active device'
      }, {
        value: 'intendedtoadminister',
        label: 'intended to administer and/or remove a medicinal product'
      }, {
        value: 'sterileconditions',
        label: 'sterile conditions'
      }, {
        value: 'measuringfunction',
        label: 'measuring function'
      }, {
        value: 'reusable',
        label: 'reusable surgical instruments'
      }, {
        value: 'software',
        label: 'software'
      }, {
        value: 'systems',
        label: 'systems'
      }, {
        value: 'procedurepacks',
        label: 'procedure packs'
      }, {
        value: 'custommade',
        label: 'custom-made'
      }, {
        value: 'non-medical',
        label: 'non-medical purpose'
      }],
      multiple: true,
      required: false
    }
  }, {
    id: 'deviceClassIVDR',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsIVDR,
    computedValue: mirHelpers.clearIfDeviceClassIsNotIVDR,
    props: {
      shrink: true,
      name: 'deviceClassIVDR',
      label: 'IVDR',
      options: [{
        value: 'class D',
        label: 'class D'
      }, {
        value: 'class C',
        label: 'class C'
      }, {
        value: 'class B',
        label: 'class B'
      }, {
        value: 'class A',
        label: 'class A'
      }],
      required: false
    }
  }, {
    id: 'deviceClassIVDRType',
    field: 'Dropdown',
    computedHidden: mirHelpers.visibleIfDeviceClassIsIVDR,
    computedValue: mirHelpers.clearIfDeviceClassIsNotIVDR,
    props: {
      shrink: true,
      name: 'deviceClassIVDRType',
      label: 'Type (Multiple choice)',
      options: [{
        value: 'selftesting',
        label: 'self-testing'
      }, {
        value: 'nearpatienttesting',
        label: 'near-patient testing'
      }, {
        value: 'professionaltesting',
        label: 'professional testing'
      }, {
        value: 'companiondiagnostics',
        label: 'companion diagnostic'
      }, {
        value: 'reagent',
        label: 'reagent'
      }, {
        value: 'software',
        label: 'software'
      }, {
        value: 'instrument',
        label: 'instrument'
      }, {
        value: 'sterileconditions',
        label: 'sterile conditions'
      }],
      multiple: true,
      required: false
    }
  }, {
    id: 'distribution_all',
    field: 'Checkbox',
    computedHidden: mirHelpers.isDistributionAllHidden,
    computedValue: mirHelpers.clearIfDistributionAllIsNotVisible,
    props: {
      label: 'All EEA, Great Britain, Switzerland and Turkey',
      name: 'distribution_all',
      required: false
    }
  }, {
    id: 'distributionEEA',
    field: 'Dropdown',
    computedHidden: mirHelpers.isDistributionEEAHidden,
    computedValue: mirHelpers.clearIfDistributionEEAIsNotVisible,
    props: {
      label: 'Distribution (Multiple choice)',
      name: 'distributionEEA',
      required: false,
      shrink: true,
      options: _config.deviceDistributionCountriesDropdownOptions,
      multiple: true
    }
  }, {
    id: 'otherCountries',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'otherCountries',
      label: 'Other countries',
      repeatableLabel: ['otherCountriesLI'],
      repeatableLabelPlaceholder: 'Country',
      schema: [{
        id: 'otherCountriesLI',
        field: 'Input',
        props: {
          shrink: true,
          name: 'otherCountriesLI',
          label: "Other countries",
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      }]
    }
  }, {
    id: 'deviceAccessories',
    field: 'Input',
    props: {
      shrink: true,
      name: 'deviceAccessories',
      label: "Relevant accessories used with the device being reported on, if applicable",
      required: false,
      multiline: true,
      rows: 5,
      validate: [validators.maxLength(10000, 'Field')]
    }
  }, {
    id: 'deviceAssociated',
    field: 'Input',
    props: {
      shrink: true,
      name: 'deviceAssociated',
      label: "Relevant associated devices used with the device being reported on, if applicable",
      required: false,
      multiline: true,
      rows: 5,
      validate: [validators.maxLength(10000, 'Field')]
    }
  }, {
    id: 'CovidRelated',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'CovidRelated',
      label: "Is this related to a Covid-19 device?",
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'CEMark',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'CEMark',
      label: "What marking is on the device?",
      options: (0, _options.mapValueAndLabel)(['CE', 'UKCA', 'None', 'Don\'t Know', 'Exceptional Use Authorisation(EUA)', 'Suspected counterfeits or fake'])
    }
  }, {
    id: 'IVDDeviceDesc',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    computedOptions: _devices.general.computeDeviceDescOptions,
    props: {
      shrink: true,
      name: 'IVDDeviceDesc',
      label: 'IVD Device description'
    }
  }, {
    id: 'productName',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    computedOptions: _devices.general.computeProductNameOptions,
    props: {
      shrink: true,
      name: 'productName',
      label: 'IVD Product'
    }
  }, {
    id: 'multipleSimilarEvents',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'multipleSimilarEvents',
      label: 'Are you reporting multiple events of the same type?',
      required: false,
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'numberSimilarEvents',
    field: 'Input',
    computedRequired: _devices.general.hasMultipleSimilarEvents,
    computedHidden: _devices.general.hasNotGotMultipleSimilarEvents,
    computedValue: _devices.general.clearIfNotMultipleSimilarEvents,
    props: {
      shrink: true,
      name: 'numberSimilarEvents',
      label: 'Number of events in this report',
      required: false,
      validate: [validators.numeric, validators.maxNumericLength(5, 'Field'), validators.isAbove(1)]
    }
  }, {
    id: 'eventDescription',
    field: 'Input',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'eventDescription',
      label: "Provide a comprehensive description of the incident, including (1) what went wrong with the device (if applicable) and (2) a description of the health effects (if applicable), ie clinical signs, symptoms, conditions as well as the overall health impact",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'imdrfCodeChoice1',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedOptions: _devices.general.computeImdrfCodeChoice1Options,
    props: {
      shrink: true,
      name: 'imdrfCodeChoice1',
      label: 'Code Choice 1',
      autocomplete: true,
      freeSolo: false
    }
  }, {
    id: 'imdrfCodeChoice2',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'imdrfCodeChoice2',
      label: "Code Choice 2",
      required: false,
      autocomplete: true,
      freeSolo: false,
      options: _config.annexA
    }
  }, {
    id: 'imdrfCodeChoice3',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'imdrfCodeChoice3',
      label: "Code Choice 3",
      required: false,
      autocomplete: true,
      freeSolo: false,
      options: _config.annexA
    }
  }, {
    id: 'imdrfCodeChoice4',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'imdrfCodeChoice4',
      label: "Code Choice 4",
      required: false,
      autocomplete: true,
      freeSolo: false,
      options: _config.annexA
    }
  }, {
    id: 'imdrfCodeChoice5',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'imdrfCodeChoice5',
      label: "Code Choice 5",
      required: false,
      autocomplete: true,
      freeSolo: false,
      options: _config.annexA
    }
  }, {
    id: 'imdrfCodeChoice6',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'imdrfCodeChoice6',
      label: "Code Choice 6",
      required: false,
      autocomplete: true,
      freeSolo: false,
      options: _config.annexA
    }
  }, {
    id: 'imdrfCodeMissing',
    field: 'Input',
    computedHidden: _devices.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_devices.general.isLateralOrSyringeReport),
      MOP: (0, _computedHidden.visibleIf)(_devices.general.isLateralOrSyringeReport),
      DEFAULT: _computedHidden.show
    }),
    props: {
      shrink: true,
      name: 'imdrfCodeMissing',
      label: 'If you think the incident is unique and a suitable IMDRF term is missing, briefly explain',
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'numPatientsInvolved',
    field: 'Input',
    props: {
      shrink: true,
      name: 'numPatientsInvolved',
      label: "Number of patents involved",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'currentDeviceLocation',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    computedOptions: _devices.general.computeCurrentDeviceLocationOptions,
    props: {
      shrink: true,
      name: 'currentDeviceLocation',
      label: 'What is the current location of the device'
    }
  }, {
    id: 'currentDeviceLocationOtherText',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfCurrentDeviceLocationIsOther,
    computedRequired: mirHelpers.isCurrentDeviceLocationOther,
    computedValue: mirHelpers.clearIfCurrentDeviceLocationIsNotOther,
    props: {
      shrink: true,
      name: 'currentDeviceLocationOtherText',
      label: "Other device location",
      required: false,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'deviceOperatorAtEvent',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'deviceOperatorAtEvent',
      label: 'Operator of device at the time of the incident',
      options: [{
        value: 'Healthcare professional',
        label: 'Healthcare professional'
      }, {
        value: 'Patient/lay user',
        label: 'Patient/lay user'
      }, {
        value: 'Other',
        label: 'Other, please specify'
      }],
      required: false
    }
  }, {
    id: 'deviceOperatorAtEventOther',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfDeviceOperatorAtEventIsOther,
    computedRequired: mirHelpers.isDeviceOperatorAtEventOther,
    computedValue: mirHelpers.clearIfDeviceOperatorAtEventIsNotOther,
    props: {
      shrink: true,
      name: 'deviceOperatorAtEventOther',
      label: "Other device operator",
      required: false,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'deviceUsage',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'deviceUsage',
      label: 'Usage of device',
      options: [{
        value: 'Initial use',
        label: 'Initial use'
      }, {
        value: 'Reuse of a reusable medical device',
        label: 'Reuse of a reusable medical device'
      }, {
        value: 'Problem noted prior use',
        label: 'Problem noted prior use'
      }, {
        value: 'Reuse of a single use medical device',
        label: 'Reuse of a single use medical device'
      }, {
        value: 'Re-serviced/refurbished/fully refurbished',
        label: 'Re-serviced/refurbished/fully refurbished'
      }, {
        value: 'Other',
        label: 'Other, please specify'
      }],
      required: false
    }
  }, {
    id: 'deviceUsageOther',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfDeviceUsageIsOther,
    computedRequired: mirHelpers.isDeviceUsageOther,
    computedValue: mirHelpers.clearIfDeviceUsageIsNotOther,
    props: {
      shrink: true,
      name: 'deviceUsageOther',
      label: "Other Device Usage",
      required: false,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'patientRemedialAction',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientRemedialAction',
      label: "Remedial actions taken by healthcare facility, patient or user subsequent to the incident",
      required: false,
      validate: [validators.maxLength(10000, 'Field')],
      multiline: true,
      rows: 5
    }
  }, {
    id: 'CoronaConfirmed',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'CoronaConfirmed',
      label: "Was the patient a confirmed case of COVID-19?",
      options: (0, _options.mapValueAndLabel)(['Yes', 'No', 'Unknown', 'Don\'t Know'])
    }
  }, {
    id: 'DevicesInvolved',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'DevicesInvolved',
      label: "Quantity defective",
      validate: [validators.numeric]
    }
  }, {
    id: 'mfrContacted',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'mfrContacted',
      label: "Has the manufacturer / supplier been contacted?",
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'CovidTestConfirmed',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'CovidTestConfirmed',
      label: "Was this defect confirmed with another test?",
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'CovidTestDetails',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'CovidTestDetails',
      label: "If so, please provide details of the confirming test",
      validate: [validators.maxLength(2000, 'Field')]
    }
  }, {
    id: 'InjuryStatus',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'InjuryStatus',
      label: "Type of injury",
      options: (0, _options.mapValueAndLabel)(['Death', 'Serious', 'Minor', 'None'])
    }
  }, {
    id: 'mpSupplier',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'mpSupplier',
      label: "Name of where you got device",
      validate: [validators.maxLength(300, 'Field')]
    }
  }, {
    id: 'imdrfClinicalCode',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'imdrfClinicalCode',
      label: 'IMDRF Health Effect terms and codes (Annex E, F)',
      repeatableLabel: ['imdrfClinicalCodeChoice', 'imdrfHealthCodeChoice'],
      repeatableLabelPlaceholder: 'Choice',
      maxLength: 6,
      schema: [{
        id: 'imdrfClinicalCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfClinicalCodeChoice',
          label: 'Clinical signs, symptoms and conditions codes (Annex E)',
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexE
        }
      }, {
        id: 'imdrfHealthCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfHealthCodeChoice',
          label: "IMDRF 'Health impact' codes (Annex F)",
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexF
        }
      }]
    }
  }, {
    id: 'imdrfMissingCodeHealthEffect',
    field: 'Input',
    props: {
      shrink: true,
      name: 'imdrfMissingCodeHealthEffect',
      label: "If you think the incident is unique and a suitable IMDRF term is missing, briefly explain",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'numYears',
    field: 'Input',
    props: {
      shrink: true,
      name: 'numYears',
      label: "Age (years)",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'numMonths',
    field: 'Input',
    props: {
      shrink: true,
      name: 'numMonths',
      label: "Age (months)",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'numDays',
    field: 'Input',
    props: {
      shrink: true,
      name: 'numDays',
      label: "Age (days)",
      required: false,
      validate: [validators.numeric]
    }
  }, {
    id: 'Gender',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'Gender',
      label: 'Gender',
      options: [{
        value: 'Female',
        label: 'Female'
      }, {
        value: 'Male',
        label: 'Male'
      }, {
        value: 'Unknown',
        label: 'Unknown'
      }, {
        value: 'Not applicable',
        label: 'Not Applicable'
      }],
      required: false
    }
  }, {
    id: 'massKG',
    field: 'Input',
    props: {
      shrink: true,
      name: 'massKG',
      label: "Body Weight (Kg)",
      required: false,
      validate: [validators.decimal]
    }
  }, {
    id: 'patientPriorMedication',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientPriorMedication',
      label: "List any of the patient's prior health condition or medication that may be relevant to this incident",
      required: false,
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'breastCapsulectomy',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'breastCapsulectomy',
      label: "Has the implantee ever had a full capsulectomy?",
      options: [{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }, {
        value: 'Don\'t Know',
        label: 'Don\'t Know'
      }],
      required: false
    }
  }, {
    id: 'initialReporterRole',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'initialReporterRole',
      label: 'Role of initial reporter',
      options: [{
        value: 'Healthcare professional',
        label: 'Healthcare professional'
      }, {
        value: 'Patient',
        label: 'Patient'
      }, {
        value: 'Lay user',
        label: 'Lay user'
      }, {
        value: 'Other',
        label: 'Other, please specify'
      }]
    }
  }, {
    id: 'initialReporterRoleOther',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfInitialReporterRoleIsOther,
    computedRequired: mirHelpers.isInitialReporterRoleOther,
    computedValue: mirHelpers.clearIfInitialReporterRoleIsNotOther,
    props: {
      shrink: true,
      name: 'initialReporterRoleOther',
      label: "Other reporter role",
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'healthcareFacilityName',
    field: 'HospitalsUKLookupInput',
    props: {
      shrink: true,
      name: 'healthcareFacilityName',
      label: "Name of the health care facility where incident occurred",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityRepNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityRepNum',
      label: "Healthcare facility report Number",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityContactPersonFirstName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityContactPersonFirstName',
      label: "Contact's first name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityContactPersonSecondName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityContactPersonSecondName',
      label: "Contact's last name",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityEmail',
    field: 'Input',
    computedRequired: function computedRequired(values, globalValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: false,
        2: false,
        3: false
      }) && !values.healthcareFacilityPhone ? true : false;
    },
    props: {
      shrink: true,
      name: 'healthcareFacilityEmail',
      label: "Email",
      validate: [validators.isValidEmail, validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityPhone',
    field: 'Input',
    computedRequired: function computedRequired(values, globalValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: false,
        2: false,
        3: false
      }) && !values.healthcareFacilityEmail ? true : false;
    },
    props: {
      shrink: true,
      name: 'healthcareFacilityPhone',
      label: "Phone",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityCountry',
    field: 'Dropdown',
    computedRequired: mirHelpers.mandatoryForIndustry,
    props: {
      shrink: true,
      name: 'healthcareFacilityCountry',
      label: 'Country',
      options: _config.countryCodesDropdownOptions
    }
  }, {
    id: 'healthcareFacilityStreet',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityStreet',
      label: "Street",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityStreetNum',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityStreetNum',
      label: "Street number",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'reporteraddresshousenameornumber',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'reporteraddresshousenameornumber',
      label: 'House Number or Name',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'reporteraddressline1',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'reporteraddressline1',
      label: 'Address Line 1',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'reporteraddressline2',
    field: 'Input',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'reporteraddressline2',
      label: 'Address Line 2',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'healthcareFacilityAddress',
    field: 'Input',
    computedValue: _devices.audience.match({
      MOP: _devices.audience.joinStreetNameAndAddress,
      HCP: _devices.audience.joinStreetNameAndAddress
    }),
    props: {
      shrink: true,
      name: 'healthcareFacilityAddress',
      label: 'Address complement',
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityPOBox',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityPOBox',
      label: "PO Box",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityCity',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityCity',
      label: "City name",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'healthcareFacilityPostcode',
    field: 'Input',
    props: {
      shrink: true,
      name: 'healthcareFacilityPostcode',
      label: "Postcode",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'allowContact',
    field: 'Dropdown',
    computedHidden: mirHelpers.showForMOPAndHCP,
    props: {
      shrink: true,
      name: 'allowContact',
      label: "Can we send your personal details to the medical device manufacturer?",
      required: false,
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'manufacturersPrelimAnalysis',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Initial' || formValues.reportType === 'Follow up',
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'manufacturersPrelimAnalysis',
      label: "For initial and follow-up reports preliminary results and conclusions of manufacturer\u2019s investigation",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'manufacturersInitialCorrecAction',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Follow up',
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'manufacturersInitialCorrecAction',
      label: "Initial actions (corrective and/or preventive) implemented by the manufacturer",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'furtherInvestigations',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Follow up',
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'furtherInvestigations',
      label: "What further investigations do you intend in view of reaching final conclusions?",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'rootCauses',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Final (Reportable incident)' || formValues.reportType === 'Combined initial and final',
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'rootCauses',
      label: "For Final (Reportable incident)- Description of the manufacturer\u2019s evaluation concerning possible root causes/causative factors and conclusion",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'manufacturersWhyNotReportable',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: formValues.reportType === 'Final (Non-reportable incident)',
        2: false,
        3: false
      });
    },
    field: 'Input',
    props: {
      shrink: true,
      name: 'manufacturersWhyNotReportable',
      label: "For Final (Non-reportable incident)- Fill out rationale for why this is considered not reportable",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'rootCauseConfirmed',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'rootCauseConfirmed',
      label: 'Is root cause confirmed',
      options: [{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }]
    }
  }, {
    id: 'riskAssReviewed',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'riskAssReviewed',
      label: 'Has the risk assessment been reviewed? ',
      options: [{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }]
    }
  }, {
    id: 'rationaleNoReview',
    field: 'Input',
    computedRequired: function computedRequired(values, globalValues) {
      return values.riskAssReviewed === 'No';
    },
    props: {
      shrink: true,
      name: 'rationaleNoReview',
      label: "If 'No', rationale for no review required",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'riskAssAdequate',
    field: 'Dropdown',
    computedRequired: function computedRequired(values, globalValues) {
      return values.riskAssReviewed === 'Yes';
    },
    props: {
      shrink: true,
      name: 'riskAssAdequate',
      label: 'If the risk assessment has been reviewed, is it still adequate?',
      options: [{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }]
    }
  }, {
    id: 'riskAssResults',
    field: 'Input',
    computedRequired: function computedRequired(values, globalValues) {
      return values.riskAssReviewed === 'Yes';
    },
    props: {
      shrink: true,
      name: 'riskAssResults',
      label: "Results of the assessment",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'imdrfTypeInvestigation',
    field: 'Repeatable',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'imdrfTypeInvestigation',
      label: "IMDRF Cause Investigation: Type of investigation (Annex B)",
      repeatableLabel: ['imdrfTypeInvestigationCodeChoice'],
      repeatableLabelPlaceholder: 'Choice',
      schema: [{
        id: 'imdrfTypeInvestigationCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfTypeInvestigationCodeChoice',
          label: "Code",
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexB
        }
      }]
    }
  }, {
    id: 'imdrfInvestigationFindings',
    field: 'Repeatable',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'imdrfInvestigationFindings',
      label: "IMDRF Cause investigation: Investigation findings (Annex C)",
      repeatableLabel: ['imdrfInvestigationFindingsCodeChoice'],
      repeatableLabelPlaceholder: 'Choice',
      schema: [{
        id: 'imdrfInvestigationFindingsCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfInvestigationFindingsCodeChoice',
          label: "Code",
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexC
        }
      }]
    }
  }, {
    id: 'imdrfInvestigationConclusion',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'imdrfInvestigationConclusion',
      label: "IMDRF Cause investigation: Investigation conclusion (Annex D)",
      repeatableLabel: ['imdrfInvestigationConclusionCodeChoice'],
      repeatableLabelPlaceholder: 'Choice',
      schema: [{
        id: 'imdrfInvestigationConclusionCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfInvestigationConclusionCodeChoice',
          label: "Code",
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexD
        }
      }]
    }
  }, {
    id: 'imdrfMissingCodeCauseInvestigation',
    field: 'Input',
    props: {
      shrink: true,
      name: 'imdrfMissingCodeCauseInvestigation',
      label: "If you think the incident is unique and a suitable IMDRF term is missing, briefly explain",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'imdrfComponent',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'imdrfComponent',
      label: "IMDRF Component codes (Annex G)",
      repeatableLabel: ['imdrfComponentCodeChoice'],
      repeatableLabelPlaceholder: 'Choice',
      schema: [{
        id: 'imdrfComponentCodeChoice',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'imdrfComponentCodeChoice',
          label: "Code",
          required: false,
          autocomplete: true,
          freeSolo: false,
          options: _config.annexG
        }
      }]
    }
  }, {
    id: 'imdrfMissingComponentCodes',
    field: 'Input',
    props: {
      shrink: true,
      name: 'imdrfMissingComponentCodes',
      label: "If you think the incident is unique and a suitable IMDRF term is missing, briefly explain",
      required: false,
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'correctiveAction',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'correctiveAction',
      label: "Description of remedial action/corrective action/preventive action / Field Safety Corrective Action",
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'correctiveActionSchedule',
    field: 'Input',
    props: {
      shrink: true,
      name: 'correctiveActionSchedule',
      label: "Time schedule for the implementation of the identified actions",
      validate: [validators.maxLength(100, 'Field')],
      required: false
    }
  }, {
    id: 'manufacturersFinalComments',
    field: 'Input',
    props: {
      shrink: true,
      name: 'manufacturersFinalComments',
      label: "Final comments from the manufacturer on Cause investigation and conclusion",
      required: false,
      validate: [validators.maxLength(10000, 'Field')],
      rows: 5,
      multiline: true
    }
  }, {
    id: 'similarIncidentsIMDRF',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'similarIncidentsIMDRF',
      label: "Identification of similar incidents using IMDRF Adverse Event Reporting terms and codes, Tick-mark which code or combination of codes were used for identifying similar incidents",
      options: [{
        value: "similarIncImdrfCodesAnnexA",
        label: "IMDRF code relating to most relevant Medical device problem (Annex A)"
      }, {
        value: "similarIncImdrfCodesAnnexC",
        label: "IMDRF code relating to most relevant ‘Medical device problem' (Annex A) and IMDRF code relating to most relevant 'Investigation finding' (Annex C, ‘Cause investigation’)"
      }, {
        value: "other",
        label: "Other"
      }]
    }
  }, {
    id: 'similarIncOtherReportingCodesText',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(mirHelpers.isSimilarIncidentsIMDRFOther),
    computedValue: (0, _computedValue.clearIf)(function (values) {
      return !(0, mirHelpers.isSimilarIncidentsIMDRFOther)(values);
    }),
    computedRequired: mirHelpers.isSimilarIncidentsIMDRFOther,
    props: {
      shrink: true,
      name: 'similarIncOtherReportingCodesText',
      label: 'Other – enter description of what similar incidents are based on and the rationale why the above IMDRF codes were not used',
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'similarIncMdCodesChoice1Code',
    field: 'Input',
    props: {
      shrink: true,
      name: 'similarIncMdCodesChoice1Code',
      label: 'Code for most relevant medical device problem',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'similarIncMdCodesChoice1Term',
    field: 'Input',
    props: {
      shrink: true,
      name: 'similarIncMdCodesChoice1Term',
      label: 'Term for most relevant medical device problem',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'similarIncRootCouseCodesChoice1Code',
    field: 'Input',
    props: {
      shrink: true,
      name: 'similarIncRootCouseCodesChoice1Code',
      label: 'Code for most relevant root cause evaluation',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'similarIncRootCouseCodesChoice1Term',
    field: 'Input',
    props: {
      shrink: true,
      name: 'similarIncRootCouseCodesChoice1Term',
      label: 'Term for most relevant root cause evaluation',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'similarIncOtherInHouseCodesText',
    field: 'Input',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isAudience)(formViewDetails, {
        1: false,
        2: false,
        3: false
      });
    },
    props: {
      shrink: true,
      name: 'similarIncOtherInHouseCodesText',
      label: 'Other – enter description of what similar incidents are based on and the rationale why the above codes were not used',
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'similarVariant',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'similarVariant',
      label: "Indicate on which basis similar incidents were identified regarding the device or device variant",
      options: [{
        value: 'Model',
        label: "Model"
      }, {
        value: 'Software',
        label: "Software"
      }, {
        value: 'Lot/Batch',
        label: "Lot/Batch"
      }, {
        value: 'Product platform',
        label: "Product platform"
      }, {
        value: 'Other',
        label: "Other"
      }]
    }
  }, {
    id: 'similarVariantDetails',
    field: 'Input',
    props: {
      shrink: true,
      name: 'similarVariantDetails',
      label: "Details of the selection made above",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'numberBasedOn',
    field: 'Dropdown',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'numberBasedOn',
      label: "Indicate to what criteria the number of devices on the market (also known as denominator data) is based on (tick the most appropriate)",
      options: [{
        value: 'Devices placed on the market or put into service',
        label: 'Devices placed on the market or put into service'
      }, {
        value: 'Units distributed within each time period',
        label: 'Units distributed within each time period'
      }, {
        value: 'Number of tests performed',
        label: 'Number of tests performed'
      }, {
        value: 'Number of episodes of use (for reusable devices)',
        label: 'Number of episodes of use (for reusable devices)'
      }, {
        value: 'Active installed base',
        label: 'Active installed base'
      }, {
        value: 'Units distributed from the date of declaration of Conformity/CE mark approval to the end date of each time period',
        label: 'Units distributed from the date of declaration of conformity/CE mark approval to the end date of each time period'
      }, {
        value: 'Number of devices implanted',
        label: 'Number of devices implanted'
      }, {
        value: 'Other',
        label: 'Other, please describe'
      }]
    }
  }, {
    id: 'numberBasedOnOther',
    field: 'Input',
    computedHidden: mirHelpers.visibleIfNumberBasedOnIsOther,
    computedRequired: mirHelpers.isNumberBasedOnOther,
    computedValue: mirHelpers.clearIfNumberBasedOnIsNotOther,
    props: {
      shrink: true,
      name: 'numberBasedOnOther',
      label: "Number based on Other",
      validate: [validators.maxLength(300, 'Field')]
    }
  }, {
    id: 'timePeriodN',
    field: 'Repeatable',
    computedRequired: function computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) {
      return (0, mirHelpers.isCombinedOrFinalReportable)(formValues) && (0, mirHelpers.mandatoryForIndustry)(formValues, parentFormValues, organisationDetails, formViewDetails);
    },
    props: {
      shrink: true,
      name: 'timePeriodN',
      label: 'Time period',
      repeatableLabel: ['Time Period'],
      repeatableInformation: ['Enter the number of similar incidents and devices on the market for the indicated time periods', 'You must use yearly time periods unless:', 'A: a different time period has been specified by the European vigilance Working Group', 'B: the device has not been on the European market for more than three years'],
      repeatableLabelPlaceholder: 'N',
      repeatableDefaultValues: [{
        label: 'Time Period (N)\nYear to date = incident year\n(e.g. 2012-10-03)',
        title: 'Time Period (N)'
      }, {
        label: 'Time Period (N - 1)\ncalendar year one year before incident\n(e.g. 2012-10-23)',
        title: 'Time Period (N - 1)'
      }, {
        label: 'Time Period (N - 2)\ncalendar year two years before incident\n(e.g. 2012-10-23)',
        title: 'Time Period (N - 2)'
      }, {
        label: 'Time Period (N - 3)\ncalendar year three years before incident\n(e.g. 2012-10-23)',
        title: 'Time Period (N - 3)'
      }],
      disableAdd: true,
      schema: [{
        id: 'startdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.startDate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'startdateformat',
          label: 'Start Date Format'
        }
      }, {
        id: 'startDate',
        field: 'Date',
        props: {
          disabled: false,
          shrink: true,
          name: 'startDate',
          label: 'Start date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('startdateformat', dateFormats), validators.isDateBeforeOrEqual('endDate', 'End date')],
          helperText: 'YYYY/MM/DD'
        }
      }, {
        id: 'enddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.endDate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'enddateformat',
          label: 'End Date Format'
        }
      }, {
        id: 'endDate',
        field: 'Date',
        props: {
          disabled: false,
          shrink: true,
          name: 'endDate',
          label: 'End date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('enddateformat', dateFormats), validators.isDateAfterOrEqual('startDate', 'Start date')],
          helperText: 'YYYY/MM/DD'
        }
      }, {
        id: 'countrySimInc',
        field: 'Input',
        props: {
          shrink: true,
          name: 'countrySimInc',
          label: "Number of similar incidents - (in country of incident)",
          required: false,
          validate: [validators.numeric]
        }
      }, {
        id: 'countrySimDev',
        field: 'Input',
        props: {
          shrink: true,
          name: 'countrySimDev',
          label: "Number of devices on market - (in country of incident)",
          required: false,
          validate: [validators.numeric]
        }
      }, {
        id: 'eeaChTrSimInc',
        field: 'Input',
        props: {
          shrink: true,
          name: 'eeaChTrSimInc',
          label: "Number of similar incidents - (in EEA + CH + TR)",
          required: false,
          validate: [validators.numeric]
        }
      }, {
        id: 'eeaChTrSimDev',
        field: 'Input',
        props: {
          shrink: true,
          name: 'eeaChTrSimDev',
          label: "Number of devices on market - (in EEA + CH + TR)",
          required: false,
          validate: [validators.numeric]
        }
      }, {
        id: 'worldSimInc',
        field: 'Input',
        props: {
          shrink: true,
          name: 'worldSimInc',
          label: "Number of similar incidents - (in world)",
          required: false,
          validate: [validators.numeric]
        }
      }, {
        id: 'worldSimDev',
        field: 'Input',
        props: {
          shrink: true,
          name: 'worldSimDev',
          label: "Number of devices on market - (in world)",
          required: false,
          validate: [validators.numeric]
        }
      }]
    }
  }, {
    id: 'howWereDetermined',
    field: 'Input',
    props: {
      shrink: true,
      name: 'howWereDetermined',
      label: "Comments on how similar incidents and associated number of devices on the market were determined",
      required: false,
      validate: [validators.maxLength(2000, 'Field')],
      maxLength: 2000,
      multiline: true,
      rows: 5
    }
  }, {
    id: 'generalComments',
    field: 'Input',
    props: {
      shrink: true,
      name: 'generalComments',
      label: "General comments",
      required: false,
      validate: [validators.maxLength(10000, 'Field')],
      multiline: true,
      rows: 5
    }
  }, {
    id: 'attachment',
    field: 'Repeatable',
    props: {
      label: 'Attachments',
      name: 'attachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      disableEdit: true,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmentcomments',
        field: 'Input',
        props: {
          label: 'File Comments',
          name: 'attachmentcomments',
          required: false,
          shrink: true,
          multiline: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }]
    }
  }];
};

exports.getFormSchema = getFormSchema;