import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getImage } from '../../../components/Images'

import Form, { FormBody, FormSubmit } from '../../../components/Form'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ContentFormSection from '../ContentFormSection'
import Dropdown from '../../../components/Fields/Dropdown'

import { constants as communicationTemplateConstants } from '../../../store/modules/communicationTemplates'

import { translations } from '../../../config'
import style from './style'

const ConfigureReportForm = Form(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM)

const copyIcon = getImage('copyIcon')

const CommunicationsTemplateForm = (props) => {
  const {
    type,
    variables,
    handleSubmit,
    schema,
    contentSections,
    initialValues,
    isSubmitting,
    isInvalid,
    classes,
    onSendTest,
    onCopy,
    testDestination,
    languagesOptions,
    testLanguage,
    setTestLanguage,
    channel
  } = props

  let translationsChannelKey = _.capitalize(channel)



  return (
    <ConfigureReportForm onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize keepDirtyOnReinitialize>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <FormBody schema={schema} editing />
            {_.map(contentSections, (section) => {
              const { id, title, schema: contentSchema } = section
              return (
                <ContentFormSection
                  key={id}
                  id={id}
                  title={title}
                  schema={contentSchema}
                  initialOpen
                />
              )
            })}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.metaSection}>
              <div className={classes.meta}>
                <Heading variant='h4' component='h2' uppercase>{translations('Communications - Template Type')}</Heading>
                <P value={translations(`Communication Type - ${_.toUpper(type)}`)} />
              </div>
              {_.size(variables) && (
                <div className={classes.meta}>
                  <Heading variant='h4' component='h2' uppercase>{translations('Communications - Variables')}</Heading>
                  <dl className={classes.variables}>
                    {_.map(variables, (variable) => {
                      return (
                        <React.Fragment key={variable}>
                          <div className={classes.variableItem}>
                            <dt>{variable}</dt>
                            <dd>{translations(`Variables - ${variable}`)}</dd>
                            <CopyToClipboard onCopy={() => onCopy(variable)} text={`{{${variable}}}`}>
                              <button
                                type='button'
                                className={classes.copyButton}
                              >
                                <img src={copyIcon} alt={translations('Copy')} />
                              </button>
                            </CopyToClipboard>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </dl>
                  <P value={translations('Communications - Variables Help', { interpolation: { prefix: '[[', suffix: ']]' } })} />
                </div>
              )}
              {!_.isNull(testDestination) && (
                  <div className={classes.meta}>
                  <Heading variant='h4' component='h2' uppercase>{translations(`Communications - Test Title ${translationsChannelKey}`)}</Heading>
                  <P value={translations(`Communications - Test ${translationsChannelKey}`, { destination: testDestination })} />
                  <div className={classes.testActions}>
                    {(_.size(languagesOptions) > 1) && <FormControl className={classes.storesFilter}>
                      <Dropdown
                        label={translations('Communications - Select language')}
                        value={testLanguage}
                        options={languagesOptions}
                        onChange={(e) => setTestLanguage(e.target.value)}
                        noErrorTextLabel
                        noFloatingLabel
                        noNullOption
                        givenClasses={{ field: classes.languageDropdown }}
                        shrink
                      />
                    </FormControl>}
                    <Button
                      className={classes.testButton}
                      onClick={onSendTest}
                      raised
                      children={translations('Communications - Send Test')}
                    />
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <BottomBar>
          <FormSubmit
            containerClass={classes.submitWrapper}
            submitting={isSubmitting}
            invalid={isInvalid}
            noContainer
          >{translations('Save')}</FormSubmit>
        </BottomBar>
      </div>
    </ConfigureReportForm>
  )
}

CommunicationsTemplateForm.defaultProps = {
  isSubmitting: false,
  isInvalid: false
}

CommunicationsTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  contentSections: PropTypes.object,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isInvalid: PropTypes.bool,
  type: PropTypes.string,
  variables: PropTypes.array,
  testDestination: PropTypes.string,
  onSendTest: PropTypes.func,
  languagesOptions: PropTypes.array,
  testLanguage: PropTypes.string,
  setTestLanguage: PropTypes.func,
  onCopy: PropTypes.func
}

export default withStyles(style)(CommunicationsTemplateForm)
