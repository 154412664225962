import OrganisationReportingFullList from './OrganisationReportingFullList'
import { connect } from 'react-redux'
import { selectors } from '../../../../../store/modules/platformAnalytics'

const mapStateToProps = state => {
  const organisationAnalytics = selectors.getSelectedOrganisationAnalytics(state)
  return {
    organisationAnalytics
  }
}

export default connect(mapStateToProps)(OrganisationReportingFullList)
