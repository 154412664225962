import { LOCATION_CHANGE, replace, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import { actions as analyticsActions } from './'
import _ from 'lodash'
import qs from 'qs'

import * as authSelectors from '../auth/selectors'

class Analytics {
  getAnalyticsForOrganisation = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const { pathname: path, search: query } = action.payload.location
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      const pathData = matchPath(path, { path: `/monitoring-and-analytics/analytics/:widgetType`})
      let organisationId = _.get(authSelectors.getSelectedOrganisation(state), 'id')

      const { timePeriod = 'today', dateFrom, dateTo, organisationIds = [organisationId], sourceId } = qs.parse(query, { ignoreQueryPrefix: true }) || {}
      const { reportType = 'AllReports' } = qs.parse(query, { ignoreQueryPrefix: true }) || {}
      const orgIds = typeof organisationIds === 'string'? [ organisationIds ]: organisationIds

      if (isLoggedIn && pathData) {
        organisationId = _.get(pathData, 'params.id')
        _.forEach([
          analyticsActions.setOrganisationIdFilter(organisationId),
          analyticsActions.setReportTypeFilter(reportType),
          analyticsActions.setSourceIdFilter(sourceId),
          analyticsActions.setTimePeriodFilter(timePeriod, dateFrom, dateTo),
          analyticsActions.setOrganisationIdsFilter(orgIds),
          analyticsActions.getOrganisationAnalyticsUsingFilters()
        ], action => {
          dispatch(action)
        })
      }
    }
  }
  
  redirectToOrganisation = ({ getState, dispatch }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const { pathname: path } = action.payload.location
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      const pathData = matchPath(path, { path: '/monitoring-and-analytics/analytics', exact: true })
      let organisationId = _.get(authSelectors.getSelectedOrganisation(state), 'id')
      if (isLoggedIn && pathData) {
        organisationId = _.get(pathData, 'params.id', organisationId)
        dispatch(push(`/monitoring-and-analytics/analytics?timePeriod=today`))
      }
    }
  }

}

export default new Analytics()
