import React from 'react'
import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
  /* '@reach/combobox/styles.css' */
  :root {
    --reach-combobox: 1;
  }

  [data-reach-combobox-popover] {
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    background: hsla(0, 100%, 100%, 0.99);
    font-size: 85%;
  }

  [data-reach-combobox-list] {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
  }

  [data-reach-combobox-option] {
    cursor: pointer;
    margin: 0;
    padding: 0.25rem 0.5rem;
  }

  [data-reach-combobox-option][aria-selected="true"] {
    background: hsl(211, 10%, 95%);
  }

  [data-reach-combobox-option]:hover {
    background: hsl(211, 10%, 92%);
  }

  [data-reach-combobox-option][aria-selected="true"]:hover {
    background: hsl(211, 10%, 90%);
  }

  [data-suggested-value] {
    font-weight: bold;
  }
  /* '@reach/combobox/styles.css' */

  .mhraUI {
    width: 100%;
  }  
  .mhraUI *, .mhraUI *:before, .mhraUI *:after {
    box-sizing: border-box;
    font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";    
  }

  /* '@reach/tooltip/styles.css' */
  :root {
    --reach-tooltip: 1;
  }
  
  [data-reach-tooltip] {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    padding: 0.25em 0.5em;
    box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.1);
    white-space: nowrap;
    font-size: 85%;
    background: #f0f0f0;
    color: #444;
    border: solid 1px #ccc;
  }
  /* '@reach/tooltip/styles.css' */
  

`

const GlobalStyles = () => <Styles />

export default GlobalStyles