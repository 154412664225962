import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  currentPlatform: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PLATFORM_FOR_DOMAIN:
      return reducers.fetchPlatformForDomain(state, action)
    case constants.FETCH_ALL_PLATFORMS:
      return reducers.fetchAllPlatforms(state, action)
    case constants.FETCH_PLATFORM_ANNOUNCEMENTS:
      return reducers.fetchPlatformAnnouncents(state, action)
    default:
      return state
  }
}
